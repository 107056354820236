// components/ZohoProjectsIntegration.js
import React from 'react';
import { Paper, Radio, RadioGroup, FormControlLabel, Typography } from '@mui/material';
import '../../App.css';

const ProjectsIntegration = () => {
  return (
    <Paper elevation={3} sx={{ p: 3, m: 2 }}>
      <Typography variant="h5" gutterBottom>
        Zoho Projects Integration
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Portal Configuration
      </Typography>
      <Typography variant="body2" gutterBottom>
        Configure your Zoho Projects to start the integration with Zoho CRM.
      </Typography>
      <RadioGroup name="portalConfiguration">
        <FormControlLabel value="newPortal" control={<Radio />} label="Create a new portal with my email id" />
        <FormControlLabel value="existingPortal" control={<Radio />} label="I already have a Zoho Projects org with different email id" />
      </RadioGroup>
    </Paper>
  );
};

export default ProjectsIntegration;
