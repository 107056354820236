import React, { useState, useEffect } from "react";
import { Box, Typography,  IconButton, InputLabel,FormControlLabel, Checkbox, Select, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Paper, Button, Grid, Menu, MenuItem, FormControl } from '@mui/material';
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "../../utils/axios";
import { useNavigate } from "react-router-dom";
import Header from "../../assets/charts/Header";
import { useUser } from "../../contexts/auth";
import { useTheme } from "@mui/material/styles";
import '../../App.css'
import ViewListIcon from '@mui/icons-material/ViewList'; // Import the List View icon
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import GridViewIcon from '@mui/icons-material/GridView';
import TableViewIcon from '@mui/icons-material/TableView';
import TocIcon from '@mui/icons-material/Toc';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DateTimePicker, DatePicker } from '@mui/x-date-pickers';
const AllMeeting = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [leads, setLeads] = useState([]);
//   const [accounts, setAccounts] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteLeadId, setDeleteLeadId] = useState(null);
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState(null);
  const { userrole, companycode } = useUser();
  const [selectedView, setSelectedView] = useState('All Meetings');
  const [viewAnchorEl, setViewAnchorEl] = useState(null);
 const [systemFiltersVisible, setSystemFiltersVisible] = useState(true);
  const [fieldFiltersVisible, setFieldFiltersVisible] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [alphabetFilter, setAlphabetFilter] = useState('');
  const [nameFilter, setNameFilter] = useState('All');
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [newMeeting, setNewMeeting] = useState({
    title: "",
    location: "",
    from: null,
    to: null,
    allDay: false,
    host: "",
    participants: "",
    relatedTo: "",
    repeat: "None",
    description: "",
  });
  useEffect(() => {
    if (openCreateDialog) {
      const now = new Date();
      const nextHour = new Date(now.setHours(now.getHours() + 1, 0, 0, 0));
      const oneHourLater = new Date(nextHour.getTime() + 60 * 60 * 1000);
      
      setNewMeeting(prev => ({
        ...prev,
        from: nextHour,
        to: oneHourLater,
      }));
    }
  }, [openCreateDialog]);


  const handleCreateMeeting = () => {
    setOpenCreateDialog(true);
  };

   const handleCloseCreateDialog = () => {
    setOpenCreateDialog(false);
    setNewMeeting({
      title: "",
      location: "",
      from: null,
      to: null,
      allDay: false,
      host: "",
      participants: "",
      relatedTo: "",
      repeat: "None",
      description: "",
    });
  };
  
  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setNewMeeting(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));

    if (name === 'allDay') {
      if (checked) {
        setNewMeeting(prev => ({
          ...prev,
          from: prev.from ? new Date(prev.from.setHours(0, 0, 0, 0)) : null,
          to: prev.to ? new Date(prev.to.setHours(23, 59, 59, 999)) : null,
        }));
      } else {
        const now = new Date();
        const nextHour = new Date(now.setHours(now.getHours() + 1, 0, 0, 0));
        const oneHourLater = new Date(nextHour.getTime() + 60 * 60 * 1000);
        setNewMeeting(prev => ({
          ...prev,
          from: nextHour,
          to: oneHourLater,
        }));
      }
    }
  };

  const handleDateChange = (name, date) => {
    setNewMeeting(prev => ({
      ...prev,
      [name]: date,
    }));
  };
  const handleSaveMeeting = async() => {
    // // Here you would typically send the newMeeting data to your backend
    // console.log("New meeting data:", newMeeting);
    // // After saving, close the dialog and reset the form
    // handleCloseCreateDialog();
    try {
      const response = await axios.post('/addmeeting', newMeeting);
      console.log('Server response:', response.data);
      // After saving, close the dialog and reset the form
      handleCloseCreateDialog();
  } catch (error) {
      console.error('Error saving meeting:', error);
  }
  };

  const meetings = [
    { id: 1, title: "Demo", from: "08/07/2024 03:24 PM", to: "08/07/2024 04:24 PM", relatedTo: "Printing Dimensions", contactName: "Donette Foller (Sample)", host: "Vivek Kumar Yadav"},
    { id: 2, title: "Webinar", from: "08/07/2024 05:24 PM", to: "08/07/2024 06:24 PM", relatedTo: "Commercial Press (Sample)", contactName: "Kris Marrier (Sample)", host: "Vivek Kumar Yadav"},
    { id: 3, title: "TradeShow", from: "08/07/2024 06:24 PM", to: "08/07/2024 07:24 PM", relatedTo: "Chemel", contactName: "Kris Marrier (Sample)", host: "Vivek Kumar Yadav"},
    { id: 4, title: "Webinar", from: "08/07/2024", to: "08/07/2024", relatedTo: "Chanay (Sample)", contactName: "Kris Marrier (Sample)", host: "Vivek Kumar Yadav"},
    { id: 5, title: "Seminar", from: "08/07/2024 04:24 PM", to: "08/07/2024 05:24 PM", relatedTo: "	Carissa Kidman (Sample)", contactName: "Kris Marrier (Sample)", host: "Vivek Kumar Yadav"},
    { id: 6, title: "Attend Customer conference", from: "08/07/2024 03:24 PM", to: "08/07/2024 04:24 PM", relatedTo: "Feltz Printing Service", contactName: "Kris Marrier (Sample)", host: "Vivek Kumar Yadav"},
    { id: 7, title: "CRM Webinar", from: "08/07/2024 02:24 PM", to: "08/07/2024 03:24 PM", relatedTo: "Printing Dimensions", contactName: "Donette Foller (Sample)", host: "Vivek Kumar Yadav"},
    { id: 8, title: "CRM Webinar", from: "08/07/2024 10:24 PM", to: "08/07/2024 11:24 PM", relatedTo: "Printing Dimensions", contactName: "Kris Marrier (Sample)", host: "Vivek Kumar Yadav"},
    { id: 9, title: "CRM Webinar", from: "08/07/2024 01:24 PM", to: "08/07/2024 02:24 PM", relatedTo: "Printing Dimensions", contactName: "Kris Marrier (Sample)", host: "Vivek Kumar Yadav"},
   
   
    // More mock data can be added here
  ];

  const [filteredAccounts, setFilteredAccounts] = useState(meetings);

  useEffect(() => {
    setFilteredAccounts(
        meetings.filter(account => {
        if (nameFilter === 'All') return true;
        return account.title && account.title.charAt(0).toUpperCase() === nameFilter;
      })
    );
  }, [nameFilter, meetings]);

  const systemDefinedFilters = [
    "Touched Records",
    "Untouched Records",
    "Record Action",
    "Related Records Action",
  ];

  const fieldFilters = [
    "All day",
    "Check-In Address",
    "Check-In By",
    "Check-In City",
    "Check-In Country",
    "Check-In State",
    "Check-In Sub-Locality",
    "Check-In Time",
    "Checked In to",
    "Contact Name",
    "Created By",
    "Created Time",
    "From",
    "Host",
    "Location",
    "Modified By",
    "Modified Time",
    "Related To",
    "Tag",
    "Title",
    "To",
    "Zip Code",
  ];

  const toggleSystemFilters = () => {
    setSystemFiltersVisible(!systemFiltersVisible);
  };

  const toggleFieldFilters = () => {
    setFieldFiltersVisible(!fieldFiltersVisible);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredSystemDefinedFilters = systemDefinedFilters.filter((filter) =>
    filter.toLowerCase().includes(searchTerm)
  );

  const filteredFieldFilters = fieldFilters.filter((filter) =>
    filter.toLowerCase().includes(searchTerm)
  );
  const handleViewMenuOpen = (event) => {
    setViewAnchorEl(event.currentTarget);
  };


  const handleChange = (event) => {
    setSelectedView(event.target.value);
  };

  const handleViewMenuClose = () => {
    setViewAnchorEl(null);
  };

  const viewMenuOpen = Boolean(viewAnchorEl);


  const handleNameFilterChange = (event) => {
    setNameFilter(event.target.value);
  };



  
const columns = [
    { 
      field: "title", 
      headerName: "Title", 
      flex: 0.3,
      renderHeader: (params) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography>{params.colDef.headerName}</Typography>
          <Select
            value={nameFilter}
            onChange={handleNameFilterChange}
            sx={{ ml: 1, height: 30 }}
          >
            <MenuItem value="All">All</MenuItem>
            {Array.from(Array(26)).map((_, i) => (
              <MenuItem key={i} value={String.fromCharCode(65 + i)}>
                {String.fromCharCode(65 + i)}
              </MenuItem>
            ))}
          </Select>
        </Box>
      )
    },
    { field: "from", headerName: "From", flex: 0.3 },
    { field: "to", headerName: "To", flex: 0.3 },
    { field: "relatedTo", headerName: "Related To", flex: 0.3 },
    { field: "contactName", headerName: "Contact Name", flex: 0.3 },
    { field: "host", headerName: "Host", flex: 0.3 },
   
  ];

  const handletoChange = async (event, lead) => {
    try {
      const updatedLeads = leads.map((leadItem) =>
        leadItem.id === lead ? { ...leadItem, leadto: event.target.value } : leadItem
      );
      setLeads(updatedLeads);

      const response = await axios.put(
        `/updateLeadState/${lead}`,
        {
          leadto: event.target.value,
        }
      );

      if (response && response.to === 200) {
        // fetchData();
        setDeleteSuccessMessage("Lead to updated successfully!");
      } else {
        console.error("Failed to update lead to");
      }
    } catch (error) {
      console.error("Error updating lead to:", error);
    }
  };

  const handleEdit = (lead) => {
    navigate(`/updatelead/${lead}`);
  };

  const handleDelete = (leadId) => {
    setDeleteLeadId(leadId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete(`/deleteLead/${deleteLeadId}`);
      if (response && response.to === 200) {
        setLeads((prevLeads) =>
          prevLeads.filter((lead) => lead.id !== deleteLeadId)
        );
        setDeleteSuccessMessage("Lead deleted successfully!");
        setTimeout(() => {
          setDeleteSuccessMessage(null);
        }, 3000);
      } else {
        console.error("Failed to delete lead");
      }
    } catch (error) {
      console.error("Error deleting lead:", error);
    }
    setOpenDialog(false);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuOpen = Boolean(anchorEl);

  return (
    <Box>
      {/* Header Section */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2, backgroundColor: 'white', color: 'black', marginTop: "5.2%", padding: '5px'}}>
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small" style={{color: 'white', background: 'black', borderRadius: '5px'}}>
      {/* <InputLabel id="lead-select-label"  style={{color: 'white'}} >Public Views</InputLabel> */}
      <Select
        labelId="lead-select-label"
        value={selectedView}
        onChange={handleChange}
        label="Public Views"
        style={{color: 'white'}}
      >
        <MenuItem value="All Meetings">All Meetings</MenuItem>
        <MenuItem value="Cancelled Meetings">Cancelled Meetings</MenuItem>
        <MenuItem value="Completed Meetings">Completed Meetings</MenuItem>
        <MenuItem value="My Cancelled Meetings">My Cancelled Meetings</MenuItem>
        <MenuItem value="My Completed Meetings">My Completed Meetings</MenuItem>
        <MenuItem value="My Meetings">My Meetings</MenuItem>
        <MenuItem value="My Today's Meetings">My Today's Meetings</MenuItem>
        <MenuItem value="My Upcoming Meetings">My Upcoming Meetings</MenuItem>
        <MenuItem value="This Weeks Meetings">This Weeks Meetings</MenuItem>
        <MenuItem value="Today's Meetings">Today's Meetings</MenuItem>
        <MenuItem value="Upcoming Meetings">Upcoming Meetings</MenuItem>
        
      </Select>
    </FormControl>
        <Box>
        <Button variant="contained" onClick={handleViewMenuOpen} startIcon={<ViewListIcon />}>List View</Button> {/* Added List View Button */}
        <Button variant="contained" color="primary" sx={{ mr: 2 }} onClick={handleCreateMeeting}>Create Meeting</Button>
        <Button variant="contained" onClick={handleMenuOpen}>Actions</Button>
          <Menu anchorEl={anchorEl} open={menuOpen} onClose={handleMenuClose}>
          <MenuItem onClick={handleMenuClose}>Mass Delete</MenuItem>
            <MenuItem onClick={handleMenuClose}>Mass Update</MenuItem>
            <MenuItem onClick={handleMenuClose}>Manage Tags</MenuItem>
            <MenuItem onClick={handleMenuClose}>Export Meetings</MenuItem>
            <MenuItem onClick={handleMenuClose}>Print View</MenuItem>
          </Menu>
          <Menu anchorEl={viewAnchorEl} open={viewMenuOpen} onClose={handleViewMenuClose}> {/* Added List View Menu */}
            <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><TocIcon /></span> List View</MenuItem>
            <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><ViewKanbanIcon /></span> Kanban View</MenuItem>
            <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><ViewListIcon /></span> Custom List View</MenuItem>
            <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><GridViewIcon /></span> Tile View</MenuItem>
            <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><TableViewIcon /></span> Table View</MenuItem>
          </Menu>
        </Box>
      </Box>

      {/* Main Content */}
      <Grid container columnGap={5}> 
     
        <Grid item xs={2}>
      <Box
        sx={{
          p: 2,
          borderRight: "1px solid #e0e0e0",
          height: "76vh",
          background: "black",
          overflowY: "auto",
          marginTop: "20px"
        }}
      >
        <Typography variant="h6" color="white" style={{fontSize: "16px"}}>Filter Meetings by</Typography>
        <TextField
          variant="outlined"
          placeholder="Search"
          size="small"
          fullWidth
          sx={{ mb: 2 }}
          value={searchTerm}
          onChange={handleSearchChange}
        />

        <Typography
          variant="body2"
          color="white"
          sx={{ mb: 1, cursor: "pointer", fontSize: "16px"}}
          onClick={toggleSystemFilters}
        >
        System Defined Filters <IconButton
            sx={{
              color: "white",
              transform: systemFiltersVisible ? 'rotate(90deg)' : 'rotate(0deg)',
              transition: 'transform 0.3s'
            }}
          >
            <ArrowRightIcon />
          </IconButton>
         
        </Typography>
        {systemFiltersVisible && (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {filteredSystemDefinedFilters.map((filter) => (
              <FormControlLabel
                key={filter}
                control={<Checkbox />}
                label={filter}
                sx={{ color: "white" }}
              />
            ))}
          </Box>
        )}

        <Typography
          variant="body2"
          color="white"
          sx={{ mt: 2, mb: 1, cursor: "pointer", fontSize: "16px" }}
          onClick={toggleFieldFilters}
        >
          Filter By Fields <IconButton
            sx={{
              color: "white",
              transform: fieldFiltersVisible ? 'rotate(90deg)' : 'rotate(0deg)',
              transition: 'transform 0.3s'
            }}
          >
            <ArrowRightIcon />
          </IconButton>
        </Typography>
        {fieldFiltersVisible && (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {filteredFieldFilters.map((filter) => (
              <FormControlLabel
                key={filter}
                control={<Checkbox />}
                label={filter}
                sx={{ color: "white" }}
              />
            ))}
          </Box>
        )}
      </Box>
    </Grid>
            <Grid item xs={9.5}>
          <Paper elevation={3} style={{ padding: '20px', height: '77vh', marginTop: '20px', color: 'black' }} className="listViewStyle">
            <Typography variant="h6" sx={{ mb: 2 }} style={{color: 'white'}}>Total Records: {filteredAccounts.length}</Typography>
            <DataGrid
              columns={columns}
              rows={filteredAccounts}
              pageSize={10}
              rowsPerPageOptions={[10]}
              checkboxSelection
              disableSelectionOnClick
            />
            
            
            {/* Confirmation Dialog */}
            <Dialog open={openDialog} onClose={handleCloseDialog}>
              <DialogTitle>Confirm Delete</DialogTitle>
              <DialogContent>Are you sure you want to delete this lead?</DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                  No
                </Button>
                <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
        </Grid>
      </Grid>

      <Dialog open={openCreateDialog} onClose={handleCloseCreateDialog} maxWidth="sm" fullWidth>
        <DialogTitle>Meeting Information</DialogTitle>
        <DialogContent>
        <TextField
            autoFocus
            margin="dense"
            name="title"
            label="Title"
            type="text"
            fullWidth
            variant="outlined"
            value={newMeeting.title}
            onChange={handleInputChange}
          />
            <TextField
            autoFocus
            margin="dense"
            name="location"
            label="Location"
            type="text"
            fullWidth
            variant="outlined"
            value={newMeeting.location}
            onChange={handleInputChange}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={newMeeting.allDay}
                onChange={handleInputChange}
                name="allDay"
              />
            }
            label="All day"
          />
          
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            {newMeeting.allDay ? (
              <>
                <DatePicker
                  label="From"
                  value={newMeeting.from}
                  onChange={(date) => handleDateChange('from', date)}
                  renderInput={(params) => <TextField {...params} fullWidth margin="dense" />}
                />
                <DatePicker
                  label="To"
                  value={newMeeting.to}
                  onChange={(date) => handleDateChange('to', date)}
                  renderInput={(params) => <TextField {...params} fullWidth margin="dense" />}
                />
              </>
            ) : (
              <>
                <DateTimePicker
                  label="From"
                  value={newMeeting.from}
                  onChange={(date) => handleDateChange('from', date)}
                  renderInput={(params) => <TextField {...params} fullWidth margin="dense" />}
                />
                <DateTimePicker
                  label="To"
                  value={newMeeting.to}
                  onChange={(date) => handleDateChange('to', date)}
                  renderInput={(params) => <TextField {...params} fullWidth margin="dense" />}
                />
              </>
            )}
          </LocalizationProvider>
          <TextField
            select
            margin="dense"
            name="host"
            label="Host"
            fullWidth
            variant="outlined"
            value={newMeeting.host}
            onChange={handleInputChange}
          >
            <MenuItem value="Vivek Kumar Yadav">Vivek Kumar Yadav</MenuItem>
            {/* Add more host options as needed */}
          </TextField>
          <TextField
            margin="dense"
            name="participants"
            label="Participants"
            type="text"
            fullWidth
            variant="outlined"
            value={newMeeting.participants}
            onChange={handleInputChange}
            InputProps={{
              endAdornment: (
                <Button size="small" color="primary">+ Add</Button>
              ),
            }}
          />
          <TextField
            select
            margin="dense"
            name="relatedTo"
            label="Related To"
            fullWidth
            variant="outlined"
            value={newMeeting.relatedTo}
            onChange={handleInputChange}
          >
            <MenuItem value="None">None</MenuItem>
            {/* Add more related to options as needed */}
          </TextField>
          <TextField
            select
            margin="dense"
            name="repeat"
            label="Repeat"
            fullWidth
            variant="outlined"
            value={newMeeting.repeat}
            onChange={handleInputChange}
          >
            <MenuItem value="None">None</MenuItem>
            {/* Add more repeat options as needed */}
          </TextField>
          <TextField
            margin="dense"
            name="description"
            label="Description"
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            value={newMeeting.description}
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCreateDialog} variant="contained" color="primary">Cancel</Button>
          <Button onClick={handleSaveMeeting} variant="contained" color="primary">Save</Button>
        </DialogActions>
      </Dialog>

    </Box>
  );
};

export default AllMeeting;
