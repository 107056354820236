import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Select,
  MenuItem,
  Paper,
  IconButton,
  Grid,
  Divider,
  FormControl,
  Menu
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import EditIcon from '@mui/icons-material/Edit';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import "../../App.css"
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "../../utils/axios";
import { useNavigate } from "react-router-dom";
import Header from "../../assets/charts/Header";
import { useUser } from "../../contexts/auth";
import { styled, useTheme } from "@mui/material/styles";
import ViewListIcon from '@mui/icons-material/ViewList'; // Import the List View icon
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import GridViewIcon from '@mui/icons-material/GridView';
import TableViewIcon from '@mui/icons-material/TableView';
import TocIcon from '@mui/icons-material/Toc';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';



const AllDeal = () => {
  
    const [anchorEl, setAnchorEl] = useState(null);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();
    const [leads, setLeads] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [deleteLeadId, setDeleteLeadId] = useState(null);
    const [deleteSuccessMessage, setDeleteSuccessMessage] = useState(null);
    const { userrole, companycode } = useUser();
    const [selectedView, setSelectedView] = useState('All Deals');
    const [viewAnchorEl, setViewAnchorEl] = useState(null);
    const [systemFiltersVisible, setSystemFiltersVisible] = useState(true);
    const [fieldFiltersVisible, setFieldFiltersVisible] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [alphabetFilter, setAlphabetFilter] = useState('');
    const [nameFilter, setNameFilter] = useState('All');
    const [selectedViewNone, setSelectedViewNone] = useState('None');


    
  const [deals, setDeals] = useState({
    'Qualification': [
      { id: 1, name: 'Benton', company: 'Benton (Sample)', owner: 'Vivek Kumar Yadav', contact: 'John Butt (Sample)', amount: 250000, date: '09/07/2024' }
    ],
    'Needs Analysis': [
      { id: 2, name: 'Truhlar And Truhlar Attys', company: 'Truhlar And Truhlar (Sample)', owner: 'Vivek Kumar Yadav', contact: 'Sage Wieser (Sample)', amount: 45000, date: '09/07/2024' },
      { id: 3, name: 'Chanay', company: 'Chanay (Sample)', owner: 'Vivek Kumar Yadav', contact: 'Josephine Darakjy (Sample)', amount: 55000, date: '10/07/2024' }
    ],
    'Value Proposition': [
      { id: 4, name: 'Chemel', company: 'Chemel (Sample)', owner: 'Vivek Kumar Yadav', contact: 'James Venere (Sample)', amount: 70000, date: '09/07/2024' }
    ],
    'Identify Decision Makers': [
      { id: 5, name: 'King', company: 'King (Sample)', owner: 'Vivek Kumar Yadav', contact: 'Kris Marrier (Sample)', amount: 60000, date: '11/07/2024' },
      { id: 6, name: 'Feltz Printing Service', company: 'Feltz Printing Service (Sample)', owner: 'Vivek Kumar Yadav', contact: 'Carla Paprocki (Sample)', amount: 45000, date: '12/07/2024' }
    ],
    'Needs Analysis1': [
      { id: 2, name: 'Truhlar And Truhlar Attys', company: 'Truhlar And Truhlar (Sample)', owner: 'Vivek Kumar Yadav', contact: 'Sage Wieser (Sample)', amount: 45000, date: '09/07/2024' },
      { id: 3, name: 'Chanay', company: 'Chanay (Sample)', owner: 'Vivek Kumar Yadav', contact: 'Josephine Darakjy (Sample)', amount: 55000, date: '10/07/2024' }
    ],
  });

  const stages = [
    { name: 'Qualification', percentage: 10, totalAmount: 250000, count: 1 },
    { name: 'Needs Analysis', percentage: 20, totalAmount: 100000, count: 2 },
    { name: 'Value Proposition', percentage: 40, totalAmount: 70000, count: 1 },
    { name: 'Identify Decision Makers', percentage: 60, totalAmount: 105000, count: 2 },
    { name: 'Needs Analysis1', percentage: 20, totalAmount: 100000, count: 2 },
  ];

  const [filters, setFilters] = useState({
    systemDefined: {
      'Touched Records': false,
      'Untouched Records': false,
      'Record Action': false,
      'Related Records Action': false,
      'Locked': false,
      'Latest Email Status': false,
      'Activities': false,
      'Notes': false,
      'Cadences': false,
    },
    fields: {
      'Account Name': false,
      'Amount': false,
      'Campaign Source': false,
      'Closing Date': false,
    }
  });


  
  const systemDefinedFilters = [
    "Touched Records",
    "Untouched Records",
    "Record Action",
    "Related Records Action",
    "Locked",
    "Latest Email Status",
    "Activities",
    "Notes",
    "Deals",
    "Deal Amount",
    "Deal Stage",
    "Deal Owner",
    "Deal Closing Date",
    "Campaigns",
    "Cadences"
  ];

  const fieldFilters = [
    "Account Name",
    "Assistant",
    "Asst Phone",
    "Contact Name",
    "Contact Owner",
    "Created By",
    "Created Time",
    "Date of Birth",
    "Department",
    "Email",
    "Email Opt Out",
    "Fax",
    "First Name",
    "Home Phone",
    "Last Activity Time",
    "Last Name",
    "Lead Source",
    "Mailing City",
    "Mailing Country",
    "Mailing State",
    "Mailing Street",
    "Mailing Zip",
    "Mobile",
    "Modified By",
    "Modified Time",
    "Other City",
    "Other Country",
    "Other Phone",
    "Other State",
    "Other Street",
    "Other Zip",
    "Phone",
    "Reporting To",
    "Salutation",
    "Secondary Email",
    "Skype ID",
    "Tag",
    "Title",
    "Twitter",
    "Unsubscribed Mode",
    "Unsubscribed Time",
    "Vendor Name"
  ];

  const handleFilterChange = (category, filter) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [category]: {
        ...prevFilters[category],
        [filter]: !prevFilters[category][filter]
      }
    }));
  };
  
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleViewMenuClose = () => {
    setViewAnchorEl(null);
  };

  const handleViewMenuOpen = (event) => {
    setViewAnchorEl(event.currentTarget);
  };

  const handleChange = (event) => {
    setSelectedView(event.target.value);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };


  const toggleSystemFilters = () => {
    setSystemFiltersVisible(!systemFiltersVisible);
  };

  const toggleFieldFilters = () => {
    setFieldFiltersVisible(!fieldFiltersVisible);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleChangeNone = (event) => {
    setSelectedViewNone(event.target.value);
  };

  const filteredSystemDefinedFilters = systemDefinedFilters.filter((filter) =>
    filter.toLowerCase().includes(searchTerm)
  );

  const filteredFieldFilters = fieldFilters.filter((filter) =>
    filter.toLowerCase().includes(searchTerm)
  );


  const viewMenuOpen = Boolean(viewAnchorEl);
  const menuOpen = Boolean(anchorEl);
  return (
    <Box sx={{ p: 0 }}>
    <Helmet>
      <title>Deals - Contacttab.com</title>
    </Helmet>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2, backgroundColor: 'white', color: 'black', marginTop: "5.2%", padding: '5px'}}>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small" style={{color: 'white', background: 'black', borderRadius: '5px'}}>
          <Select
            labelId="lead-select-label"
            value={selectedView}
            onChange={handleChange}
            label="Public Views"
            style={{color: 'white'}}
          >
            <MenuItem value="All Deals">All Deals</MenuItem>
            <MenuItem value="All Locked Deals">All Locked Deals</MenuItem>
            <MenuItem value="Closing Next Month">Closing Next Month</MenuItem>
            <MenuItem value="Closing This Month">Closing This Month</MenuItem>
            <MenuItem value="My Deals">My Deals</MenuItem>
            <MenuItem value="Closing This Month">Closing This Month</MenuItem>
            <MenuItem value="New Last Week">New Last Week</MenuItem>
            <MenuItem value="New This Week">New This Week</MenuItem>
            <MenuItem value="Recently Created Deals">Recently Created Deals</MenuItem>
            <MenuItem value="Recently Modified Deals">Recently Modified Deals</MenuItem>
            <MenuItem value="Unread Deals">Unread Deals</MenuItem>
          </Select>
        </FormControl>
        <Box>
          <Button variant="contained" onClick={handleViewMenuOpen} startIcon={<ViewListIcon />}>List View</Button> {/* Added List View Button */}
          <Button variant="contained" color="primary" sx={{ mr: 2 }} onClick={() => navigate('/create-deal')}>Create Deal</Button>
          <Button variant="contained" onClick={handleMenuOpen}>Actions</Button>
          <Menu anchorEl={anchorEl} open={menuOpen} onClose={handleMenuClose}>
            <MenuItem onClick={handleMenuClose}>Mass Delete</MenuItem>
            <MenuItem onClick={handleMenuClose}>Mass Update</MenuItem>
            <MenuItem onClick={handleMenuClose}>Manage Tags</MenuItem>
            <MenuItem onClick={handleMenuClose}>Drafts</MenuItem>
            <MenuItem onClick={handleMenuClose}>Mass Email</MenuItem>
            <MenuItem onClick={handleMenuClose}>Deduplicate Deals</MenuItem>
            <MenuItem onClick={handleMenuClose}>Export Deals</MenuItem>
          </Menu>
          <Menu anchorEl={viewAnchorEl} open={viewMenuOpen} onClose={handleViewMenuClose}> {/* Added List View Menu */}
            <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><TocIcon /></span> List View</MenuItem>
            <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><ViewKanbanIcon /></span> Kanban View</MenuItem>
            <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><ViewListIcon /></span> Custom List View</MenuItem>
            <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><GridViewIcon /></span> Tile View</MenuItem>
            <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><TableViewIcon /></span> Table View</MenuItem>
          </Menu>
        </Box>
      </Box>


      <Box sx={{ display: 'flex', mb: 2, marginLeft: '10px', marginRight: '10px', marginTop: '10px', alignItems: 'center' }}>
        <Button variant="outlined" sx={{ mr: 1, color: 'white' }}>
          STAGEVIEW <ArrowDropDownIcon />
        </Button>
        <IconButton>
          <EditIcon />
        </IconButton>
        <Box sx={{ flexGrow: 1 }} />
        <Typography variant="body2" sx={{ mr: 1, fontSize: 16 }}>Sort By</Typography>
        <FormControl sx={{ m: 1, minWidth: 120  }} size="small" style={{color: 'white', background: 'black', borderRadius: '5px'}}>
      {/* <InputLabel id="lead-select-label"  style={{color: 'white'}} >Public Views</InputLabel> */}
      <Select
        labelId="lead-select-label"
        value={selectedViewNone}
        onChange={handleChangeNone}
        label="Public Views"
        style={{color: 'white'}}
      >
         <MenuItem value="None">None</MenuItem>
          <MenuItem value="Account Name">Account Name</MenuItem>
          <MenuItem value="Amount">Amount</MenuItem>
          <MenuItem value="Campaign Source">Campaign Source</MenuItem>
          <MenuItem value="Closing Date">Closing Date</MenuItem>
          <MenuItem value="Contact Name">Contact Name</MenuItem>
          <MenuItem value="Created By">Created By</MenuItem>
          <MenuItem value="Created Time">Created Time</MenuItem>
          <MenuItem value="Deal Name">Deal Name</MenuItem>
          <MenuItem value="Deal Owner">Deal Owner</MenuItem>
          <MenuItem value="Expected Revenue">Expected Revenue</MenuItem>
          <MenuItem value="Last Activity Time">Last Activity Time</MenuItem>
          <MenuItem value="Lead Conversion Time">Lead Conversion Time</MenuItem>
          <MenuItem value="Lead Source">Lead Source</MenuItem>
          <MenuItem value="Modified By">Modified By</MenuItem>
          <MenuItem value="Modified Time">Modified Time</MenuItem>
          <MenuItem value="Next Step">Next Step</MenuItem>
          <MenuItem value="Overall Sales Duration">Overall Sales Duration</MenuItem>
          <MenuItem value="Probability (%)">Probability (%)</MenuItem>
          <MenuItem value="Reason For Loss">Reason For Loss</MenuItem>
          <MenuItem value="Sales Cycle Duration">Sales Cycle Duration</MenuItem>
          <MenuItem value="Stage">Stage</MenuItem>
          <MenuItem value="Type">Type</MenuItem>
      </Select>
    </FormControl>
        {/* <Select value="None" size="small" sx={{ mr: 2 }}>
          <MenuItem value="None">None</MenuItem>
          <MenuItem value="Account Name">Account Name</MenuItem>
          <MenuItem value="Amount">Amount</MenuItem>
          <MenuItem value="Campaign Source">Campaign Source</MenuItem>
          <MenuItem value="Closing Date">Closing Date</MenuItem>
          <MenuItem value="Contact Name">Contact Name</MenuItem>
          <MenuItem value="Created By">Created By</MenuItem>
          <MenuItem value="Created Time">Created Time</MenuItem>
          <MenuItem value="Deal Name">Deal Name</MenuItem>
          <MenuItem value="Deal Owner">Deal Owner</MenuItem>
          <MenuItem value="Expected Revenue">Expected Revenue</MenuItem>
          <MenuItem value="Last Activity Time">Last Activity Time</MenuItem>
          <MenuItem value="Lead Conversion Time">Lead Conversion Time</MenuItem>
          <MenuItem value="Lead Source">Lead Source</MenuItem>
          <MenuItem value="Modified By">Modified By</MenuItem>
          <MenuItem value="Modified Time">Modified Time</MenuItem>
          <MenuItem value="Next Step">Next Step</MenuItem>
          <MenuItem value="Overall Sales Duration">Overall Sales Duration</MenuItem>
          <MenuItem value="Probability (%)">Probability (%)</MenuItem>
          <MenuItem value="Reason For Loss">Reason For Loss</MenuItem>
          <MenuItem value="Sales Cycle Duration">Sales Cycle Duration</MenuItem>
          <MenuItem value="Stage">Stage</MenuItem>
          <MenuItem value="Type">Type</MenuItem>
        </Select> */}
        <Select value="Asc" size="small">
          <MenuItem value="Asc">Asc</MenuItem>
        </Select>
      </Box>
      
      <Grid container columnGap={5}> 
        <Grid item xs={2}>
          <Box
            sx={{
              p: 2,
              borderRight: "1px solid #e0e0e0",
              height: "72vh",
              background: "black",
              overflowY: "auto",
              marginTop: "0px"
            }}
          >
            <Typography variant="h6" color="white" style={{fontSize: "16px"}}>Filter Deals by</Typography>
            <TextField
              variant="outlined"
              placeholder="Search"
              size="small"
              fullWidth
              sx={{ mb: 2 }}
              value={searchTerm}
              onChange={handleSearchChange}
            />

            <Typography
              variant="body2"
              color="white"
              sx={{ mb: 1, cursor: "pointer", fontSize: "16px"}}
              onClick={toggleSystemFilters}
            >
              System Defined Filters <IconButton
                sx={{
                  color: "white",
                  transform: systemFiltersVisible ? 'rotate(90deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s'
                }}
              >
                <ArrowRightIcon />
              </IconButton>
            </Typography>
            {systemFiltersVisible && (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {filteredSystemDefinedFilters.map((filter) => (
                  <FormControlLabel
                    key={filter}
                    control={<Checkbox />}
                    label={filter}
                    sx={{ color: "white" }}
                  />
                ))}
              </Box>
            )}

            <Typography
              variant="body2"
              color="white"
              sx={{ mt: 2, mb: 1, cursor: "pointer", fontSize: "16px" }}
              onClick={toggleFieldFilters}
            >
              Filter By Fields <IconButton
                sx={{
                  color: "white",
                  transform: fieldFiltersVisible ? 'rotate(90deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s'
                }}
              >
                <ArrowRightIcon />
              </IconButton>
            </Typography>
            {fieldFiltersVisible && (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {filteredFieldFilters.map((filter) => (
                  <FormControlLabel
                    key={filter}
                    control={<Checkbox />}
                    label={filter}
                    sx={{ color: "white" }}
                  />
                ))}
              </Box>
            )}
          </Box>
        </Grid>

        <Grid item xs={9.5} style={{display: 'flex', overflow: 'auto'}} >
        {stages.map((stage, index) => (
            <Paper key={index} sx={{ minWidth: 300, mr: 2, p: 2, bgcolor: '#e3f2fd' }}>
              <Typography variant="h6">
                {stage.name} <span style={{ color: 'gray' }}>{stage.count} • {stage.percentage}%</span>
              </Typography>
              <Typography variant="subtitle1">${stage.totalAmount.toLocaleString()}</Typography>
              
              {deals[stage.name].map((deal) => (
                <Paper key={deal.id} sx={{ mt: 2, p: 1, bgcolor: 'white' }}>
                  <Typography variant="subtitle1">{deal.name}</Typography>
                  <Typography variant="body2">{deal.company}</Typography>
                  <Typography variant="body2">{deal.owner}</Typography>
                  <Typography variant="body2">{deal.contact}</Typography>
                  <Typography variant="body2">${deal.amount.toLocaleString()}</Typography>
                  <Typography variant="body2" color="error">{deal.date}</Typography>
                </Paper>
              ))}
            </Paper>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AllDeal;