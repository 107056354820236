import React, { useState, useEffect } from "react";
import { Box, Typography,  IconButton, InputLabel,FormControlLabel, Checkbox, Select, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Paper, Button, Grid, Menu, MenuItem, FormControl } from '@mui/material';
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { Helmet } from 'react-helmet-async';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "../../utils/axios";
import { useNavigate } from "react-router-dom";
import Header from "../../assets/charts/Header";
import { useUser } from "../../contexts/auth";
import { useTheme } from "@mui/material/styles";
import '../../App.css'
import ViewListIcon from '@mui/icons-material/ViewList'; // Import the List View icon
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import GridViewIcon from '@mui/icons-material/GridView';
import TableViewIcon from '@mui/icons-material/TableView';
import TocIcon from '@mui/icons-material/Toc';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const AllAccount = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [accounts, setAccount] = useState([]);
//   const [accounts, setAccounts] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteLeadId, setDeleteLeadId] = useState(null);
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState(null);
  const { userrole, companycode } = useUser();
  const [selectedView, setSelectedView] = useState('All Accounts');
  const [viewAnchorEl, setViewAnchorEl] = useState(null);
 const [systemFiltersVisible, setSystemFiltersVisible] = useState(true);
  const [fieldFiltersVisible, setFieldFiltersVisible] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [alphabetFilter, setAlphabetFilter] = useState('');
  const [nameFilter, setNameFilter] = useState('All');
  const [filteredAccounts, setFilteredAccounts] = useState(accounts);
  const fetchData = async () => {
    try {
      if (companycode !== undefined && userrole !== undefined) {
        const response = await axios.get("/getaccounts", {
          params: {
            companycode: companycode,
            userrole: userrole,
          },
        });
        if (response && response.data) {
          setAccount(response.data);
        }
      }
    } catch (error) {
      console.error("Error fetching accounts:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [companycode, userrole]);
  useEffect(() => {
    setFilteredAccounts(
      accounts.filter(account => {
        if (nameFilter === 'All') return true;
        return account.accountName && account.accountName.charAt(0).toUpperCase() === nameFilter;
      })
    );
  }, [nameFilter, accounts]);

  const systemDefinedFilters = [
    "Touched Records",
    "Untouched Records",
    "Record Action",
    "Related Records Action",
    "Locked",
    "Deals",
    "Deal Amount",
    "Deal Stage",
    "Deal Owner",
    "Deal Closing Date",
    "Contacts"
  ];

  const fieldFilters = [
    "Account Name",
    "Account Number",
    "Account Owner",
    "Account Site",
    "Account Type",
    "Annual Revenue",
    "Billing City",
    "Billing Code",
    "Billing Country",
    "Billing State",
    "Billing Street",
    "Created By",
    "Created Time",
    "Employees",
    "Fax",
    "Industry",
    "Last Activity Time-",
    "Modified By",
    "Modified Time",
    "Ownership",
    "Parent Account",
    "Phone",
    "Rating",
    "Shipping City",
    "Shipping Code",
    "Shipping Country",
    "Shipping State",
    "Shipping Street",
    "SIC Code",
    "Tag",
    "Ticker Symbol",
    "Website",
  ];

  const toggleSystemFilters = () => {
    setSystemFiltersVisible(!systemFiltersVisible);
  };

  const toggleFieldFilters = () => {
    setFieldFiltersVisible(!fieldFiltersVisible);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredSystemDefinedFilters = systemDefinedFilters.filter((filter) =>
    filter.toLowerCase().includes(searchTerm)
  );

  const filteredFieldFilters = fieldFilters.filter((filter) =>
    filter.toLowerCase().includes(searchTerm)
  );
  const handleViewMenuOpen = (event) => {
    setViewAnchorEl(event.currentTarget);
  };


  const handleChange = (event) => {
    setSelectedView(event.target.value);
  };

  const handleViewMenuClose = () => {
    setViewAnchorEl(null);
  };

  const viewMenuOpen = Boolean(viewAnchorEl);


  const handleNameFilterChange = (event) => {
    setNameFilter(event.target.value);
  };



  
const columns = [
    { 
      field: "accountname", 
      headerName: "Account Name", 
      flex: 0.3,
      renderHeader: (params) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography>{params.colDef.headerName}</Typography>
          <Select
            value={nameFilter}
            onChange={handleNameFilterChange}
            sx={{ ml: 1, height: 30 }}
          >
            <MenuItem value="All">All</MenuItem>
            {Array.from(Array(26)).map((_, i) => (
              <MenuItem key={i} value={String.fromCharCode(65 + i)}>
                {String.fromCharCode(65 + i)}
              </MenuItem>
            ))}
          </Select>
        </Box>
      )
    },
    { field: "phone", headerName: "Phone", flex: 0.3 },
    { field: "website", headerName: "Website", flex: 0.3 },
    { field: "accountowner", headerName: "Account Owner", flex: 0.3 },
  ];

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

 const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete(`/deleteLead/${deleteLeadId}`);
      if (response && response.status === 200) {
        setAccount((prevLeads) =>
          prevLeads.filter((lead) => lead.id !== deleteLeadId)
        );
        setDeleteSuccessMessage("Lead deleted successfully!");
        setTimeout(() => {
          setDeleteSuccessMessage(null);
        }, 3000);
      } else {
        console.error("Failed to delete lead");
      }
    } catch (error) {
      console.error("Error deleting lead:", error);
    }
    setOpenDialog(false);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuOpen = Boolean(anchorEl);
  const [selectedSystemFilters, setSelectedSystemFilters] = useState([]);
  const [selectedFieldFilters, setSelectedFieldFilters] = useState([]);
  // Handle system filter checkbox selection
const handleSystemFilterChange = (event) => {
  const { name, checked } = event.target;
  setSelectedSystemFilters((prev) =>
    checked ? [...prev, name] : prev.filter((filter) => filter !== name)
  );
};

// Handle field filter checkbox selection
const handleFieldFilterChange = (event) => {
  const { name, checked } = event.target;
  setSelectedFieldFilters((prev) =>
    checked ? [...prev, name] : prev.filter((filter) => filter !== name)
  );
};

  useEffect(() => {
    let updatedaccounts = accounts;
  
    // Apply system filters
    if (selectedSystemFilters.length > 0) {
      updatedaccounts = updatedaccounts.filter((lead) => {
        // Add your system-defined filtering logic here.
        // For demonstration, I'm just filtering by a simple condition.
        // You'll need to customize this based on the system filters.
        return selectedSystemFilters.every((filter) =>
          lead[filter.toLowerCase().replace(/\s+/g, '')]
        );
      });
    }
  
    // Apply field filters
    if (selectedFieldFilters.length > 0) {
      updatedaccounts = updatedaccounts.filter((lead) => {
        // Add your field-based filtering logic here
        // Example: if the field filter is "Company", check if `lead.company` exists
        return selectedFieldFilters.every((filter) =>
          lead[filter.toLowerCase().replace(/\s+/g, '')]
        );
      });
    }
  
    setFilteredAccounts(updatedaccounts);
  }, [selectedSystemFilters, selectedFieldFilters, accounts]);
  return (
    <Box>
    <Helmet>
      <title>Accounts - Contacttab.com</title>
    </Helmet>
      {/* Header Section */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2, backgroundColor: 'white', color: 'black', marginTop: "5.2%", padding: '5px'}}>
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small" style={{color: 'white', background: 'black', borderRadius: '5px'}}>
      {/* <InputLabel id="lead-select-label"  style={{color: 'white'}} >Public Views</InputLabel> */}
      <Select
        labelId="lead-select-label"
        value={selectedView}
        onChange={handleChange}
        label="Public Views"
        style={{color: 'white'}}
      >
        <MenuItem value="All Accounts">All Accounts</MenuItem>
        <MenuItem value="All Locked Accounts">All Locked Accounts</MenuItem>
        <MenuItem value="My Accounts">My Accounts</MenuItem>
        <MenuItem value="New Last Week">New Last Week</MenuItem>
        <MenuItem value="New This Week">New This Week</MenuItem>
        <MenuItem value="Recently Created Accounts">Recently Created Accounts</MenuItem>
        <MenuItem value="Recently Modified Accouts">Recently Modified Accouts</MenuItem>
        <MenuItem value="Unread Accounts">Unread Accounts</MenuItem>
      </Select>
    </FormControl>
        <Box>
        <Button variant="contained" onClick={handleViewMenuOpen} startIcon={<ViewListIcon />}>List View</Button> {/* Added List View Button */}
          <Button variant="contained" color="primary" sx={{ mr: 2 }} onClick={() => navigate('/create-account')}>Create Account</Button>
          <Button variant="contained" onClick={handleMenuOpen}>Actions</Button>
          <Menu anchorEl={anchorEl} open={menuOpen} onClose={handleMenuClose}>
          <MenuItem onClick={handleMenuClose}>Mass Delete</MenuItem>
            <MenuItem onClick={handleMenuClose}>Mass Update</MenuItem>
            <MenuItem onClick={handleMenuClose}>Manage Tags</MenuItem>
            <MenuItem onClick={handleMenuClose}>Drafts</MenuItem>
            <MenuItem onClick={handleMenuClose}>Deduplicate Accounts</MenuItem>
            <MenuItem onClick={handleMenuClose}>Create Client Script</MenuItem>
            <MenuItem onClick={handleMenuClose}>Export Accounts</MenuItem>
            <MenuItem onClick={handleMenuClose}>Zoho Sheet View</MenuItem>
            <MenuItem onClick={handleMenuClose}>Print View</MenuItem>
          </Menu>
          <Menu anchorEl={viewAnchorEl} open={viewMenuOpen} onClose={handleViewMenuClose}> {/* Added List View Menu */}
            <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><TocIcon /></span> List View</MenuItem>
            <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><ViewKanbanIcon /></span> Kanban View</MenuItem>
            <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><ViewListIcon /></span> Custom List View</MenuItem>
            <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><GridViewIcon /></span> Tile View</MenuItem>
            <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><TableViewIcon /></span> Table View</MenuItem>
          </Menu>
        </Box>
      </Box>

      {/* Main Content */}
      <Grid container columnGap={5}> 
     
        <Grid item xs={2}>
      <Box
        sx={{
          p: 2,
          borderRight: "1px solid #e0e0e0",
          height: "76vh",
          background: "black",
          overflowY: "auto",
          marginTop: "20px"
        }}
      >
        <Typography variant="h6" color="white" style={{fontSize: "16px"}}>Filter Accounts by</Typography>
        <TextField
          variant="outlined"
          placeholder="Search"
          size="small"
          fullWidth
          sx={{ mb: 2 }}
          value={searchTerm}
          onChange={handleSearchChange}
        />

        <Typography
          variant="body2"
          color="white"
          sx={{ mb: 1, cursor: "pointer", fontSize: "16px"}}
          onClick={toggleSystemFilters}
        >
        System Defined Filters <IconButton
            sx={{
              color: "white",
              transform: systemFiltersVisible ? 'rotate(90deg)' : 'rotate(0deg)',
              transition: 'transform 0.3s'
            }}
          >
            <ArrowRightIcon />
          </IconButton>
         
        </Typography>
        {systemFiltersVisible && (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {filteredSystemDefinedFilters.map((filter) => (
              <FormControlLabel
                key={filter}
                control={
                <Checkbox 
                name={filter}
                onChange={handleSystemFilterChange}
                checked={selectedSystemFilters.includes(filter)} 
                />}
                label={filter}
                sx={{ color: "white" }}
              />
            ))}
          </Box>
        )}

        <Typography
          variant="body2"
          color="white"
          sx={{ mt: 2, mb: 1, cursor: "pointer", fontSize: "16px" }}
          onClick={toggleFieldFilters}
        >
          Filter By Fields <IconButton
            sx={{
              color: "white",
              transform: fieldFiltersVisible ? 'rotate(90deg)' : 'rotate(0deg)',
              transition: 'transform 0.3s'
            }}
          >
            <ArrowRightIcon />
          </IconButton>
        </Typography>
        {fieldFiltersVisible && (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {filteredFieldFilters.map((filter) => (
              <FormControlLabel
                key={filter}
                control={
                <Checkbox
                  name={filter}
                  onChange={handleFieldFilterChange}
                  checked={selectedFieldFilters.includes(filter)}
                  />}
                label={filter}
                sx={{ color: "white" }}
              />
            ))}
          </Box>
        )}
      </Box>
    </Grid>
            <Grid item xs={9.5}>
          <Paper elevation={3} style={{ padding: '20px', height: '76vh', marginTop: '20px', color: 'black' }} className="listViewStyle">
            <Typography variant="h6" sx={{ mb: 2 }} style={{color: 'white'}}>Total Records: {filteredAccounts.length}</Typography>
            <DataGrid
              columns={columns}
              rows={filteredAccounts}
              pageSize={10}
              rowsPerPageOptions={[10]}
              checkboxSelection
              disableSelectionOnClick
              getRowId={(row) => row.accountid}
            />
            
            
            {/* Confirmation Dialog */}
            <Dialog open={openDialog} onClose={handleCloseDialog}>
              <DialogTitle>Confirm Delete</DialogTitle>
              <DialogContent>Are you sure you want to delete this lead?</DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                  No
                </Button>
                <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AllAccount;
