import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Helmet } from 'react-helmet-async';
import * as yup from "yup";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import {
  TextField,
  Button,
  Grid,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  createTheme,
  styled,
} from "@mui/material";
import axios from "../../utils/axios";
import { useUser } from "../../contexts/auth";
const CustomTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
          borderColor: 'white',
          color: 'white',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'white',
        },
      },
      '& .MuiInputLabel-root': {
        color: 'white',
      },
      '& .MuiInputBase-input': {
        color: 'white',
      },
}));

const CustomSelect = styled(Select)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'white',
        },
        '&:hover fieldset': {
          borderColor: 'white',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'white',
        },
      },
      '& .MuiInputLabel-root': {
        color: 'white',
      },
      '& .MuiInputBase-input': {
        color: 'white',
      },
}));

const CreateContact = () => {
  const {
    userrole,
    username,
    userid,
    companycode,
    isAuthRole,
    isAuthorized,
    token,
  } = useUser();
  const [contactOwners, setContactOwners] = useState([]);
  const [leadSources, setLeadSources] = useState([]);
  const [selectedFileNamePan, setSelectedFileNamePan] = useState("");
  const [contactImage, setContactImage] = useState(null);
  const initialValues = {
    contactOwner: "",
    leadSource: "",
    firstName: "",
    lastName: "",
    accountName: "",
    title: "",
    email: "",
    department: "",
    phone: "",
    homePhone: "",
    otherPhone: "",
    fax: "",
    mobile: "",
    dateOfBirth: "",
    assistant: "",
    asstPhone: "",
    emailOptOut: false,
    skypeId: "",
    secondaryEmail: "",
    twitter: "",
    reportingTo: "",
    mailingStreet: "",
    otherStreet: "",
    mailingCity: "",
    otherCity: "",
    mailingState: "",
    otherState: "",
    mailingZip: "",
    otherZip: "",
    mailingCountry: "",
    otherCountry: "",
    description: "",
    companyCodes: userrole === "Superadmin" && companycode === 1 ? "" : companycode, // Initialize company code
  }
  const [companyCodes, setCompanyCodes] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  useEffect(() => {
    // Simulating API call to fetch contact owners
    setContactOwners([
      { id: 1, name: "Vivek Kumar Yadav" },
      { id: 2, name: "John Doe" },
    ]);
    // Simulating API call to fetch lead sources
    setLeadSources(["Web", "Phone", "Email", "Other"]);
  }, []);

  const validationSchema = yup.object({
    contactOwner: yup.string().required("Contact Owner is required"),
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    phone: yup.string().required("Phone is required"),
  });
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async () => {
      try {
        if (userrole !== "Superadmin" || companycode !== 1) {
          values.companyCodes = companycode;
        }
        const formDataToSend = new FormData();

        formDataToSend.append("formData", JSON.stringify(values));
        formDataToSend.append("contactImage", contactImage);
        console.log(JSON.stringify(values));
        const response = await axios.post("/addcontact", formDataToSend);
        if (response.status === 200) {
          // Update state with success message
          setSuccessMessage("contact data added successful");
          setOpenSuccess(true);
          // Hide the success message after 3 seconds (adjust duration as needed)
          setTimeout(() => {
            setOpenSuccess(false);
            setSuccessMessage("");
          }, 3000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);

        // Handle specific error cases
        if (error.response) {
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
        }
      }
    },
  });
  // const formik = useFormik({
  //   initialValues: {
  //     contactOwner: "",
  //     leadSource: "",
  //     firstName: "",
  //     lastName: "",
  //     accountName: "",
  //     title: "",
  //     email: "",
  //     department: "",
  //     phone: "",
  //     homePhone: "",
  //     otherPhone: "",
  //     fax: "",
  //     mobile: "",
  //     dateOfBirth: "",
  //     assistant: "",
  //     asstPhone: "",
  //     emailOptOut: false,
  //     skypeId: "",
  //     secondaryEmail: "",
  //     twitter: "",
  //     reportingTo: "",
  //     mailingStreet: "",
  //     otherStreet: "",
  //     mailingCity: "",
  //     otherCity: "",
  //     mailingState: "",
  //     otherState: "",
  //     mailingZip: "",
  //     otherZip: "",
  //     mailingCountry: "",
  //     otherCountry: "",
  //     description: "",
  //   },
  //   validationSchema: validationSchema,
  //   onSubmit: (values, { resetForm }) => {
  //       console.log("Form Values:", values);
  //       console.log("Contact Image:", contactImage);
  //       // Here you would typically make an API call to save the contact
  //       // After successful submission, reset the form
  //       resetForm();
  //       setContactImage(null); // Reset the contact image as well
  //     },
  // });

  const handleImageChange = (event) => {
    const fileName = event.target.files[0].name;
    setSelectedFileNamePan(fileName);
    const selectedFiles = event.target.files[0];
    if (selectedFiles) {
      // Set the file in the state
      setContactImage(selectedFiles);
      handleChange({
        target: {
          name: "leadimage",
          value: selectedFiles,
          size: selectedFiles.size,
        },
      });
    }
  };
  // const handleImageChange = (event) => {
  //   if (event.target.files && event.target.files[0]) {
  //     setContactImage(URL.createObjectURL(event.target.files[0]));
  //   }
  // };

  return (
    <>
    <Helmet>
      <title>Create Contact- Contacttab.com</title>
    </Helmet>
    <form onSubmit={handleSubmit}>
      <Box m="20px">
        <h1>Create Contact</h1>

        <h2>Contact Image</h2>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <input
                        type="file"
                        accept="image/*"
                        id="lead-image-upload"
                        name="leadimage"
                        style={{ display: 'none' }}
                        onChange={handleImageChange}
                      />
          <label htmlFor="contact-image-upload">
            <Button
              variant="contained"
              component="span"
              startIcon={<CameraAltIcon />}
            >
              Upload Image
            </Button>
          </label>
          {contactImage && (
            <Box sx={{ ml: 2 }}>
              {/* <img src={contactImage} alt="Contact" style={{ width: 100, height: 100, objectFit: 'cover', borderRadius: '50%' }} /> */}
              {selectedFileNamePan && (
                        <div>Selected file: {selectedFileNamePan}</div>
                      )}
            </Box>
          )}
        </Box>

        <h2 style={{ margin: '20px 0px' }}>Contact Information</h2>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Contact Owner</InputLabel>
              <CustomSelect
                name="contactOwner"
                value={values.contactOwner}
                onChange={handleChange}
                error={touched.contactOwner && Boolean(errors.contactOwner)}
              >
                {contactOwners.map((owner) => (
                  <MenuItem key={owner.id} value={owner.id}>{owner.name}</MenuItem>
                ))}
              </CustomSelect>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Lead Source</InputLabel>
              <CustomSelect
                name="leadSource"
                value={values.leadSource}
                onChange={handleChange}
              >
                {leadSources.map((source) => (
                  <MenuItem key={source} value={source}>{source}</MenuItem>
                ))}
              </CustomSelect>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="firstName"
              label="First Name"
              value={values.firstName}
              onChange={handleChange}
              error={touched.firstName && Boolean(errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="lastName"
              label="Last Name"
              value={values.lastName}
              onChange={handleChange}
              error={touched.lastName && Boolean(errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="accountName"
              label="Account Name"
              value={values.accountName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="title"
              label="Title"
              value={values.title}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="email"
              label="Email"
              value={values.email}
              onChange={handleChange}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="department"
              label="Department"
              value={values.department}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="phone"
              label="Phone"
              value={values.phone}
              onChange={handleChange}
              error={touched.phone && Boolean(errors.phone)}
              helperText={touched.phone && errors.phone}
            />
          </Grid>
          {userrole === "Superadmin" && companycode === 1 ? (
  <Grid item xs={6}>
    <FormControl
      fullWidth
    >
      <InputLabel>Company Code</InputLabel>
      <CustomSelect
        labelId="companyCode-label"
        id="companyCodes"
        onChange={handleChange}
        value={values.companyCodes}
        name="companyCodes"
        error={touched.companyCodes && !!errors.companyCodes}
        helperText={
          touched.companyCodes && errors.companyCodes
            ? errors.companyCodes
            : ""
        }
        label="Company Code"
        MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
      >
        <MenuItem value="">Select Company Code</MenuItem>
        {companyCodes.map((code, index) => (
          <MenuItem key={index} value={code}>
            {code}
          </MenuItem>
        ))}
      </CustomSelect>
    </FormControl>
  </Grid>
) : (
  <Grid item xs={6}>
    <TextField
      fullWidth
      variant="filled"
      type="text"
      label="Company Code"
      value={companycode}
      name="companyCodes"
      sx={{ gridColumn: "span 2" }}
    />
  </Grid>
)}
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="homePhone"
              label="Home Phone"
              value={values.homePhone}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="otherPhone"
              label="Other Phone"
              value={values.otherPhone}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="fax"
              label="Fax"
              value={values.fax}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="mobile"
              label="Mobile"
              value={values.mobile}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="dateOfBirth"
              label="Date of Birth"
              type="date"
              value={values.dateOfBirth}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="assistant"
              label="Assistant"
              value={values.assistant}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="asstPhone"
              label="Assistant Phone"
              value={values.asstPhone}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="skypeId"
              label="Skype ID"
              value={values.skypeId}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="secondaryEmail"
              label="Secondary Email"
              value={values.secondaryEmail}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="twitter"
              label="Twitter"
              value={values.twitter}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="reportingTo"
              label="Reporting To"
              value={values.reportingTo}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <h2 style={{ margin: '20px 0px' }}>Address Information</h2>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="mailingStreet"
              label="Mailing Street"
              value={values.mailingStreet}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="otherStreet"
              label="Other Street"
              value={values.otherStreet}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="mailingCity"
              label="Mailing City"
              value={values.mailingCity}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="otherCity"
              label="Other City"
              value={values.otherCity}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="mailingState"
              label="Mailing State"
              value={values.mailingState}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="otherState"
              label="Other State"
              value={values.otherState}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="mailingZip"
              label="Mailing Zip"
              value={values.mailingZip}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="otherZip"
              label="Other Zip"
              value={values.otherZip}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="mailingCountry"
              label="Mailing Country"
              value={values.mailingCountry}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="otherCountry"
              label="Other Country"
              value={values.otherCountry}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <h2 style={{ margin: '20px 0px' }}>Additional Information</h2>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomTextField
              fullWidth
              name="description"
              label="Description"
              multiline
              rows={4}
              value={values.description}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button type="button" onClick={() => resetForm()} variant="contained" color="primary" style={{ margin: '20px', background: 'blue', padding: '5px 10px' }} >
            Cancel
          </Button>
          <Button type="submit" variant="contained" color="primary" style={{ margin: '20px', background: 'blue', padding: '5px 10px' }}>
            Save
          </Button>
        </Box>
      </Box>
    </form>
    <br/>

    </>
  );
};

export default CreateContact;
