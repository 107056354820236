import React, { useState, useEffect } from "react";
import { Box, Typography,  IconButton, InputLabel,FormControlLabel, Checkbox, Select, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Paper, Button, Grid, Menu, MenuItem, FormControl } from '@mui/material';
import { DataGrid } from "@mui/x-data-grid";
import { Helmet } from 'react-helmet-async';
import { tokens } from "../../theme";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "../../utils/axios";
import { useNavigate } from "react-router-dom";
import Header from "../../assets/charts/Header";
import { useUser } from "../../contexts/auth";
import { useTheme } from "@mui/material/styles";
import '../../App.css'
import ViewListIcon from '@mui/icons-material/ViewList'; // Import the List View icon
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import GridViewIcon from '@mui/icons-material/GridView';
import TableViewIcon from '@mui/icons-material/TableView';
import TocIcon from '@mui/icons-material/Toc';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const AllTask = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [leads, setLeads] = useState([]);
//   const [accounts, setAccounts] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteLeadId, setDeleteLeadId] = useState(null);
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState(null);
  const { userrole, companycode } = useUser();
  const [selectedView, setSelectedView] = useState('All Tasks');
  const [viewAnchorEl, setViewAnchorEl] = useState(null);
 const [systemFiltersVisible, setSystemFiltersVisible] = useState(true);
  const [fieldFiltersVisible, setFieldFiltersVisible] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [alphabetFilter, setAlphabetFilter] = useState('');
  const [nameFilter, setNameFilter] = useState('All');


  const tasks = [
    { id: 1, subject: "Register for upcoming CRM Webinars", dueDate: "08/07/2024", status: "Not Started", priority: "Low", relatedTo: "King (Sample)", 	contactName: "Kris Marrier (Sample)", taskOwner: "Vivek Kumar Yadav"},
    { id: 2, subject: "Refer CRM Videos", dueDate: "18/07/2024", status: "Started", priority: "High", relatedTo: "King (Sample)", 	contactName: "Kris Marrier (Sample)", taskOwner: "Vivek Kumar Yadav"},
    { id: 3, subject: "Competitor Comparison Document", dueDate: "12/07/2024", status: "Started", priority: "Normal", relatedTo: "King (Sample)", 	contactName: "Kris Marrier (Sample)", taskOwner: "Vivek Kumar Yadav"},
    { id: 4, subject: "Get Apporval from Manager", dueDate: "11/07/2024", status: "Not Started", priority: "Low", relatedTo: "King (Sample)", 	contactName: "Kris Marrier (Sample)", taskOwner: "Vivek Kumar Yadav"},
    { id: 5, subject: "Get Approval from Manager", dueDate: "08/07/2024", status: "Started", priority: "Normal", relatedTo: "King (Sample)", 	contactName: "Kris Marrier (Sample)", taskOwner: "Vivek Kumar Yadav"},
    { id: 6, subject: "Get Apporval from Manager", dueDate: "/07/2024", status: "Started", priority: "Low", relatedTo: "King (Sample)", 	contactName: "Kris Marrier (Sample)", taskOwner: "Vivek Kumar Yadav"},
    { id: 7, subject: "Register for upcoming CRM Webinars", dueDate: "10/07/2024", status: "Not Started", priority: "Low", relatedTo: "King (Sample)", 	contactName: "Kris Marrier (Sample)", taskOwner: "Vivek Kumar Yadav"},
    { id: 8, subject: "Customize CRM to your needs", dueDate: "09/07/2024", status: "Not Started", priority: "Low", relatedTo: "King (Sample)", 	contactName: "Kris Marrier (Sample)", taskOwner: "Vivek Kumar Yadav"},
    { id: 9, subject: "Complete CRM Getting Started steps", dueDate: "10/07/2024", status: "Not Started", priority: "Low", relatedTo: "King (Sample)", 	contactName: "Kris Marrier (Sample)", taskOwner: "Vivek Kumar Yadav"},
   
    // More mock data can be added here
  ];

  const [filteredAccounts, setFilteredAccounts] = useState(tasks);

  useEffect(() => {
    setFilteredAccounts(
      tasks.filter(account => {
        if (nameFilter === 'All') return true;
        return account.subject && account.subject.charAt(0).toUpperCase() === nameFilter;
      })
    );
  }, [nameFilter, tasks]);

  const systemDefinedFilters = [
    "Touched Records",
    "Untouched Records",
    "Record Action",
    "Related Records Action",
    "Locked",
  ];

  const fieldFilters = [
    "Closed Time",
    "Contact Name",
    "Created By",
    "Created Time",
    "Due Date",
    "Modified By",
    "Modified Time",
    "Priority",
    "Related To",
    "Status",
    "Subject",
    "Tag",
    "Task Owner",
  ];

  const toggleSystemFilters = () => {
    setSystemFiltersVisible(!systemFiltersVisible);
  };

  const toggleFieldFilters = () => {
    setFieldFiltersVisible(!fieldFiltersVisible);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredSystemDefinedFilters = systemDefinedFilters.filter((filter) =>
    filter.toLowerCase().includes(searchTerm)
  );

  const filteredFieldFilters = fieldFilters.filter((filter) =>
    filter.toLowerCase().includes(searchTerm)
  );
  const handleViewMenuOpen = (event) => {
    setViewAnchorEl(event.currentTarget);
  };


  const handleChange = (event) => {
    setSelectedView(event.target.value);
  };

  const handleViewMenuClose = () => {
    setViewAnchorEl(null);
  };

  const viewMenuOpen = Boolean(viewAnchorEl);


  const handleNameFilterChange = (event) => {
    setNameFilter(event.target.value);
  };



  
const columns = [
    { 
      field: "subject", 
      headerName: "Subject", 
      flex: 0.3,
      renderHeader: (params) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography>{params.colDef.headerName}</Typography>
          <Select
            value={nameFilter}
            onChange={handleNameFilterChange}
            sx={{ ml: 1, height: 30 }}
          >
            <MenuItem value="All">All</MenuItem>
            {Array.from(Array(26)).map((_, i) => (
              <MenuItem key={i} value={String.fromCharCode(65 + i)}>
                {String.fromCharCode(65 + i)}
              </MenuItem>
            ))}
          </Select>
        </Box>
      )
    },
    { field: "dueDate", headerName: "Due Date", flex: 0.3 },
    { field: "status", headerName: "Status", flex: 0.3 },
    { field: "priority", headerName: "Priority", flex: 0.3 },
    { field: "relatedTo", headerName: "Related To", flex: 0.3 },
    { field: "contactName", headerName: "Contact Name", flex: 0.3 },
    { field: "taskOwner", headerName: "Task Owner", flex: 0.3 },
   
  ];

  const handleStatusChange = async (event, lead) => {
    try {
      const updatedLeads = leads.map((leadItem) =>
        leadItem.id === lead ? { ...leadItem, leadstatus: event.target.value } : leadItem
      );
      setLeads(updatedLeads);

      const response = await axios.put(
        `/updateLeadState/${lead}`,
        {
          leadstatus: event.target.value,
        }
      );

      if (response && response.status === 200) {
        // fetchData();
        setDeleteSuccessMessage("Lead status updated successfully!");
      } else {
        console.error("Failed to update lead status");
      }
    } catch (error) {
      console.error("Error updating lead status:", error);
    }
  };

  const handleEdit = (lead) => {
    navigate(`/updatelead/${lead}`);
  };

  const handleDelete = (leadId) => {
    setDeleteLeadId(leadId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete(`/deleteLead/${deleteLeadId}`);
      if (response && response.status === 200) {
        setLeads((prevLeads) =>
          prevLeads.filter((lead) => lead.id !== deleteLeadId)
        );
        setDeleteSuccessMessage("Lead deleted successfully!");
        setTimeout(() => {
          setDeleteSuccessMessage(null);
        }, 3000);
      } else {
        console.error("Failed to delete lead");
      }
    } catch (error) {
      console.error("Error deleting lead:", error);
    }
    setOpenDialog(false);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuOpen = Boolean(anchorEl);

  return (
    <Box>
    <Helmet>
      <title>Tasks - Contacttab.com</title>
    </Helmet>
      {/* Header Section */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2, backgroundColor: 'white', color: 'black', marginTop: "5.2%", padding: '5px'}}>
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small" style={{color: 'white', background: 'black', borderRadius: '5px'}}>
      {/* <InputLabel id="lead-select-label"  style={{color: 'white'}} >Public Views</InputLabel> */}
      <Select
        labelId="lead-select-label"
        value={selectedView}
        onChange={handleChange}
        label="Public Views"
        style={{color: 'white'}}
      >
        <MenuItem value="All Tasks">All Tasks</MenuItem>
        <MenuItem value="All Locked Tasks">All Locked Tasks</MenuItem>
        <MenuItem value="Closed Tasks">Closed Tasks</MenuItem>
        <MenuItem value="My Closed Tasks">My Closed Tasks</MenuItem>
        <MenuItem value="My Next 7 Days + Overdue Tasks">My Next 7 Days + Overdue Tasks</MenuItem>
        <MenuItem value="My Open Tasks">My Open Tasks</MenuItem>
        <MenuItem value="My Overdue Tasks">My Overdue Tasks</MenuItem>
        <MenuItem value="My Tasks">My Tasks</MenuItem>
        <MenuItem value="My Today + Overdue Tasks">My Today + Overdue Tasks</MenuItem>
        <MenuItem value="My Today's Tasks">My Today's Tasks</MenuItem>
        <MenuItem value="My Tomorrow's Tasks">My Tomorrow's Tasks</MenuItem>
        <MenuItem value="Next 7 Days + Overdue Tasks">Next 7 Days + Overdue Tasks</MenuItem>
        <MenuItem value="Open Tasks">Open Tasks</MenuItem>
        <MenuItem value="Overdue Tasks">Overdue Tasks</MenuItem>
        <MenuItem value="Today + Overdue Tasks">Today + Overdue Tasks</MenuItem>
        <MenuItem value="Today's Tasks">Today's Tasks</MenuItem>
        <MenuItem value="Tomorrow's Tasks">Tomorrow's Tasks</MenuItem>
        
      </Select>
    </FormControl>
        <Box>
        <Button variant="contained" onClick={handleViewMenuOpen} startIcon={<ViewListIcon />}>List View</Button> {/* Added List View Button */}
          <Button variant="contained" color="primary" sx={{ mr: 2 }} onClick={() => navigate('/create-task')}>Create Task</Button>
          <Button variant="contained" onClick={handleMenuOpen}>Actions</Button>
          <Menu anchorEl={anchorEl} open={menuOpen} onClose={handleMenuClose}>
          <MenuItem onClick={handleMenuClose}>Mass Delete</MenuItem>
            <MenuItem onClick={handleMenuClose}>Mass Update</MenuItem>
            <MenuItem onClick={handleMenuClose}>Manage Tags</MenuItem>
            <MenuItem onClick={handleMenuClose}>Export Tasks</MenuItem>
            <MenuItem onClick={handleMenuClose}>Print View</MenuItem>
          </Menu>
          <Menu anchorEl={viewAnchorEl} open={viewMenuOpen} onClose={handleViewMenuClose}> {/* Added List View Menu */}
            <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><TocIcon /></span> List View</MenuItem>
            <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><ViewKanbanIcon /></span> Kanban View</MenuItem>
            <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><ViewListIcon /></span> Custom List View</MenuItem>
            <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><GridViewIcon /></span> Tile View</MenuItem>
            <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><TableViewIcon /></span> Table View</MenuItem>
          </Menu>
        </Box>
      </Box>

      {/* Main Content */}
      <Grid container columnGap={5}> 
     
        <Grid item xs={2}>
      <Box
        sx={{
          p: 2,
          borderRight: "1px solid #e0e0e0",
          height: "76vh",
          background: "black",
          overflowY: "auto",
          marginTop: "20px"
        }}
      >
        <Typography variant="h6" color="white" style={{fontSize: "16px"}}>Filter Tasks by</Typography>
        <TextField
          variant="outlined"
          placeholder="Search"
          size="small"
          fullWidth
          sx={{ mb: 2 }}
          value={searchTerm}
          onChange={handleSearchChange}
        />

        <Typography
          variant="body2"
          color="white"
          sx={{ mb: 1, cursor: "pointer", fontSize: "16px"}}
          onClick={toggleSystemFilters}
        >
        System Defined Filters <IconButton
            sx={{
              color: "white",
              transform: systemFiltersVisible ? 'rotate(90deg)' : 'rotate(0deg)',
              transition: 'transform 0.3s'
            }}
          >
            <ArrowRightIcon />
          </IconButton>
         
        </Typography>
        {systemFiltersVisible && (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {filteredSystemDefinedFilters.map((filter) => (
              <FormControlLabel
                key={filter}
                control={<Checkbox />}
                label={filter}
                sx={{ color: "white" }}
              />
            ))}
          </Box>
        )}

        <Typography
          variant="body2"
          color="white"
          sx={{ mt: 2, mb: 1, cursor: "pointer", fontSize: "16px" }}
          onClick={toggleFieldFilters}
        >
          Filter By Fields <IconButton
            sx={{
              color: "white",
              transform: fieldFiltersVisible ? 'rotate(90deg)' : 'rotate(0deg)',
              transition: 'transform 0.3s'
            }}
          >
            <ArrowRightIcon />
          </IconButton>
        </Typography>
        {fieldFiltersVisible && (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {filteredFieldFilters.map((filter) => (
              <FormControlLabel
                key={filter}
                control={<Checkbox />}
                label={filter}
                sx={{ color: "white" }}
              />
            ))}
          </Box>
        )}
      </Box>
    </Grid>
            <Grid item xs={9.5}>
          <Paper elevation={3} style={{ padding: '20px', height: '77vh', marginTop: '20px', color: 'black' }} className="listViewStyle">
            <Typography variant="h6" sx={{ mb: 2 }} style={{color: 'white'}}>Total Records: {filteredAccounts.length}</Typography>
            <DataGrid
              columns={columns}
              rows={filteredAccounts}
              pageSize={10}
              rowsPerPageOptions={[10]}
              checkboxSelection
              disableSelectionOnClick
            />
            
            
            {/* Confirmation Dialog */}
            <Dialog open={openDialog} onClose={handleCloseDialog}>
              <DialogTitle>Confirm Delete</DialogTitle>
              <DialogContent>Are you sure you want to delete this lead?</DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                  No
                </Button>
                <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AllTask;
