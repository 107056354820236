import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Snackbar,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";

import axios from "../../../utils/axios";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import {  useUser } from "../../../contexts/auth";
import Header from "../../../assets/charts/Header";
// import { useUser } from "../../../Provider";

const Adduser = () => {
  const {
    userrole,
    username,
    userid,
    companycode,
    isAuthRole,
    isAuthorized,
    token,
  } = useUser();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    age: "",
    phoneno: "",
    address: "",
    role: "",
    password:"",
    companyCodes:userrole === "Superadmin" && companycode === 1 ? "" : companycode, // Initialize company code
  };

  const ValidationSchema = yup.object().shape({
    firstname: yup.string().required("First name is required"),
    role: yup.string().required("Role is required"),
    companyCodes: yup.string().required("Company Code is required"),
    email: yup
      .string()
      .email("Email must be a valid")
      .required("Please enter the email"),
    phoneno: yup
      .string()
      .matches(/^[0-9]+$/, "Mobile Number should only contain numbers")
      .min(10, "Mobile Number must be at least 10 characters")
      .max(10, "Mobile Number must be at most 10 characters")
      .required("Mobile Number is required"),
      password: yup.string().min(6).required("Please enter the password"),
      conformpassword: yup.string().required().oneOf([yup.ref('password'), null]),
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [companyCodes, setCompanyCodes] = useState([]);
  const [birthDate, setBirthDate] = useState(new Date());
  const [joiningDate, setJoiningDate] = useState(new Date());

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/getCompanyRegistrations");
        setData(response.data);
        setFilteredData(response.data);
        console.log(response.data)
        const formattedCodes = response.data.map((company) =>
          company.establishmentid
        );
        setCompanyCodes(formattedCodes);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const selectDate = (sd) => {
    setBirthDate(sd);
  };

  const selectJoiningDate = (sd) => {
    setJoiningDate(sd);
  };
  const header ={
    headers:{
      Authorization: `Bearer EAAMVy5OZB2W4BO3xMiEuhNtYZBY4FqGfk9GGDOXTZCSAmj3V4kr12mUdM0aWTrzPZBYyD6F5dXhifyigaLFm5YTETOcSlgKm8hdF6bRh3F51qNLZAL7D91HGkKtvh6O2biCBbqyX2qrcsdPm9ELHltqNsZADOOFvDwJXYZBABR6i5FSuRZBRf8XZAZB9ZBRoqhnN4GZB`,
      Accept:`application/json`
    }
  }
  
  return (
    
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={async (values) => {
        try {
          if (userrole !== "Superadmin" || companycode !== 1) {
            values.companyCodes = companycode;
          }
          const formDataToSend = {
            ...values,
            username,
            userrole,
            companycode,
            dob: birthDate,
            joiningdate: joiningDate,
          };
          const response = await axios.post(
            "/userRegistration",
            formDataToSend,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          if (response.status === 201) {
            setSuccessMessage("Employee registration successful");
             // Call sendMsg function here
             const sendMsg =()=>{
              const body ={
                "messaging_product":"whatsapp",
                "to":"91"+values.phoneno,
                "type":"template",
                "template":{
                  "name":"notification ",
                  "language":{
                    "code":"en"
                  }
                }
              }
              axios.post('https://graph.facebook.com/v18.0/217459234774748/messages', body, header)
              .then((res)=>(
                console.log("msg sent success",res)
              ))
              .catch((err)=>(
                console.log(err)
              ))
            }
            sendMsg();
            setOpenSuccess(true);

            setTimeout(() => {
              setOpenSuccess(false);
              setSuccessMessage("");
            }, 3000);
          }
        } catch (error) {
          console.error("Error submitting form:", error);

          if (error.response) {
            console.error("Response data:", error.response.data);
            console.error("Response status:", error.response.status);
          }
        }
      }}
    >
      {(formikProps) => (
        <>
        <form onSubmit={formikProps.handleSubmit}>
          <Box m="20px">
            <Header
              title="Add User"
              subtitle="Create a New User Profile"
            />
            <h3>User Details</h3>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="First Name"
                onChange={formikProps.handleChange}
                value={formikProps.values.firstname}
                name="firstname"
                error={
                  formikProps.touched.firstname &&
                  !!formikProps.errors.firstname
                }
                helperText={
                  formikProps.touched.firstname && formikProps.errors.firstname
                    ? "First name is required"
                    : ""
                }
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Last Name"
                onChange={formikProps.handleChange}
                value={formikProps.values.lastname}
                name="lastname"
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Email"
                onChange={formikProps.handleChange}
                value={formikProps.values.email}
                name="email"
                error={formikProps.touched.email && !!formikProps.errors.email}
                helperText={
                  formikProps.touched.email && formikProps.errors.email
                    ? "Email is required"
                    : ""
                }
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Phone Number"
                onChange={formikProps.handleChange}
                value={formikProps.values.phoneno}
                name="phoneno"
                error={
                  formikProps.touched.phoneno && !!formikProps.errors.phoneno
                }
                helperText={
                  formikProps.touched.phoneno && formikProps.errors.phoneno
                    ? "Phone number is required"
                    : ""
                }
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Address"
                onChange={formikProps.handleChange}
                value={formikProps.values.address}
                name="address"
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="Age"
                onChange={formikProps.handleChange}
                value={formikProps.values.age}
                name="age"
                sx={{ gridColumn: "span 2" }}
              />
               <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Password"
                onChange={formikProps.handleChange}
                value={formikProps.values.password}
                name="password"
                error={
                  formikProps.touched.password &&
                  !!formikProps.errors.password
                }
                helperText={
                  formikProps.touched.password && formikProps.errors.password
                    ? "password required"
                    : ""
                }
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Conform Pasword"
                onChange={formikProps.handleChange}
                value={formikProps.values.conformpassword}
                name="conformpassword"
                 error={
                  formikProps.touched.conformpassword &&
                  !!formikProps.errors.conformpassword
                }
                helperText={
                  formikProps.touched.conformpassword && formikProps.errors.conformpassword
                    ? "Password and Conform Password should same"
                    : ""
                }
                sx={{ gridColumn: "span 2" }}
              />


              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  id="combo-box-demo"
                  label=<span> Date Of Birth </span>
                  value={dayjs(birthDate)}
                  slotProps={{ textField: { size: "small" } }}
                  style={{ marginTop: "2rem" }}
                  onChange={(e) => selectDate(e["$d"])}
                  sx={{ gridColumn: "span 2" }}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  id="combo-box-demo"
                  label=<span> Joining Date </span>
                  value={dayjs(joiningDate)}
                  slotProps={{ textField: { size: "small" } }}
                  style={{ marginTop: "2rem" }}
                  onChange={(e) => selectJoiningDate(e["$d"])}
                  sx={{ gridColumn: "span 2" }}
                />
              </LocalizationProvider>
              {/* {userrole === "Superadmin" && companycode === 1 && ( */}
              <FormControl
                fullWidth
                variant="filled"
                sx={{ gridColumn: "span 2" }}
              >
                <InputLabel id="role">Role</InputLabel>
                <Select
                  label="Role"
                  id="role"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.role}
                  name="role"
                  error={formikProps.touched.role && !!formikProps.errors.role}
                  helperText={
                    formikProps.touched.role && formikProps.errors.role
                      ? formikProps.errors.role
                      : ""
                  }
                >
                  <MenuItem value="">Select Role</MenuItem>
                  <MenuItem value="Superadmin">Super Admin</MenuItem>
                  <MenuItem value="Admin">Admin</MenuItem>
                  <MenuItem value="Manager">Manager</MenuItem>
                  <MenuItem value="Employee">Employee</MenuItem>
                </Select>
              </FormControl>
              {/* )} */}
              {userrole === "Superadmin" && companycode === 1 ? (
              <FormControl
                fullWidth
                variant="filled"
                sx={{ gridColumn: "span 2" }}
              >
                <InputLabel id="companyCode-label">Company Code</InputLabel>
                <Select
                  labelId="companyCode-label"
                  id="companyCodes"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.companyCodes}
                  name="companyCodes"
                  error={
                    formikProps.touched.companyCodes &&
                    !!formikProps.errors.companyCodes
                  }
                  helperText={
                    formikProps.touched.companyCodes &&
                    formikProps.errors.companyCodes
                      ? formikProps.errors.companyCodes
                      : ""
                  }
                  label="Company Code"
                  MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                >
                  <MenuItem value="">Select Company Code</MenuItem>
                  {companyCodes.map((code, index) => (
                    <MenuItem
                      key={index}
                      value={code}
                    >
                      {code}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              ) : (
                <TextField
                          fullWidth
                          variant="filled"
                          type="text"
                          label="Company Code"
                          value={companycode}
                          name="companyCodes"
                          sx={{ gridColumn: "span 2" }}
                        />
              )}
            </Box>
            <Box
              display="flex"
              justifyContent="end"
              mt="20px"
            >
              <Button
                type="submit"
                color="secondary"
                variant="contained"
              >
                SUBMIT
              </Button>
            </Box>
          </Box>
        </form>
        <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSuccess}
        autoHideDuration={3000}
        message={successMessage}
      />
<br/>
</>
      )}
      
      
    </Formik>
  
  );
};

export default Adduser;
