// import { useState, useContext } from "react";
// import {  Menu, MenuItem, SubMenu } from "react-pro-sidebar";

// import { ProSidebar } from 'react-pro-sidebar';

// import { Box, IconButton, Typography, useTheme } from "@mui/material";
// import { Link } from "react-router-dom";
// import "react-pro-sidebar/dist/css/styles.css";
// import { tokens } from "../../theme";
// import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
// import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
// import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
// import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
// import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
// import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
// import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
// import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
// import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
// import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
// import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
// import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
// // import { AuthContext } from "../../contexts/auth";
// import { useUser } from "../../contexts/auth";
// import logo from "../../assets/images/logo1.svg";
// import user from "../../assets/images/user.png";


// const Item = ({ title, to, icon, selected, setSelected }) => {
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);
//   return (
//     <MenuItem
//       active={selected === title}
//       style={{
//         color: colors.grey[100],
//       }}
//       onClick={() => setSelected(title)}
//       icon={icon}
//     >
//       <Typography>{title}</Typography>
//       <Link to={to} />
//     </MenuItem>
//   );
// };

// const Sidebar = () => {
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);
//   const [isCollapsed, setIsCollapsed] = useState(false);
//   const [selected, setSelected] = useState("Dashboard");
//   const {
//     userrole,
//     username,
//     userid,
//     companycode,
//     isAuthRole,
//     isAuthorized,
//     token,
//   } = useUser();
//   console.log( userrole,
//     username,
//     companycode,)
//   return (
//     <Box
//       sx={{
//         "& .pro-sidebar-inner": {
//           background: `${colors.primary[400]} !important`,
//         },
//         "& .pro-icon-wrapper": {
//           backgroundColor: "transparent !important",
//         },
//         "& .pro-inner-item": {
//           padding: "5px 35px 5px 20px !important",
//         },
//         "& .pro-inner-item:hover": {
//           color: "#868dfb !important",
//         },
//         "& .pro-menu-item.active": {
//           color: "#6870fa !important",
//         },
//       }}
//       style={{ height: "200vh" }}
//     >
//       <ProSidebar collapsed={isCollapsed}>
//         <Menu iconShape="square">
//           {/* LOGO AND MENU ICON */}
//           <MenuItem
//             onClick={() => setIsCollapsed(!isCollapsed)}
//             icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
//             style={{
//               margin: "10px 0 20px 0",
//               color: colors.grey[100],
//             }}
//           >
//             {!isCollapsed && (
//               <Box
//                 display="flex"
//                 justifyContent="space-between"
//                 alignItems="center"
//                 ml="20px"
//               >
                // <span>
                //   <img
                //     src={logo}
                //     alt="Logo"
                //     width="30"
                //     height="30"
                //   />
                // </span>
                // <Typography
                //   variant="h5"
                //   color={colors.grey[100]}
                // >
                //   ContactTab.com
                // </Typography>

//                 <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
//                   <MenuOutlinedIcon />
//                 </IconButton>
//               </Box>
//             )}
//           </MenuItem>

//           {!isCollapsed && (
//             <Box mb="25px">
//               <Box
//                 display="flex"
//                 justifyContent="center"
//                 alignItems="center"
//               >
//                 <img
//                   alt="profile-user"
//                   width="100px"
//                   height="100px"
//                   src={user}
//                   style={{ cursor: "pointer", borderRadius: "50%" }}
//                 />
//               </Box>
//               <Box textAlign="center">
//                 <Typography
//                   variant="h2"
//                   color={colors.grey[100]}
//                   fontWeight="bold"
//                   sx={{ m: "10px 0 0 0" }}
//                 >
//                  {username && username.charAt(0).toUpperCase() + username.slice(1)}
//                 </Typography>
//                 <Typography
//                   variant="h5"
//                   color={colors.greenAccent[500]}
//                 >
//                  {userrole}
//                 </Typography>
//               </Box>
//             </Box>
//           )}

//           <Box paddingLeft={isCollapsed ? undefined : "09%"}>
//             <Item
//               title="Dashboard"
//               to="/dashboard"
//               icon={<HomeOutlinedIcon />}
//               selected={selected}
//               setSelected={setSelected}
//             />

//             {/* <Typography
//               variant="h6"
//               color={colors.grey[300]}
//               sx={{ m: "15px 0 5px 20px" }}
//             >
//               Establishment
//             </Typography>
//             <Item
//               title="Add Establishment"
//               to="/add-establishment"
//               icon={<PeopleOutlinedIcon />}
//               selected={selected}
//               setSelected={setSelected}
//             />
//             <Item
//               title="All Establishment"
//               to="/all-establishment"
//               icon={<ContactsOutlinedIcon />}
//               selected={selected}
//               setSelected={setSelected}
//             /> */}

//             {userrole === "Superadmin" && companycode === 1 && (
//               <>
//                 <Typography
//                   variant="h6"
//                   color={colors.grey[300]}
//                   sx={{ m: "15px 0 5px 20px" }}
//                 >
//                   Establishment
//                 </Typography>
//                 <Item
//                   title="Add Establishment"
//                   to="/add-establishment"
//                   icon={<PeopleOutlinedIcon />}
//                   selected={selected}
//                   setSelected={setSelected}
//                 />
//                 <Item
//                   title="All Establishment"
//                   to="/all-establishment"
//                   icon={<ContactsOutlinedIcon />}
//                   selected={selected}
//                   setSelected={setSelected}
//                 />
//               </>
//                )}

//             <Typography
//               variant="h6"
//               color={colors.grey[300]}
//               sx={{ m: "15px 0 5px 20px" }}
//             >
//               Leads
//             </Typography>
//             <Item
//               title="Add Lead"
//               to="/add-lead"
//               icon={<PersonOutlinedIcon />}
//               selected={selected}
//               setSelected={setSelected}
//             />
//             <Item
//               title="All Lead"
//               to="/all-lead"
//               icon={<CalendarTodayOutlinedIcon />}
//               selected={selected}
//               setSelected={setSelected}
//             />
//             <Typography
//               variant="h6"
//               color={colors.grey[300]}
//               sx={{ m: "15px 0 5px 20px" }}
//             >
//               Users
//             </Typography>
//             <Item
//               title="Add User"
//               to="/add-user"
//               icon={<BarChartOutlinedIcon />}
//               selected={selected}
//               setSelected={setSelected}
//             />
//             <Item
//               title="All User"
//               to="/all-user"
//               icon={<PieChartOutlineOutlinedIcon />}
//               selected={selected}
//               setSelected={setSelected}
//             />
//             {/* <Item
//               title="Line Chart"
//               to="/line"
//               icon={<TimelineOutlinedIcon />}
//               selected={selected}
//               setSelected={setSelected}
//             />
//             <Item
//               title="Geography Chart"
//               to="/geography"
//               icon={<MapOutlinedIcon />}
//               selected={selected}
//               setSelected={setSelected}
//             /> */}
//             <Typography
//               variant="h6"
//               color={colors.grey[300]}
//               sx={{ m: "15px 0 5px 20px" }}
//             >
//               Clients
//             </Typography>
//             <Item
//               title="Add Client"
//               to="/add-client"
//               icon={<BarChartOutlinedIcon />}
//               selected={selected}
//               setSelected={setSelected}
//             />
//             <Item
//               title="All Client"
//               to="/all-client"
//               icon={<PieChartOutlineOutlinedIcon />}
//               selected={selected}
//               setSelected={setSelected}
//             />
//             <Typography
//               variant="h6"
//               color={colors.grey[300]}
//               sx={{ m: "15px 0 5px 20px" }}
//             >
//               Pitched
//             </Typography>
//             <Item
//               title="All Pitched"
//               to="/all-pitched"
//               icon={<PieChartOutlineOutlinedIcon />}
//               selected={selected}
//               setSelected={setSelected}
//             />
//             <Typography
//               variant="h6"
//               color={colors.grey[300]}
//               sx={{ m: "15px 0 5px 20px" }}
//             >
//               Demo
//             </Typography>
//             <Item
//               title="All Demo"
//               to="/all-demo"
//               icon={<PieChartOutlineOutlinedIcon />}
//               selected={selected}
//               setSelected={setSelected}
//             />
//             <Typography
//               variant="h6"
//               color={colors.grey[300]}
//               sx={{ m: "15px 0 5px 20px" }}
//             >
//               Negotiations
//             </Typography>
//             <Item
//               title="All Negotiations"
//               to="/all-negotiation"
//               icon={<PieChartOutlineOutlinedIcon />}
//               selected={selected}
//               setSelected={setSelected}
//             />
//             <Typography
//               variant="h6"
//               color={colors.grey[300]}
//               sx={{ m: "15px 0 5px 20px" }}
//             >
//               Closed Lost
//             </Typography>
//             <Item
//               title="All Closed Lost"
//               to="/all-closed-lost"
//               icon={<PieChartOutlineOutlinedIcon />}
//               selected={selected}
//               setSelected={setSelected}
//             />
//             <Typography
//               variant="h6"
//               color={colors.grey[300]}
//               sx={{ m: "15px 0 5px 20px" }}
//             >
//               Closed Won
//             </Typography>
//             <Item
//               title="All Closed Won"
//               to="/all-closed-won"
//               icon={<PieChartOutlineOutlinedIcon />}
//               selected={selected}
//               setSelected={setSelected}
//             />
//             <Typography
//               variant="h6"
//               color={colors.grey[300]}
//               sx={{ m: "15px 0 5px 20px" }}
//             >
//               RNR
//             </Typography>
//             <Item
//               title="All RNR"
//               to="/all-RNR"
//               icon={<PieChartOutlineOutlinedIcon />}
//               selected={selected}
//               setSelected={setSelected}
//             />
//             <Typography
//               variant="h6"
//               color={colors.grey[300]}
//               sx={{ m: "15px 0 5px 20px" }}
//             >
//               Junk
//             </Typography>
//             <Item
//               title="All Junk"
//               to="/all-junk"
//               icon={<PieChartOutlineOutlinedIcon />}
//               selected={selected}
//               setSelected={setSelected}
//             />
//           </Box>
//         </Menu>
//       </ProSidebar>
//     </Box>
//   );
// };

// export default Sidebar;
// import * as React from 'react';
// import { styled, useTheme } from '@mui/material/styles';
// import Box from '@mui/material/Box';
// import Drawer from '@mui/material/Drawer';
// import CssBaseline from '@mui/material/CssBaseline';
// import MuiAppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import List from '@mui/material/List';
// import Typography from '@mui/material/Typography';
// import Divider from '@mui/material/Divider';
// import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import ListItem from '@mui/material/ListItem';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
// import MailIcon from '@mui/icons-material/Mail';
// import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
// import {
//   Badge,
//   Modal,
//   Button,
// } from "@mui/material";
// import { Menu, MenuItem } from "@mui/material";
// import { useContext, useState, useEffect } from "react";
// import { ColorModeContext, tokens } from "../../theme";
// import InputBase from "@mui/material/InputBase";
// import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
// import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
// import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
// import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
// import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
// import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
// import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
// import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
// // import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
// import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
// import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
// import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
// import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
// import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
// import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
// import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
// import SearchIcon from '@mui/icons-material/Search';
// import CloseIcon from '@mui/icons-material/Close';
// import axios from "../../utils/axios";
// import { Link, useNavigate } from "react-router-dom"; // Import Link and useNavigate
// import { useUser } from "../../contexts/auth";

// const drawerWidth = 240;

// const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
//   ({ theme, open }) => ({
//     flexGrow: 1,
//     padding: theme.spacing(3),
//     transition: theme.transitions.create('margin', {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//     marginLeft: `-${drawerWidth}px`,
//     ...(open && {
//       transition: theme.transitions.create('margin', {
//         easing: theme.transitions.easing.easeOut,
//         duration: theme.transitions.duration.enteringScreen,
//       }),
//       marginLeft: 0,
//     }),
//   }),
// );

// const AppBar = styled(MuiAppBar, {
//   shouldForwardProp: (prop) => prop !== 'open',
// })(({ theme, open }) => ({
//   transition: theme.transitions.create(['margin', 'width'], {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   ...(open && {
//     width: `calc(100% - ${drawerWidth}px)`,
//     marginLeft: `${drawerWidth}px`,
//     transition: theme.transitions.create(['margin', 'width'], {
//       easing: theme.transitions.easing.easeOut,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   }),
// }));

// const DrawerHeader = styled('div')(({ theme }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   padding: theme.spacing(0, 1),
//   // necessary for content to be below app bar
//   ...theme.mixins.toolbar,
//   justifyContent: 'flex-end',
// }));

// const Item = ({ title, to, icon, selected, setSelected }) => {
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);
//   return (
//     <ListItemButton
//       selected={selected === title}
//       style={{
//         color: colors.grey[100],
//       }}
//       onClick={() => setSelected(title)}
//       component={Link}
//       to={to}
//     >
//       <ListItemIcon>
//         {icon}
//       </ListItemIcon>
//       <ListItemText primary={title} />
//     </ListItemButton>
//   );
// };

// export default function Sidebar() {
//   const theme = useTheme();
//   const [selected, setSelected] = useState("Dashboard");
//   const [open, setOpen] = React.useState(true);
//   const { userrole, username, userid } = useUser();
//   const colors = tokens(theme.palette.mode);
//   const colorMode = useContext(ColorModeContext);
//   const [modalOpen, setModalOpen] = useState(false);
//   const [notifications, setNotifications] = useState([]);
//   const [newNotifications, setNewNotifications] = useState(false);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const navigate = useNavigate(); // Use useNavigate
//   const handleAction = () => {
//     // No action needed, simply close the modal
//     closeModal();
//   };

//   const handleMenuOpen = (event) => {
//     setAnchorEl(event.currentTarget);
//   };
//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };

//   const fetchNotifications = async () => {
//     try {
//       const response = await axios.get("/notifications");

//       if (!response.data) {
//         throw new Error("No data received");
//       }

//       const data = response.data;
//       console.log("Fetched data:", data);
//       setNotifications(data.notifications);
//       setNewNotifications(
//         data.notifications.some((notification) =>
//           isNewNotification(notification)
//         )
//       );
//     } catch (error) {
//       console.error("Error fetching notifications:", error);

//       // Log the entire response to inspect its content
//       if (error.response && error.response.data) {
//         // console.log("Raw Response:", error.response.data);
//       }
//     }
//   };

//   const isNewNotification = (notification) => {
//     const notificationDate = new Date(notification.expiredate);
//     const currentDate = new Date();
//     const oneDayInMilliseconds = 24 * 60 * 60 * 1000;

//     return (
//       notificationDate.getTime() - currentDate.getTime() < oneDayInMilliseconds
//     );
//   };

//   useEffect(() => {
//     fetchNotifications();
//   }, []); // Add notifications to the dependency array to log when it changes

//   const openModal = () => {
//     setModalOpen(true);
//   };

//   const closeModal = () => {
//     setModalOpen(false);
//   };

//   const handleLogout = async () => {
//     // Prompt the user to confirm logout
//     const confirmed = window.confirm("Do you want to log out?");
//     if (confirmed) {
//       try {
//         // Call the logout API endpoint
//         const response = await axios.post("/logout");

//         // Check if the logout was successful
//         if (response.status === 200) {
//           // Remove JWT token from localStorage
//           localStorage.removeItem("jwtToken");
//           // Redirect the user to the homepage after logout
//           navigate("/login");
//         }
//       } catch (error) {
//         // Handle errors, such as token expiration or invalid token
//         if (error.response.status === 403) {
//           alert("Token Expired. Please login again.");
//           localStorage.removeItem("jwtToken");
//           navigate("/login");
//         } else if (error.response.status === 401) {
//           alert("Invalid token. Please login again.");
//           localStorage.removeItem("jwtToken");
//           navigate("/login");
//         } else {
//           alert("Error: " + error.response.data.error);
//         }
//       }
//     }
//   };
//   const handleDrawerOpen = () => {
//     setOpen(true);
//   };

//   const handleDrawerClose = () => {
//     setOpen(false);
//   };

//   return (
//     <Box sx={{ display: 'flex' }}>
//       <CssBaseline />
//       <AppBar position="fixed" open={open}>
//         <Toolbar>
//           <IconButton
//             color="inherit"
//             aria-label="open drawer"
//             onClick={handleDrawerOpen}
//             edge="start"
//             sx={{ mr: 2, ...(open && { display: 'none' }) }}
//           >
//             <MenuIcon />
//           </IconButton>
//           <Typography variant="h6" noWrap component="div">
//             <Box display="flex" justifyContent="space-between" p={2}>
//               {/* SEARCH BAR */}
//               <Box
//                 display="flex"
//                 backgroundColor={colors.primary[400]}
//                 borderRadius="3px"
//               >
//                 <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
//                 <IconButton type="button" sx={{ p: 1 }}>
//                   <SearchIcon />
//                 </IconButton>
//               </Box>

//               {/* ICONS */}
//               <Box display="flex">
//                 <IconButton onClick={colorMode.toggleColorMode}>
//                   {theme.palette.mode === "dark" ? (
//                     <DarkModeOutlinedIcon />
//                   ) : (
//                     <LightModeOutlinedIcon />
//                   )}
//                 </IconButton>
//                 <IconButton onClick={openModal}>
//                   <Badge
//                     badgeContent={notifications.length}
//                     color="error"
//                     variant="dot"
//                   >
//                     <NotificationsOutlinedIcon />
//                   </Badge>
//                 </IconButton>
//                 <IconButton>
//                   <SettingsOutlinedIcon />
//                 </IconButton>
//                 <IconButton onClick={handleMenuOpen}>
//                   <PersonOutlinedIcon />
//                 </IconButton>

//                 <Menu
//                   anchorEl={anchorEl}
//                   open={Boolean(anchorEl)}
//                   onClose={handleMenuClose}
//                 >
//                   <MenuItem onClick={handleLogout}>Logout</MenuItem>
//                 </Menu>
//               </Box>
//               <Modal open={modalOpen} onClose={closeModal}>
//                 <Box
//                   sx={{
//                     position: "absolute",
//                     top: "50%",
//                     left: "50%",
//                     transform: "translate(-50%, -50%)",
//                     bgcolor: "background.paper",
//                     boxShadow: 24,
//                     p: 4,
//                     width: 500,
//                     borderRadius: 3,
//                   }}
//                 >
//                   <IconButton
//                     edge="end"
//                     color="inherit"
//                     onClick={closeModal}
//                     sx={{
//                       position: "absolute",
//                       top: 0,
//                       right: 5,
//                     }}
//                   >
//                     <CloseIcon />
//                   </IconButton>
//                   <Typography variant="h5" mb={2}>
//                     Reminders
//                   </Typography>
//                   <Divider sx={{ mb: 2 }} />{" "}
//                   {/* Add a divider for better visual separation */}
//                   <List>
//                     {notifications.map((notification, index) => (
//                       <ListItem key={index}>
//                         <ListItemText
//                           primary={`${notification.ownername}, Your ${
//                             notification.sourcetable
//                           } expiry date is on: ${new Date(
//                             notification.expiredate
//                           ).toDateString()}`}
//                         />
//                       </ListItem>
//                     ))}
//                   </List>
//                   {/* Add a button for further action */}
//                   <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
//                     <Button variant="contained" color="primary" onClick={handleAction}>
//                       Take Action
//                     </Button>
//                   </Box>
//                 </Box>
//               </Modal>
//             </Box>
//           </Typography>
//         </Toolbar>
//       </AppBar>
//       <Drawer
//         sx={{
//           width: drawerWidth,
//           flexShrink: 0,
//           '& .MuiDrawer-paper': {
//             width: drawerWidth,
//             boxSizing: 'border-box',
//           },
//         }}
//         variant="persistent"
//         anchor="left"
//         open={open}
//       >
//         <DrawerHeader>
//           <IconButton onClick={handleDrawerClose}>
//             {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
//           </IconButton>
//         </DrawerHeader>
//         <Divider />
//         <List>
//           <Item
//             title="Dashboard"
//             to="/dashboard"
//             icon={<HomeOutlinedIcon />}
//             selected={selected}
//             setSelected={setSelected}
//           />
//           <Typography
//               variant="h6"
//               color={colors.grey[300]}
//               sx={{ m: "15px 0 5px 20px" }}
//             >
//               Establishment
//             </Typography>
//             <Item
//               title="Add Establishment"
//               to="/add-establishment"
//               icon={<PeopleOutlinedIcon />}
//               selected={selected}
//               setSelected={setSelected}
//             />
//             <Item
//               title="All Establishment"
//               to="/all-establishment"
//               icon={<ContactsOutlinedIcon />}
//               selected={selected}
//               setSelected={setSelected}
//             />

//             {/* {userrole === "Superadmin" && userid === "00001" && (
//               <>
//                 <Typography
//                   variant="h6"
//                   color={colors.grey[300]}
//                   sx={{ m: "15px 0 5px 20px" }}
//                 >
//                   Establishment
//                 </Typography>
//                 <Item
//                   title="Add Establishment"
//                   to="/add-establishment"
//                   icon={<PeopleOutlinedIcon />}
//                   selected={selected}
//                   setSelected={setSelected}
//                 />
//                 <Item
//                   title="All Establishment"
//                   to="/all-establishment"
//                   icon={<ContactsOutlinedIcon />}
//                   selected={selected}
//                   setSelected={setSelected}
//                 />
//               </>
//                )} */}

//             <Typography
//               variant="h6"
//               color={colors.grey[300]}
//               sx={{ m: "15px 0 5px 20px" }}
//             >
//               Leads
//             </Typography>
//             <Item
//               title="Add Lead"
//               to="/add-lead"
//               icon={<PersonOutlinedIcon />}
//               selected={selected}
//               setSelected={setSelected}
//             />
//             <Item
//               title="All Lead"
//               to="/all-lead"
//               icon={<CalendarTodayOutlinedIcon />}
//               selected={selected}
//               setSelected={setSelected}
//             />
//             <Typography
//               variant="h6"
//               color={colors.grey[300]}
//               sx={{ m: "15px 0 5px 20px" }}
//             >
//               Users
//             </Typography>
//             <Item
//               title="Add User"
//               to="/add-user"
//               icon={<BarChartOutlinedIcon />}
//               selected={selected}
//               setSelected={setSelected}
//             />
//             <Item
//               title="All User"
//               to="/all-user"
//               icon={<PieChartOutlineOutlinedIcon />}
//               selected={selected}
//               setSelected={setSelected}
//             />
//             {/* <Item
//               title="Line Chart"
//               to="/line"
//               icon={<TimelineOutlinedIcon />}
//               selected={selected}
//               setSelected={setSelected}
//             />
//             <Item
//               title="Geography Chart"
//               to="/geography"
//               icon={<MapOutlinedIcon />}
//               selected={selected}
//               setSelected={setSelected}
//             /> */}
//             <Typography
//               variant="h6"
//               color={colors.grey[300]}
//               sx={{ m: "15px 0 5px 20px" }}
//             >
//               Clients
//             </Typography>
//             <Item
//               title="Add Client"
//               to="/add-client"
//               icon={<BarChartOutlinedIcon />}
//               selected={selected}
//               setSelected={setSelected}
//             />
//             <Item
//               title="All Client"
//               to="/all-client"
//               icon={<PieChartOutlineOutlinedIcon />}
//               selected={selected}
//               setSelected={setSelected}
//             />
//             <Typography
//               variant="h6"
//               color={colors.grey[300]}
//               sx={{ m: "15px 0 5px 20px" }}
//             >
//               Pitched
//             </Typography>
//             <Item
//               title="All Pitched"
//               to="/all-pitched"
//               icon={<PieChartOutlineOutlinedIcon />}
//               selected={selected}
//               setSelected={setSelected}
//             />
//             <Typography
//               variant="h6"
//               color={colors.grey[300]}
//               sx={{ m: "15px 0 5px 20px" }}
//             >
//               Demo
//             </Typography>
//             <Item
//               title="All Demo"
//               to="/all-demo"
//               icon={<PieChartOutlineOutlinedIcon />}
//               selected={selected}
//               setSelected={setSelected}
//             />
//             <Typography
//               variant="h6"
//               color={colors.grey[300]}
//               sx={{ m: "15px 0 5px 20px" }}
//             >
//               Negotiations
//             </Typography>
//             <Item
//               title="All Negotiations"
//               to="/all-negotiation"
//               icon={<PieChartOutlineOutlinedIcon />}
//               selected={selected}
//               setSelected={setSelected}
//             />
//             <Typography
//               variant="h6"
//               color={colors.grey[300]}
//               sx={{ m: "15px 0 5px 20px" }}
//             >
//               Closed Lost
//             </Typography>
//             <Item
//               title="All Closed Lost"
//               to="/all-closed-lost"
//               icon={<PieChartOutlineOutlinedIcon />}
//               selected={selected}
//               setSelected={setSelected}
//             />
//             <Typography
//               variant="h6"
//               color={colors.grey[300]}
//               sx={{ m: "15px 0 5px 20px" }}
//             >
//               Closed Won
//             </Typography>
//             <Item
//               title="All Closed Won"
//               to="/all-closed-won"
//               icon={<PieChartOutlineOutlinedIcon />}
//               selected={selected}
//               setSelected={setSelected}
//             />
//             <Typography
//               variant="h6"
//               color={colors.grey[300]}
//               sx={{ m: "15px 0 5px 20px" }}
//             >
//               RNR
//             </Typography>
//             <Item
//               title="All RNR"
//               to="/all-RNR"
//               icon={<PieChartOutlineOutlinedIcon />}
//               selected={selected}
//               setSelected={setSelected}
//             />
//             <Typography
//               variant="h6"
//               color={colors.grey[300]}
//               sx={{ m: "15px 0 5px 20px" }}
//             >
//               Junk
//             </Typography>
//             <Item
//               title="All Junk"
//               to="/all-junk"
//               icon={<PieChartOutlineOutlinedIcon />}
//               selected={selected}
//               setSelected={setSelected}
//             />
//         </List>
//       </Drawer>
//       <Main open={open}>
//         <DrawerHeader />
//         <Typography paragraph>
//           {/* Your main content */}
//         </Typography>
//       </Main>
//     </Box>
//   );
// }




// import * as React from 'react';
// import { styled, useTheme } from '@mui/material/styles';
// import Box from '@mui/material/Box';
// import Drawer from '@mui/material/Drawer';
// import CssBaseline from '@mui/material/CssBaseline';
// import MuiAppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import Typography from '@mui/material/Typography';
// import Divider from '@mui/material/Divider';
// import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import List from '@mui/material/List';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
// import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
// import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
// import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
// import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
// import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
// import PieChartOutlineOutlinedIcon from '@mui/icons-material/PieChartOutlineOutlined';
// import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
// import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
// import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
// import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
// import SearchIcon from '@mui/icons-material/Search';
// import CloseIcon from '@mui/icons-material/Close';
// import ListItem from '@mui/material/ListItem';
// import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
// import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
// import Badge from '@mui/material/Badge';
// import Modal from '@mui/material/Modal';
// import Button from '@mui/material/Button';
// import InputBase from '@mui/material/InputBase';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
// import { Link, useNavigate } from 'react-router-dom';
// import { useContext, useState, useEffect } from 'react';
// import { ColorModeContext, tokens } from '../../theme';
// import axios from '../../utils/axios';
// import { useUser } from '../../contexts/auth';
// import logo from "../../assets/images/logo1.svg";

// const drawerWidth = 240;

// const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
//   ({ theme, open }) => ({
//     flexGrow: 1,
//     padding: theme.spacing(3),
//     transition: theme.transitions.create('margin', {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//     marginLeft: `-${drawerWidth}px`,
//     ...(open && {
//       transition: theme.transitions.create('margin', {
//         easing: theme.transitions.easing.easeOut,
//         duration: theme.transitions.duration.enteringScreen,
//       }),
//       marginLeft: 0,
//     }),
//   })
// );

// const AppBar = styled(MuiAppBar, {
//   shouldForwardProp: (prop) => prop !== 'open',
// })(({ theme, open }) => ({
//   transition: theme.transitions.create(['margin', 'width'], {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   ...(open && {
//     width: `calc(100% - ${drawerWidth}px)`,
//     marginLeft: `${drawerWidth}px`,
//     transition: theme.transitions.create(['margin', 'width'], {
//       easing: theme.transitions.easing.easeOut,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   }),
// }));

// const DrawerHeader = styled('div')(({ theme }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   padding: theme.spacing(0, 1),
//   ...theme.mixins.toolbar,
//   justifyContent: 'flex-end',
// }));

// // const Item = ({ title, to, icon, selected, setSelected }) => {
// //   const theme = useTheme();
// //   const colors = tokens(theme.palette.mode);
// //   return (
// //     <ListItemButton
// //       selected={selected === title}
// //       style={{
// //         color: colors.grey[100],
// //       }}
// //       onClick={() => setSelected(title)}
// //       component={Link}
// //       to={to}
// //     >
// //       <ListItemIcon>{icon}</ListItemIcon>
// //       <ListItemText primary={title} />
// //     </ListItemButton>
// //   );
// // };

// const Item = ({ title, to, icon, selected, setSelected, subtitle }) => {
//     const theme = useTheme();
//     const colors = tokens(theme.palette.mode);
//     return (
//       <ListItemButton
//         selected={selected === title}
//         style={{
//           color: colors.grey[100],
//         }}
//         onClick={() => setSelected(title)}
//         component={Link}
//         to={to}
//       >
//         <ListItemIcon>{icon}</ListItemIcon>
//         <ListItemText primary={title} secondary={subtitle} />
//       </ListItemButton>
//     );
//   };

// export default function Sidebar() {
//   const theme = useTheme();
//   const [selected, setSelected] = useState('Dashboard');
//   const [open, setOpen] = useState(true);
//   const [expanded, setExpanded] = useState(false);
//   const { userrole, username, userid } = useUser();
//   const colors = tokens(theme.palette.mode);
//   const colorMode = useContext(ColorModeContext);
//   const [modalOpen, setModalOpen] = useState(false);
//   const [notifications, setNotifications] = useState([]);
//   const [newNotifications, setNewNotifications] = useState(false);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const navigate = useNavigate();

//   const handleAction = () => {
//     closeModal();
//   };

//   const handleMenuOpen = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };

//   const fetchNotifications = async () => {
//     try {
//       const response = await axios.get('/notifications');
//       if (!response.data) {
//         throw new Error('No data received');
//       }
//       const data = response.data;
//       setNotifications(data.notifications);
//       setNewNotifications(
//         data.notifications.some((notification) => isNewNotification(notification))
//       );
//     } catch (error) {
//       console.error('Error fetching notifications:', error);
//       if (error.response && error.response.data) {
//         // console.log("Raw Response:", error.response.data);
//       }
//     }
//   };

//   const isNewNotification = (notification) => {
//     const notificationDate = new Date(notification.expiredate);
//     const currentDate = new Date();
//     const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
//     return notificationDate.getTime() - currentDate.getTime() < oneDayInMilliseconds;
//   };

//   useEffect(() => {
//     fetchNotifications();
//   }, []);

//   const openModal = () => {
//     setModalOpen(true);
//   };

//   const closeModal = () => {
//     setModalOpen(false);
//   };

//   const handleLogout = async () => {
//     const confirmed = window.confirm('Do you want to log out?');
//     if (confirmed) {
//       try {
//         const response = await axios.post('/logout');
//         if (response.status === 200) {
//           localStorage.removeItem('jwtToken');
//           navigate('/login');
//         }
//       } catch (error) {
//         if (error.response.status === 403) {
//           alert('Token Expired. Please login again.');
//           localStorage.removeItem('jwtToken');
//           navigate('/login');
//         } else if (error.response.status === 401) {
//           alert('Invalid token. Please login again.');
//           localStorage.removeItem('jwtToken');
//           navigate('/login');
//         } else {
//           alert('Error: ' + error.response.data.error);
//         }
//       }
//     }
//   };

//   const handleDrawerOpen = () => {
//     setOpen(true);
//   };

//   const handleDrawerClose = () => {
//     setOpen(false);
//   };

//   const handleChange = (panel) => (event, isExpanded) => {
//     setExpanded(isExpanded ? panel : false);
//   };


//   return (
//     <Box sx={{ display: 'flex' }}>
//       <CssBaseline />
//       <AppBar position="fixed" open={open}>
//         <Toolbar>
//           <IconButton
//             color="inherit"
//             aria-label="open drawer"
//             onClick={handleDrawerOpen}
//             edge="start"
//             sx={{ mr: 2, ...(open && { display: 'none' }) }}
//           >
//             <MenuIcon />
//           </IconButton>
//           {/* Logo and Title */}
//           {!open && (
//             <>
//               <span>
//                 <img
//                   src={logo}
//                   alt="Logo"
//                   width="30"
//                   height="30"
//                 />
//               </span>
//               <Typography variant="h5" color={colors.grey[100]} sx={{ ml: 1 }}>
//                 ContactTab.com
//               </Typography>
//             </>
//           )}
        //   <Typography variant="h6" noWrap component="div">
        //     <Box display="flex" justifyContent="space-between" p={2}>
        //       {/* SEARCH BAR */}
        //       <Box display="flex" backgroundColor={colors.primary[400]} borderRadius="3px">
        //         <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        //         <IconButton type="button" sx={{ p: 1 }}>
        //           <SearchIcon />
        //         </IconButton>
        //       </Box>
        //       {/* ICONS */}
        //       <Box display="flex">
        //         <IconButton onClick={colorMode.toggleColorMode}>
        //           {theme.palette.mode === 'dark' ? (
        //             <DarkModeOutlinedIcon />
        //           ) : (
        //             <LightModeOutlinedIcon />
        //           )}
        //         </IconButton>
        //         <IconButton onClick={openModal}>
        //           <Badge badgeContent={notifications.length} color="error" variant="dot">
        //             <NotificationsOutlinedIcon />
        //           </Badge>
        //         </IconButton>
        //         <IconButton>
        //           <SettingsOutlinedIcon />
        //         </IconButton>
        //         <IconButton onClick={handleMenuOpen}>
        //           <PersonOutlinedIcon />
        //         </IconButton>
        //         <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        //           <MenuItem onClick={handleLogout}>Logout</MenuItem>
        //         </Menu>
        //       </Box>
        //       <Modal open={modalOpen} onClose={closeModal}>
        //         <Box
        //           sx={{
        //             position: 'absolute',
        //             top: '50%',
        //             left: '50%',
        //             transform: 'translate(-50%, -50%)',
        //             bgcolor: 'background.paper',
        //             boxShadow: 24,
        //             p: 4,
        //             width: 500,
        //             borderRadius: 3,
        //           }}
        //         >
        //           <IconButton
        //             edge="end"
        //             color="inherit"
        //             onClick={closeModal}
        //             sx={{
        //               position: 'absolute',
        //               top: 0,
        //               right: 5,
        //             }}
        //           >
        //             <CloseIcon />
        //           </IconButton>
        //           <Typography variant="h5" mb={2}>
        //             Reminders
        //           </Typography>
        //           <Divider sx={{ mb: 2 }} />
        //           <List>
        //             {notifications.map((notification, index) => (
        //               <ListItem key={index}>
        //                 <ListItemText
        //                   primary={`${notification.ownername}, Your ${
        //                     notification.sourcetable
        //                   } expiry date is on: ${new Date(notification.expiredate).toDateString()}`}
        //                 />
        //               </ListItem>
        //             ))}
        //           </List>
        //           <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        //             <Button variant="contained" color="primary" onClick={handleAction}>
        //               Take Action
        //             </Button>
        //           </Box>
        //         </Box>
        //       </Modal>
        //     </Box>
        //   </Typography>
//         </Toolbar>
//       </AppBar>
//       <Drawer
//         sx={{
        
//           width: drawerWidth,
//           flexShrink: 0,
//           '& .MuiDrawer-paper': {
//             width: drawerWidth,
//             boxSizing: 'border-box',
//           },
//         }}
//         variant="persistent"
//         anchor="left"
//         open={open}
//       >
      
//         <DrawerHeader>
        //  {/* Logo and Title */}
        //  {open && (
        //     <>
        //       <span>
        //         <img
        //           src={logo}
        //           alt="Logo"
        //           width="30"
        //           height="30"
        //         />
        //       </span>
        //       <Typography variant="h5" color={colors.grey[100]} sx={{ ml: 1 }}>
        //         ContactTab.com
        //       </Typography>
        //     </>
        //   )}
//           <IconButton onClick={handleDrawerClose}>
//             {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
//           </IconButton>
//         </DrawerHeader>
        
//         <Divider />
//         <List>
//           <Item
//             title="Dashboard"
//             to="/dashboard"
//             icon={<HomeOutlinedIcon />}
//             selected={selected}
//             setSelected={setSelected}
//           />
//           <Accordion expanded={expanded === 'establishment'} onChange={handleChange('establishment')}>
//             <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="establishment-content" id="establishment-header">
//               <Typography>Establishment</Typography>
//             </AccordionSummary>
//             <AccordionDetails>
//               <Item
//                 title="Add Establishment"
//                 to="/add-establishment"
//                 icon={<PeopleOutlinedIcon />}
//                 selected={selected}
//                 setSelected={setSelected}
//               />
//               <Item
//                 title="All Establishment"
//                 to="/all-establishment"
//                 icon={<ContactsOutlinedIcon />}
//                 selected={selected}
//                 setSelected={setSelected}
//               />
//             </AccordionDetails>
//           </Accordion>
//           <Accordion expanded={expanded === 'leads'} onChange={handleChange('leads')}>
//             <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="leads-content" id="leads-header">
//               <Typography>Leads</Typography>
//             </AccordionSummary>
//             <AccordionDetails>
//               <Item
//                 title="Add Lead"
//                 to="/add-lead"
//                 icon={<PersonOutlinedIcon />}
//                 selected={selected}
//                 setSelected={setSelected}
//               />
//               <Item
//                 title="All Lead"
//                 to="/all-lead"
//                 icon={<CalendarTodayOutlinedIcon />}
//                 selected={selected}
//                 setSelected={setSelected}
//               />
//             </AccordionDetails>
//           </Accordion>
//           <Accordion expanded={expanded === 'users'} onChange={handleChange('users')}>
//             <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="users-content" id="users-header">
//               <Typography>Users</Typography>
//             </AccordionSummary>
//             <AccordionDetails>
//               <Item
//                 title="Add User"
//                 to="/add-user"
//                 icon={<PersonOutlinedIcon />}
//                 selected={selected}
//                 setSelected={setSelected}
//               />
//               <Item
//                 title="All User"
//                 to="/all-user"
//                 icon={<PeopleOutlinedIcon />}
//                 selected={selected}
//                 setSelected={setSelected}
//               />
//             </AccordionDetails>
//           </Accordion>
//           <Accordion expanded={expanded === 'clients'} onChange={handleChange('clients')}>
//             <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="clients-content" id="clients-header">
//               <Typography>Clients</Typography>
//             </AccordionSummary>
//             <AccordionDetails>
//               <Item
//                 title="Add Client"
//                 to="/add-client"
//                 icon={<PersonOutlinedIcon />}
//                 selected={selected}
//                 setSelected={setSelected}
//               />
//               <Item
//                 title="All Client"
//                 to="/all-client"
//                 icon={<ContactsOutlinedIcon />}
//                 selected={selected}
//                 setSelected={setSelected}
//               />
//             </AccordionDetails>
//           </Accordion>
//           <Accordion expanded={expanded === 'pitched'} onChange={handleChange('pitched')}>
//             <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="pitched-content" id="pitched-header">
//               <Typography>Pitched</Typography>
//             </AccordionSummary>
//             <AccordionDetails>
//               <Item
//                 title="All Pitched"
//                 to="/all-pitched"
//                 icon={<BarChartOutlinedIcon />}
//                 selected={selected}
//                 setSelected={setSelected}
//               />
//             </AccordionDetails>
//           </Accordion>
//           <Accordion expanded={expanded === 'demo'} onChange={handleChange('demo')}>
//             <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="demo-content" id="demo-header">
//               <Typography>Demo</Typography>
//             </AccordionSummary>
//             <AccordionDetails>
//               <Item
//                 title="All Demo"
//                 to="/all-demo"
//                 icon={<PieChartOutlineOutlinedIcon />}
//                 selected={selected}
//                 setSelected={setSelected}
//               />
//             </AccordionDetails>
//           </Accordion>
//           <Accordion expanded={expanded === 'negotiations'} onChange={handleChange('negotiations')}>
//             <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="negotiations-content" id="negotiations-header">
//               <Typography>Negotiations</Typography>
//             </AccordionSummary>
//             <AccordionDetails>
//               <Item
//                 title="All Negotiations"
//                 to="/all-negotiation"
//                 icon={<TimelineOutlinedIcon />}
//                 selected={selected}
//                 setSelected={setSelected}
//               />
//             </AccordionDetails>
//           </Accordion>
//           <Accordion expanded={expanded === 'closedLost'} onChange={handleChange('closedLost')}>
//             <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="closed-lost-content" id="closed-lost-header">
//               <Typography>Closed Lost</Typography>
//             </AccordionSummary>
//             <AccordionDetails>
//               <Item
//                 title="All Closed Lost"
//                 to="/all-closed-lost"
//                 icon={<MapOutlinedIcon />}
//                 selected={selected}
//                 setSelected={setSelected}
//               />
//             </AccordionDetails>
//           </Accordion>
//           <Accordion expanded={expanded === 'closedWon'} onChange={handleChange('closedWon')}>
//             <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="closed-won-content" id="closed-won-header">
//               <Typography>Closed Won</Typography>
//             </AccordionSummary>
//             <AccordionDetails>
//               <Item
//                 title="All Closed Won"
//                 to="/all-closed-won"
//                 icon={<PieChartOutlineOutlinedIcon />}
//                 selected={selected}
//                 setSelected={setSelected}
//               />
//             </AccordionDetails>
//           </Accordion>
//           <Accordion expanded={expanded === 'rnr'} onChange={handleChange('rnr')}>
//             <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="rnr-content" id="rnr-header">
//               <Typography>RNR</Typography>
//             </AccordionSummary>
//             <AccordionDetails>
//               <Item
//                 title="All RNR"
//                 to="/all-RNR"
//                 icon={<BarChartOutlinedIcon />}
//                 selected={selected}
//                 setSelected={setSelected}
//               />
//             </AccordionDetails>
//           </Accordion>
//           <Accordion expanded={expanded === 'junk'} onChange={handleChange('junk')}>
//             <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="junk-content" id="junk-header">
//               <Typography>Junk</Typography>
//             </AccordionSummary>
//             <AccordionDetails>
//               <Item
//                 title="All Junk"
//                 to="/all-junk"
//                 icon={<PieChartOutlineOutlinedIcon />}
//                 selected={selected}
//                 setSelected={setSelected}
//               />
//             </AccordionDetails>
//           </Accordion>
//         </List>
//       </Drawer>
//       <Main open={open}>
//         <DrawerHeader />
//         <Typography paragraph>{/* Your main content */}</Typography>
//       </Main>
//     </Box>
//   );
// }



import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import PieChartOutlineOutlinedIcon from '@mui/icons-material/PieChartOutlineOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import ListItem from '@mui/material/ListItem';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import Badge from '@mui/material/Badge';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import InputBase from '@mui/material/InputBase';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link, useNavigate } from 'react-router-dom';
import axios from '../../utils/axios';
import { useUser } from '../../contexts/auth';
import logo from "../../assets/images/logo1.svg";
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import { useContext, useState, useEffect } from 'react';
import { ColorModeContext, tokens } from '../../theme';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import '../../App.css'


const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const SidebarItem = ({ title, to, icon, selected, setSelected, nestedItems, isOpen, handleToggle, currentNestedItem, setCurrentNestedItem }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
//   const [open, setOpen] = useState(false); // State to track whether nested items should be open

//   const handleClick = () => {
//     setSelected(title);
//     if (nestedItems) {
//         handleToggle(title); // Toggle the open state when clicking on the title
//       }
//   };
const handleClick = () => {
    setSelected(title);
    if (nestedItems) {
      if (isOpen && currentNestedItem === title) {
        // If the same nested item is clicked again, close it
        handleToggle(title);
        setCurrentNestedItem(null);
      } else {
        // Close the previously open nested item and open the clicked one
        if (currentNestedItem) {
          handleToggle(currentNestedItem);
        }
        handleToggle(title);
        setCurrentNestedItem(title);
      }
    }
  };

  return (
    <div>
      <ListItemButton
        selected={selected === title}
        style={{
          color: colors.grey[100],
        }}
        // onClick={handleClick}
        // component={Link}
        // to={to}
        onClick={handleClick}
        component={Link}
        to={to}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={title} />
        {nestedItems && !isOpen && <ChevronRightIcon />} {/* Show chevron if there are nested items and they're closed */}
        {nestedItems && isOpen && <ExpandMoreIcon />} {/* Show expand icon if nested items are open */}
      </ListItemButton>
      {nestedItems && isOpen && (
        <List>
          {nestedItems.map((item) => (
            <ListItemButton
              key={item.title}
              selected={selected === item.title}
              style={{
                color: colors.grey[100],
                paddingLeft: 32, // Adjust indentation as needed
              }}
              onClick={() => setSelected(item.title)}
              component={Link}
              to={item.to}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItemButton>
          ))}
        </List>
      )}
    </div>
  );
};

export default function Sidebar() {
  const theme = useTheme();
  const [selected, setSelected] = useState('Dashboard');
  const [open, setOpen] = useState(false);
  const { userrole, username, userid } = useUser();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [newNotifications, setNewNotifications] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
  const [openSubItems, setOpenSubItems] = useState({}); // State to track open/close status of nested items
  const [currentNestedItem, setCurrentNestedItem] = useState(null);


  const handleToggle = (title) => {
    setOpenSubItems((prevOpenSubItems) => ({
      ...prevOpenSubItems,
      [title]: !prevOpenSubItems[title],
    }));
  };

 const handleAction = () => {
    closeModal();
  };
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const fetchNotifications = async () => {
    try {
      const response = await axios.get('/notifications');
      if (!response.data) {
        throw new Error('No data received');
      }
      const data = response.data;
      setNotifications(data.notifications);
      setNewNotifications(
        data.notifications.some((notification) => isNewNotification(notification))
      );
    } catch (error) {
      console.error('Error fetching notifications:', error);
      if (error.response && error.response.data) {
        // console.log("Raw Response:", error.response.data);
      }
    }
  };

  const isNewNotification = (notification) => {
    const notificationDate = new Date(notification.expiredate);
    const currentDate = new Date();
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
    return notificationDate.getTime() - currentDate.getTime() < oneDayInMilliseconds;
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleLogout = async () => {
    const confirmed = window.confirm('Do you want to log out?');
    if (confirmed) {
      try {
        const response = await axios.post('/logout');
        if (response.status === 200) {
          localStorage.removeItem('jwtToken');
          navigate('/login');
        }
      } catch (error) {
        if (error.response.status === 403) {
          alert('Token Expired. Please login again.');
          localStorage.removeItem('jwtToken');
          navigate('/login');
        } else if (error.response.status === 401) {
          alert('Invalid token. Please login again.');
          localStorage.removeItem('jwtToken');
          navigate('/login');
        } else {
          alert('Error: ' + error.response.data.error);
        }
      }
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


  const sidebarItems = [
    {
      title: 'Dashboard',
      to: '/dashboard',
      icon: <HomeOutlinedIcon />,
      // nestedItems: [
      //   {
      //     title: 'Super',
      //     to: '/dashboard/super',
      //     icon: <PeopleOutlinedIcon />,
      //   },
      // ],
    },
    {
      title: 'Establishment',
      icon:  <PeopleOutlinedIcon />,
      nestedItems: [
        {
          title: 'Add',
          to: '/add-establishment',
          icon: <ContactsOutlinedIcon />,
        },
        {
          title: 'All ',
          to: '/all-establishment',
          icon: <ContactsOutlinedIcon />,
        },
      ],
    },
    {
        title: 'Lead',
        // to: '/add-lead',
        icon:  <PeopleOutlinedIcon />,
        nestedItems: [
          {
            title: 'Add',
            to: '/add-lead',
            icon: <ContactsOutlinedIcon />,
            
          },
          {
            title: 'All',
            to: '/all-lead',
            icon: <ContactsOutlinedIcon />,
          },
        ],
      },
      {
        title: 'User',
        // to: '/add-user',
        icon:  <PeopleOutlinedIcon />,
        nestedItems: [
          {
            title: 'Add',
            to: '/add-user',
            icon: <ContactsOutlinedIcon />,
          },
          {
            title: 'All',
            to: '/all-user',
            icon: <ContactsOutlinedIcon />,
          },
        ],
      },
      {
        title: 'Client',
        // to: '/add-client',
        icon:  <PeopleOutlinedIcon />,
        nestedItems: [
          {
            title: 'Add',
            to: '/add-client',
            icon: <ContactsOutlinedIcon />,
          },
          {
            title: 'All',
            to: '/all-client',
            icon: <ContactsOutlinedIcon />,
          },
        ],
      },
      {
        title: 'Pitched',
        // to: '/all-pitched',
        icon:  <BarChartOutlinedIcon />,
        nestedItems: [
          {
            title: 'All',
            to: '/all-piched',
            icon: <ContactsOutlinedIcon />,
          },
        ],
      },
      {
        title: 'Demo',
        // to: '/all-demo',
        icon:  <PieChartOutlineOutlinedIcon />,
        nestedItems: [
          {
            title: 'All',
            to: '/all-demo',
            icon: <ContactsOutlinedIcon />,
          },
        ],
      },
      {
        title: 'Negotiations',
        // to: '/all-negotiation',
        icon:  <TimelineOutlinedIcon />,
        nestedItems: [
          {
            title: 'All',
            to: '/all-negotiation',
            icon: <ContactsOutlinedIcon />,
          },
        ],
      },
      {
        title: 'Closed Lost',
        // to: '/all-closed-lost',
        icon:  <MapOutlinedIcon />,
        nestedItems: [
          {
            title: 'All',
            to: '/all-cloased-lost',
            icon: <ContactsOutlinedIcon />,
          },
        ],
      },
      {
        title: 'Closed Won',
        // to: '/all-closed-won',
        icon:  <PieChartOutlineOutlinedIcon />,
        nestedItems: [
          {
            title: 'All',
            to: '/all-closed-won',
            icon: <ContactsOutlinedIcon />,
          },
        ],
      },
      {
        title: 'RNR',
        // to: '/all-RNR',
        icon:  <BarChartOutlinedIcon />,
        nestedItems: [
          {
            title: 'All',
            to: '/all-RNR',
            icon: <ContactsOutlinedIcon />,
          },
        ],
      },
      {
        title: 'Junk',
        // to: '/all-junk',
        icon:  <PieChartOutlineOutlinedIcon />,
        nestedItems: [
          {
            title: 'All',
            to: '/all-junk',
            icon: <ContactsOutlinedIcon />,
          },
        ],
      },
      

  ];

    const filteredItems = sidebarItems.filter(item =>
    item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    (item.nestedItems && item.nestedItems.some(nestedItem =>
      nestedItem.title.toLowerCase().includes(searchQuery.toLowerCase())
    ))
  );

  // return (
  //   <Box sx={{ display: 'flex' }}>
  //     <CssBaseline />
  //     <AppBar position="fixed" open={open}>
  //       <Toolbar>
  //         <IconButton
  //           color="inherit"
  //           aria-label="open drawer"
  //           onClick={handleDrawerOpen}
  //           edge="start"
  //           sx={{ mr: 2, ...(open && { display: 'none' }) }}
  //         >
  //           <MenuIcon />
  //         </IconButton>
  //           {/* Logo and Title */}
  //        {!open && (
  //         <>
  //            <span>
  //              <img
  //                src={logo}
  //                alt="Logo"
  //                width="30"
  //                 height="30"
  //               />
  //             </span>
  //              <Typography variant="h5" color={colors.grey[100]} sx={{ ml: 1 }}>
  //                ContactTab.com
  //              </Typography>
  //       </>
  //          )}
  //          <Typography variant="h6" noWrap component="div">
  //           <Box display="flex" justifyContent="space-between" p={2}>
  //             {/* SEARCH BAR */}
  //             <Box display="flex" backgroundColor={colors.primary[400]} borderRadius="3px">
  //               <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
  //               <IconButton type="button" sx={{ p: 1 }}>
  //                 <SearchIcon />
  //               </IconButton>
  //             </Box>
  //             <Box>
  //               <Typography></Typography>
  //             </Box>

  //             {/* ICONS */}
  //             <Box display="flex">
  //               <IconButton onClick={colorMode.toggleColorMode}>
  //                 {theme.palette.mode === 'dark' ? (
  //                   <DarkModeOutlinedIcon />
  //                 ) : (
  //                   <LightModeOutlinedIcon />
  //                 )}
  //               </IconButton>
  //               <IconButton onClick={openModal}>
  //                 <Badge badgeContent={notifications.length} color="error" variant="dot">
  //                   <NotificationsOutlinedIcon />
  //                 </Badge>
  //               </IconButton>
  //               <IconButton>
  //                 <SettingsOutlinedIcon />
  //               </IconButton>
  //               <IconButton onClick={handleMenuOpen}>
  //                 <PersonOutlinedIcon />
  //               </IconButton>
  //               <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
  //                 <MenuItem onClick={handleLogout}>Logout</MenuItem>
  //               </Menu>
  //             </Box>
  //             <Modal open={modalOpen} onClose={closeModal}>
  //               <Box
  //                 sx={{
  //                   position: 'absolute',
  //                   top: '50%',
  //                   left: '50%',
  //                   transform: 'translate(-50%, -50%)',
  //                   bgcolor: 'background.paper',
  //                   boxShadow: 24,
  //                   p: 4,
  //                   width: 500,
  //                   borderRadius: 3,
  //                 }}
  //               >
  //                 <IconButton
  //                   edge="end"
  //                   color="inherit"
  //                   onClick={closeModal}
  //                   sx={{
  //                     position: 'absolute',
  //                     top: 0,
  //                     right: 5,
  //                   }}
  //                 >
  //                   <CloseIcon />
  //                 </IconButton>
  //                 <Typography variant="h5" mb={2}>
  //                   Reminders
  //                 </Typography>
  //                 <Divider sx={{ mb: 2 }} />
  //                 <List>
  //                   {notifications.map((notification, index) => (
  //                     <ListItem key={index}>
  //                       <ListItemText
  //                         primary={`${notification.ownername}, Your ${
  //                           notification.sourcetable
  //                         } expiry date is on: ${new Date(notification.expiredate).toDateString()}`}
  //                       />
  //                     </ListItem>
  //                   ))}
  //                 </List>
  //                 <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
  //                   <Button variant="contained" color="primary" onClick={handleAction}>
  //                     Take Action
  //                   </Button>
  //                 </Box>
  //               </Box>
  //             </Modal>
  //           </Box>
  //         </Typography>
  //       </Toolbar>
  //     </AppBar>
  //     <Drawer
  //       sx={{
  //         width: drawerWidth,
  //         flexShrink: 0,
  //         '& .MuiDrawer-paper': {
  //           width: drawerWidth,
  //           boxSizing: 'border-box',
  //         },
  //       }}
  //       variant="persistent"
  //       anchor="left"
  //       open={open}
  //     >
  //       <DrawerHeader>
  //                {/* Logo and Title */}
  //                {open && (
  //           <>
  //             <span>
  //               <img
  //                 src={logo}
  //                 alt="Logo"
  //                 width="30"
  //                 height="30"
  //               />
  //             </span>
  //             <Typography variant="h5" color={colors.grey[100]} sx={{ ml: 1 }}>
  //               ContactTab.com
  //             </Typography>
  //           </>
  //         )}
  //         <IconButton onClick={handleDrawerClose}>
  //           {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
  //         </IconButton>
  //       </DrawerHeader>
  //       <Divider />
  //       <Box sx={{ display: 'flex', alignItems: 'center', padding: '0 16px' }}>
  //          <InputBase
  //           sx={{ flex: 1, padding: '8px', borderRadius: '4px', backgroundColor: colors.primary[400] }}
  //           placeholder="Search Sidebar"
  //           value={searchQuery}
  //           onChange={(e) => setSearchQuery(e.target.value)}
  //         />
  //         <IconButton type="button">
  //           <SearchIcon />
  //         </IconButton>
  //       </Box>
  //       <Divider />
  //       <List>
  //         {filteredItems.map((item) => (
  //           <SidebarItem
  //             key={item.title}
  //             title={item.title}
  //             to={item.to}
  //             icon={item.icon}
  //             selected={selected}
  //             setSelected={setSelected}
  //             nestedItems={item.nestedItems}
  //             isOpen={openSubItems[item.title]}
  //             handleToggle={handleToggle}
  //             currentNestedItem={currentNestedItem}
  //             setCurrentNestedItem={setCurrentNestedItem}
  //           />
  //         ))}
  //       </List>
  //     </Drawer>
  //     <Main open={open}>
  //       <DrawerHeader />
  //       {/* Your main content goes here */}
  //     </Main>
  //   </Box>
  // );
}



// import * as React from 'react';
// import { styled, useTheme } from '@mui/material/styles';
// import Drawer from '@mui/material/Drawer';
// import MuiAppBar from '@mui/material/AppBar';
// import Typography from '@mui/material/Typography';
// import Divider from '@mui/material/Divider';
// import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import List from '@mui/material/List';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
// import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
// import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
// import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
// import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
// import PieChartOutlineOutlinedIcon from '@mui/icons-material/PieChartOutlineOutlined';
// import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
// import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
// import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
// import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
// import SearchIcon from '@mui/icons-material/Search';
// import CloseIcon from '@mui/icons-material/Close';
// import ListItem from '@mui/material/ListItem';
// import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
// import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
// import Badge from '@mui/material/Badge';
// import Modal from '@mui/material/Modal';
// import Button from '@mui/material/Button';
// import InputBase from '@mui/material/InputBase';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
// import { Link, useNavigate } from 'react-router-dom';
// import axios from '../../utils/axios';
// import { useUser } from '../../contexts/auth';
// import logo from "../../assets/images/logo1.svg";
// import Box from '@mui/material/Box';
// import CssBaseline from '@mui/material/CssBaseline';
// import Toolbar from '@mui/material/Toolbar';
// import { useContext, useState, useEffect } from 'react';
// import { ColorModeContext, tokens } from '../../theme';
// import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
// import '../../App.css'

// const drawerWidth = 240;

// const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
//   ({ theme, open }) => ({
//     flexGrow: 1,
//     padding: theme.spacing(3),
//     transition: theme.transitions.create('margin', {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//     marginLeft: `-${drawerWidth}px`,
//     ...(open && {
//       transition: theme.transitions.create('margin', {
//         easing: theme.transitions.easing.easeOut,
//         duration: theme.transitions.duration.enteringScreen,
//       }),
//       marginLeft: 0,
//     }),
//   })
// );

// const AppBar = styled(MuiAppBar, {
//   shouldForwardProp: (prop) => prop !== 'open',
// })(({ theme, open }) => ({
//   transition: theme.transitions.create(['margin', 'width'], {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   ...(open && {
//     width: `calc(100% - ${drawerWidth}px)`,
//     marginLeft: `${drawerWidth}px`,
//     transition: theme.transitions.create(['margin', 'width'], {
//       easing: theme.transitions.easing.easeOut,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   }),
// }));

// const DrawerHeader = styled('div')(({ theme }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   padding: theme.spacing(0, 1),
//   ...theme.mixins.toolbar,
//   justifyContent: 'flex-end',
// }));

// const SidebarItem = ({ title, to, icon, selected, setSelected, nestedItems, isOpen, handleToggle, currentNestedItem, setCurrentNestedItem }) => {
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);

//   const handleClick = () => {
//     setSelected(title);
//     if (nestedItems) {
//       if (isOpen && currentNestedItem === title) {
//         handleToggle(title);
//         setCurrentNestedItem(null);
//       } else {
//         if (currentNestedItem) {
//           handleToggle(currentNestedItem);
//         }
//         handleToggle(title);
//         setCurrentNestedItem(title);
//       }
//     }
//   };

//   return (
//     <div>
//       <ListItemButton
//         selected={selected === title}
//         style={{ color: colors.grey[100] }}
//         onClick={handleClick}
//         component={to ? Link : 'div'}
//         to={to}
//       >
//         <ListItemIcon>{icon}</ListItemIcon>
//         <ListItemText primary={title} />
//         {nestedItems && (isOpen ? <ExpandMoreIcon /> : <ChevronRightIcon />)}
//       </ListItemButton>
//       {nestedItems && isOpen && (
//         <List>
//           {nestedItems.map((item) => (
//             <SidebarItem
//               key={item.title}
//               title={item.title}
//               to={item.to}
//               icon={item.icon}
//               selected={selected}
//               setSelected={setSelected}
//               nestedItems={item.nestedItems}
//               // isOpen={isOpen}
//               //  isOpen={openSubItems[item.title]}
//               handleToggle={handleToggle}
//               currentNestedItem={currentNestedItem}
//               setCurrentNestedItem={setCurrentNestedItem}
//             />
//           ))}
//         </List>
//       )}
//     </div>
//   );
// };

// export default function Sidebar() {
//   const theme = useTheme();
//   const [selected, setSelected] = useState('Dashboard');
//   const [open, setOpen] = useState(true);
//   const { userrole, username, userid } = useUser();
//   const colors = tokens(theme.palette.mode);
//   const colorMode = useContext(ColorModeContext);
//   const [modalOpen, setModalOpen] = useState(false);
//   const [notifications, setNotifications] = useState([]);
//   const [newNotifications, setNewNotifications] = useState(false);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [searchQuery, setSearchQuery] = useState('');
//   const navigate = useNavigate();
//   const [openSubItems, setOpenSubItems] = useState({});
//   const [currentNestedItem, setCurrentNestedItem] = useState(null);

//   const handleToggle = (title) => {
//     setOpenSubItems((prevOpenSubItems) => ({
//       ...prevOpenSubItems,
//       [title]: !prevOpenSubItems[title],
//     }));
//   };

//   const handleMenuOpen = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };

//   const fetchNotifications = async () => {
//     try {
//       const response = await axios.get('/notifications');
//       if (!response.data) {
//         throw new Error('No data received');
//       }
//       const data = response.data;
//       setNotifications(data.notifications);
//       setNewNotifications(
//         data.notifications.some((notification) => isNewNotification(notification))
//       );
//     } catch (error) {
//       console.error('Error fetching notifications:', error);
//     }
//   };

//   const isNewNotification = (notification) => {
//     const notificationDate = new Date(notification.expiredate);
//     const currentDate = new Date();
//     const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
//     return notificationDate.getTime() - currentDate.getTime() < oneDayInMilliseconds;
//   };

//   useEffect(() => {
//     fetchNotifications();
//   }, []);

//   const openModal = () => {
//     setModalOpen(true);
//   };

//   const closeModal = () => {
//     setModalOpen(false);
//   };

//   const handleLogout = async () => {
//     const confirmed = window.confirm('Do you want to log out?');
//     if (confirmed) {
//       try {
//         const response = await axios.post('/logout');
//         if (response.status === 200) {
//           localStorage.removeItem('jwtToken');
//           navigate('/login');
//         }
//       } catch (error) {
//         if (error.response.status === 403) {
//           alert('Token Expired. Please login again.');
//           localStorage.removeItem('jwtToken');
//           navigate('/login');
//         } else if (error.response.status === 401) {
//           alert('Invalid token. Please login again.');
//           localStorage.removeItem('jwtToken');
//           navigate('/login');
//         } else {
//           alert('Error: ' + error.response.data.error);
//         }
//       }
//     }
//   };

//   const handleDrawerOpen = () => {
//     setOpen(true);
//   };

//   const handleDrawerClose = () => {
//     setOpen(false);
//   };

//   const handleAction = () => {
//     closeModal();
//   };

//   const sidebarItems = [
//     {
//       title: 'Dashboard',
//       to: '/dashboard',
//       icon: <HomeOutlinedIcon />,
//     },
//     {
//       title: 'Establishment',
//       icon: <PeopleOutlinedIcon />,
//      nestedItems: [
//         {
//           title: 'Add',
//           to: '/add-establishment',
//           icon: <ContactsOutlinedIcon />,
//           // nestedItems: [
//           //   {
//           //     title: 'Inside Add',
//           //     to: '/inside-add',
//           //     icon: <ContactsOutlinedIcon />,
//           //   },
//           // ],
//         },
//         {
//           title: 'All',
//           to: '/all-establishment',
//           icon: <ContactsOutlinedIcon />,
//         },
//       ],
//     },
//     {
//       title: 'Lead',
//       icon: <PeopleOutlinedIcon />,
//       nestedItems: [
//         {
//           title: 'Add',
//           to: '/add-lead',
//           icon: <ContactsOutlinedIcon />,
//         },
//         {
//           title: 'All',
//           to: '/all-lead',
//           icon: <ContactsOutlinedIcon />,
//         },
//       ],
//     },
//     {
//       title: 'User',
//       icon: <PeopleOutlinedIcon />,
//       nestedItems: [
//         {
//           title: 'Add',
//           to: '/add-user',
//           icon: <ContactsOutlinedIcon />,
//         },
//         {
//           title: 'All',
//           to: '/all-user',
//           icon: <ContactsOutlinedIcon />,
//         },
//       ],
//     },
//     {
//       title: 'Client',
//       icon: <PeopleOutlinedIcon />,
//       nestedItems: [
//         {
//           title: 'Add',
//           to: '/add-client',
//           icon: <ContactsOutlinedIcon />,
//         },
//         {
//           title: 'All',
//           to: '/all-client',
//           icon: <ContactsOutlinedIcon />,
//         },
//       ],
//     },
//     {
//       title: 'Pitched',
//       icon: <BarChartOutlinedIcon />,
//       nestedItems: [
//         {
//           title: 'All',
//           to: '/all-piched',
//           icon: <ContactsOutlinedIcon />,
//         },
//       ],
//     },
//     {
//       title: 'Demo',
//       icon: <PieChartOutlineOutlinedIcon />,
//       nestedItems: [
//         {
//           title: 'All',
//           to: '/all-demo',
//           icon: <ContactsOutlinedIcon />,
//         },
//       ],
//     },
//     {
//       title: 'Negotiations',
//       icon: <TimelineOutlinedIcon />,
//       nestedItems: [
//         {
//           title: 'All',
//           to: '/all-negotiation',
//           icon: <ContactsOutlinedIcon />,
//         },
//       ],
//     },
//     {
//       title: 'Closed Lost',
//       icon: <MapOutlinedIcon />,
//       nestedItems: [
//         {
//           title: 'All',
//           to: '/all-closed-lost',
//           icon: <ContactsOutlinedIcon />,
//         },
//       ],
//     },
//     {
//       title: 'Closed Won',
//       icon: <PieChartOutlineOutlinedIcon />,
//       nestedItems: [
//         {
//           title: 'All',
//           to: '/all-closed-won',
//           icon: <ContactsOutlinedIcon />,
//         },
//       ],
//     },
//     {
//       title: 'RNR',
//       icon: <BarChartOutlinedIcon />,
//       nestedItems: [
//         {
//           title: 'All',
//           to: '/all-RNR',
//           icon: <ContactsOutlinedIcon />,
//         },
//       ],
//     },
//     {
//       title: 'Junk',
//       icon: <PieChartOutlineOutlinedIcon />,
//       nestedItems: [
//         {
//           title: 'All',
//           to: '/all-junk',
//           icon: <ContactsOutlinedIcon />,
//         },
//       ],
//     },
//   ];

//   const filteredItems = sidebarItems.filter(item =>
//     item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
//     (item.nestedItems && item.nestedItems.some(nestedItem =>
//       nestedItem.title.toLowerCase().includes(searchQuery.toLowerCase())
//     ))
//   );

//   return (
//     <Box sx={{ display: 'flex' }}>
//       <CssBaseline />
//       <AppBar position="fixed" open={open}>
//         <Toolbar>
//           <IconButton
//             color="inherit"
//             aria-label="open drawer"
//             onClick={handleDrawerOpen}
//             edge="start"
//             sx={{ mr: 2, ...(open && { display: 'none' }) }}
//           >
//             <MenuIcon />
//           </IconButton>
//           {!open && (
//             <>
//               <span>
//                 <img src={logo} alt="Logo" width="30" height="30" />
//               </span>
//               <Typography variant="h5" color={colors.grey[100]} sx={{ ml: 1 }}>
//                 ContactTab.com
//               </Typography>
//             </>
//           )}
//           <Typography variant="h6" noWrap component="div">
//             <Box display="flex" justifyContent="space-between" p={2}>
//               <Box display="flex" backgroundColor={colors.primary[400]} borderRadius="3px">
//                 <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
//                 <IconButton type="button" sx={{ p: 1 }}>
//                   <SearchIcon />
//                 </IconButton>
//               </Box>
//               <Box display="flex">
//                 <IconButton onClick={colorMode.toggleColorMode}>
//                   {theme.palette.mode === 'dark' ? <DarkModeOutlinedIcon /> : <LightModeOutlinedIcon />}
//                 </IconButton>
//                 <IconButton onClick={openModal}>
//                   <Badge badgeContent={notifications.length} color="error" variant="dot">
//                     <NotificationsOutlinedIcon />
//                   </Badge>
//                 </IconButton>
//                 <IconButton>
//                   <SettingsOutlinedIcon />
//                 </IconButton>
//                 <IconButton onClick={handleMenuOpen}>
//                   <PersonOutlinedIcon />
//                 </IconButton>
//                 <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
//                   <MenuItem onClick={handleLogout}>Logout</MenuItem>
//                 </Menu>
//                 <Modal open={modalOpen} onClose={closeModal}>
//                   <Box
//                     sx={{
//                       position: 'absolute',
//                       top: '50%',
//                       left: '50%',
//                       transform: 'translate(-50%, -50%)',
//                       bgcolor: 'background.paper',
//                       boxShadow: 24,
//                       p: 4,
//                       width: 500,
//                       borderRadius: 3,
//                     }}
//                   >
//                     <IconButton
//                       edge="end"
//                       color="inherit"
//                       onClick={closeModal}
//                       sx={{
//                         position: 'absolute',
//                         top: 0,
//                         right: 5,
//                       }}
//                     >
//                       <CloseIcon />
//                     </IconButton>
//                     <Typography variant="h5" mb={2}>
//                       Reminders
//                     </Typography>
//                     <Divider sx={{ mb: 2 }} />
//                     <List>
//                       {notifications.map((notification, index) => (
//                         <ListItem key={index}>
//                           <ListItemText
//                             primary={`${notification.ownername}, Your ${
//                               notification.sourcetable
//                             } expiry date is on: ${new Date(notification.expiredate).toDateString()}`}
//                           />
//                         </ListItem>
//                       ))}
//                     </List>
//                     <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
//                       <Button variant="contained" color="primary" onClick={handleAction}>
//                         Take Action
//                       </Button>
//                     </Box>
//                   </Box>
//                 </Modal>
//               </Box>
//             </Box>
//           </Typography>
//         </Toolbar>
//       </AppBar>
//       <Drawer
//         sx={{
//           width: drawerWidth,
//           flexShrink: 0,
//           '& .MuiDrawer-paper': {
//             width: drawerWidth,
//             boxSizing: 'border-box',
//           },
//         }}
//         variant="persistent"
//         anchor="left"
//         open={open}
//       >
//         <DrawerHeader>
//           {open && (
//             <>
//               <span>
//                 <img src={logo} alt="Logo" width="30" height="30" />
//               </span>
//               <Typography variant="h5" color={colors.grey[100]} sx={{ ml: 1 }}>
//                 ContactTab.com
//               </Typography>
//             </>
//           )}
//           <IconButton onClick={handleDrawerClose}>
//             {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
//           </IconButton>
//         </DrawerHeader>
//         <Divider />
//         <Box sx={{ display: 'flex', alignItems: 'center', padding: '0 16px' }}>
//           <InputBase
//             sx={{ flex: 1, padding: '8px', borderRadius: '4px', backgroundColor: colors.primary[400] }}
//             placeholder="Search Sidebar"
//             value={searchQuery}
//             onChange={(e) => setSearchQuery(e.target.value)}
//           />
//           <IconButton type="button">
//             <SearchIcon />
//           </IconButton>
//         </Box>
//         <Divider />
//         <List>
//           {filteredItems.map((item) => (
//             <SidebarItem
//               key={item.title}
//               title={item.title}
//               to={item.to}
//               icon={item.icon}
//               selected={selected}
//               setSelected={setSelected}
//               nestedItems={item.nestedItems}
//               isOpen={openSubItems[item.title]}
//               handleToggle={handleToggle}
//               currentNestedItem={currentNestedItem}
//               setCurrentNestedItem={setCurrentNestedItem}
//             />
//           ))}
//         </List>
//       </Drawer>
//       <Main open={open}>
//         <DrawerHeader />
//         {/* Your main content goes here */}
//       </Main>
//     </Box>
//   );
// }

