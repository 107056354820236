import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
  Typography,
  Grid,
  Paper,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker, TimePicker } from '@mui/x-date-pickers';
import axios from 'axios';
import '../../App.css';

const validationSchema = yup.object({
  subject: yup.string().required('Subject is required'),
  dueDate: yup.date().nullable().required('Due Date is required'),
  contact: yup.string().required('Contact is required'),
  account: yup.string().required('Account is required'),
});

const CreateTask = () => {
  const [reminderEnabled, setReminderEnabled] = useState(false);
  const [repeatEnabled, setRepeatEnabled] = useState(false);

  const formik = useFormik({
    initialValues: {
      taskOwner: 'Vivek Kumar Yadav',
      subject: '',
      dueDate: null,
      contact: '',
      account: '',
      status: 'Not Started',
      priority: 'High',
      reminder: false,
      reminderDate: null,
      reminderTime: null,
      reminderType: 'Pop Up',
      repeat: false,
      repeatType: 'Daily',
      repeatEnds: 'Never',
      repeatTimes: 1,
      repeatEndDate: null,
      description: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        console.log('Form Values:', values);

        const response = await axios.post('/api/tasks', values);
        console.log('API Response:', response.data);

        resetForm();
        setReminderEnabled(false);
        setRepeatEnabled(false);
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    },
  });

  return (
    <>
    <Helmet>
      <title>Create Task - Contacttab.com</title>
    </Helmet>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Paper elevation={3} sx={{ p: 3, m: 2 }} style={{marginTop: '6%'}}>
        <Typography variant="h5" gutterBottom>
          Create Task
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Task Owner"
                name="taskOwner"
                value={formik.values.taskOwner}
                onChange={formik.handleChange}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Subject"
                name="subject"
                value={formik.values.subject}
                onChange={formik.handleChange}
                error={formik.touched.subject && Boolean(formik.errors.subject)}
                helperText={formik.touched.subject && formik.errors.subject}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Contact"
                name="contact"
                value={formik.values.contact}
                onChange={formik.handleChange}
                error={formik.touched.contact && Boolean(formik.errors.contact)}
                helperText={formik.touched.contact && formik.errors.contact}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Account"
                name="account"
                value={formik.values.account}
                onChange={formik.handleChange}
                error={formik.touched.account && Boolean(formik.errors.account)}
                helperText={formik.touched.account && formik.errors.account}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  name="status"
                  value={formik.values.status}
                  onChange={formik.handleChange}
                  label="Status"
                >
                  <MenuItem value="Not Started">Not Started</MenuItem>
                  <MenuItem value="In Progress">In Progress</MenuItem>
                  <MenuItem value="Completed">Completed</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Priority</InputLabel>
                <Select
                  name="priority"
                  value={formik.values.priority}
                  onChange={formik.handleChange}
                  label="Priority"
                >
                  <MenuItem value="High">High</MenuItem>
                  <MenuItem value="Medium">Medium</MenuItem>
                  <MenuItem value="Low">Low</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePicker
                label="Due Date"
                value={formik.values.dueDate}
                onChange={(date) => formik.setFieldValue('dueDate', date)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    error={formik.touched.dueDate && Boolean(formik.errors.dueDate)}
                    helperText={formik.touched.dueDate && formik.errors.dueDate}
                  />
                )}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <Typography>Reminder</Typography>
                <Switch
                  checked={reminderEnabled}
                  onChange={() => {
                    setReminderEnabled(!reminderEnabled);
                    formik.setFieldValue('reminder', !reminderEnabled);
                  }}
                />
              </Box>
            </Grid>
            {reminderEnabled && (
              <>
                <Grid item xs={12} sm={4}>
                  <DatePicker
                    label="Reminder Date"
                    value={formik.values.reminderDate}
                    onChange={(date) => formik.setFieldValue('reminderDate', date)}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TimePicker
                    label="Reminder Time"
                    value={formik.values.reminderTime}
                    onChange={(time) => formik.setFieldValue('reminderTime', time)}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <InputLabel>Notify</InputLabel>
                    <Select
                      name="reminderType"
                      value={formik.values.reminderType}
                      onChange={formik.handleChange}
                      label="Notify"
                    >
                      <MenuItem value="Pop Up">Pop Up</MenuItem>
                      <MenuItem value="Email">Email</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <Typography>Repeat</Typography>
                <Switch
                  checked={repeatEnabled}
                  onChange={() => {
                    setRepeatEnabled(!repeatEnabled);
                    formik.setFieldValue('repeat', !repeatEnabled);
                  }}
                />
              </Box>
            </Grid>
            {repeatEnabled && (
              <>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Repeat Type</InputLabel>
                    <Select
                      name="repeatType"
                      value={formik.values.repeatType}
                      onChange={formik.handleChange}
                      label="Repeat Type"
                    >
                      <MenuItem value="Daily">Daily</MenuItem>
                      <MenuItem value="Weekly">Weekly</MenuItem>
                      <MenuItem value="Monthly">Monthly</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Ends</InputLabel>
                    <Select
                      name="repeatEnds"
                      value={formik.values.repeatEnds}
                      onChange={formik.handleChange}
                      label="Ends"
                    >
                      <MenuItem value="Never">Never</MenuItem>
                      <MenuItem value="After">After</MenuItem>
                      <MenuItem value="On">On</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {formik.values.repeatEnds === 'After' && (
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Times"
                      name="repeatTimes"
                      value={formik.values.repeatTimes}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                )}
                {formik.values.repeatEnds === 'On' && (
                  <Grid item xs={12} sm={6}>
                    <DatePicker
                      label="End Date"
                      value={formik.values.repeatEndDate}
                      onChange={(date) => formik.setFieldValue('repeatEndDate', date)}
                      renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                  </Grid>
                )}
              </>
            )} */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Description"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
              />
            </Grid>
          </Grid>
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button
              type="button"
              onClick={() => {
                formik.resetForm();
                setReminderEnabled(false);
                setRepeatEnabled(false);
              }}
              variant="contained"
              color="primary"
              style={{ margin: '20px', background: 'blue', padding: '5px 10px' }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ margin: '20px', background: 'blue', padding: '5px 10px' }}
            >
              Save
            </Button>
          </Box>
        </form>
      </Paper>
    </LocalizationProvider>

    </>
  );
};

export default CreateTask;
