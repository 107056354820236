import React from 'react';
import './UnifiedSalesProjectManagement.css'; // You'll need to create this CSS file
import '../../App.css'
import { useNavigate } from "react-router-dom";

const AllProject = () => {
  const navigate = useNavigate();

    return (
        <div className="unified-sales-project-management">
          <h1>Unified Sales and Project Management</h1>
          <p className="subtitle">
            A smarter way to bridge the gap between sales and project tracking. <a href="#">Learn More</a>
          </p>
    
          <div className="features-container">
            <div className="feature">
              <div className="icon connect-icon">
                <svg viewBox="0 0 24 24" fill="currentColor">
                  <path d="M8 11h1v2H8v-2zm2-2h1v4h-1V9zm2 4h1v2h-1v-2zm2-8h1v8h-1V5zm2 11h-1V6h1v10zm4-7h-1V3h1v6zM5 21H3V3h2v18zm16 0h-2V11h2v10z"/>
                </svg>
              </div>
              <h2>Connect</h2>
              <p>Integrate Zoho Projects to create and associate projects in Zoho CRM.</p>
            </div>
    
            <div className="feature">
              <div className="icon track-icon">
                <svg viewBox="0 0 24 24" fill="currentColor">
                  <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-5 14H7v-2h7v2zm3-4H7v-2h10v2zm0-4H7V7h10v2z"/>
                </svg>
              </div>
              <h2>Track</h2>
              <p>Stay on top of your tasks and milestones.</p>
            </div>
    
            <div className="feature">
              <div className="icon deliver-icon">
                <svg viewBox="0 0 24 24" fill="currentColor">
                  <path d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-2 14l-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z"/>
                </svg>
              </div>
              <h2>Deliver</h2>
              <p>Execute customer projects on time, every time.</p>
            </div>
          </div>
    
          <div className="cta-container">
          <button
          className="cta-button"
          // onClick={() => navigate('/projects-integration')}
        >
          Get Started
        </button>
            <label className="checkbox-label" onClick={() => navigate('/dashboard')}>
             Don't show this tab again.
            </label>
          </div>
        </div>
      );
};

export default AllProject;