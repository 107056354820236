import React, { useContext, useState, useRef, useEffect } from 'react';
import {
  Box,
  Badge,
  Popover,
  IconButton,
  Typography,
  Button,
  Modal,
  List,
  ListItem,
  ListItemText,
  Divider,
  InputBase,
} from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/material';
import { ColorModeContext, tokens } from "../../theme";
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import AddBoxIcon from '@mui/icons-material/AddBox';
import axios from '../../utils/axios';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../contexts/auth'; // Assuming you have a context for user authentication
import logo from '../../assets/images/logo1.svg';
import '../../App.css';
const Topbar = () => {
  const theme = useTheme();
  const { username,userrole, companycode } = useUser();  // Assuming useUser provides information about the current username
  console.log(username,userrole, companycode)
  const colorMode = useContext(ColorModeContext);
  const colors = tokens(theme.palette.mode);
  const [modalOpen, setModalOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [newNotifications, setNewNotifications] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [addAnchorEl, setAddAnchorEl] = useState(null);
  const [searchOpen, setSearchOpen] = useState(false);
  const searchRef = useRef(null);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
 
  const isMobile = useMediaQuery('(max-width:500px)');
  const isMobile1 = useMediaQuery('()');


  useEffect(() => {
    if (username) {
      fetchNotifications();
    }
  }, [username,userrole, companycode]);


  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenMenu1 = (event) => {
    setAddAnchorEl(event.currentTarget);
  };

  const handleCloseMenu1 = () => {
    setAddAnchorEl(null);
  };

  // Close search field when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setSearchOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleAction = () => {
    closeModal();
  };

  const toggleSearch = () => {
    setSearchOpen(!searchOpen);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const fetchNotifications = async () => {
    try {
      const response = await axios.get('/notifications');

      if (!response.data) {
        throw new Error('No data received');
      }

      const data = response.data;
      setNotifications(data.notifications);
      setNewNotifications(
        data.notifications.some((notification) => isNewNotification(notification))
      );
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  const isNewNotification = (notification) => {
    const notificationDate = new Date(notification.expiredate);
    const currentDate = new Date();
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000;

    return notificationDate.getTime() - currentDate.getTime() < oneDayInMilliseconds;
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const handleLogout = async () => {
    const confirmed = window.confirm('Do you want to log out?');
    if (confirmed) {
      try {
        const response = await axios.post('/logout');

        if (response.status === 200) {
          localStorage.removeItem('jwtToken');
          window.location.reload();
          navigate('/login'); // Navigate to '/login' after logout
        }
      } catch (error) {
        if (error.response.status === 403) {
          alert('Token Expired. Please login again.');
          localStorage.removeItem('jwtToken');
          window.location.reload();
          navigate('/login'); // Navigate to '/login' if token expired
        } else if (error.response.status === 401) {
          alert('Invalid token. Please login again.');
          localStorage.removeItem('jwtToken');
          window.location.reload();
          navigate('/login'); // Navigate to '/login' if invalid token
        } else {
          alert('Error: ' + error.response.data.error);
        }
      }
    }
  };

  // Main items list (hard-coded)
  const mainItems = [
    'Demo', 'Products', 'Quotes', 'Sales Orders', 'Purchase Orders',
    'Invoices', 'SalesInbox', 'Campaigns', 'Vendors', 'Price Books',
    'Cases', 'Solutions', 'Documents', 'Forecasts', 'Visits', 'Social'
  ];

  const mainItems1 = [
    'Home', 'Leads', 'Contacts', 'Accounts', 'Deals',
    'Tasks', 'Meetings', 'Calls', 'Reports', 'Services', 'Projects',
  ];
// Add 'User' and 'Establishment' only if the user role is Superadmin
if (userrole === 'Superadmin') {
  mainItems1.push('User');
}
if (userrole === 'Superadmin' && companycode === 1) {
  mainItems1.push('Establishment');
}
  const handleListItemClick = (item) => {
    handleCloseMenu1(); // Close the popover after clicking on an item
    switch (item) {
      case 'Home':
        navigate('/dashboard');
        break;
      case 'Leads':
        navigate('/all-lead');
        break;
      case 'Contacts':
        navigate('/all-contact');
        break;
      case 'Accounts':
        navigate('/all-account');
        break;
      case 'Deals':
        navigate('/all-deal');
        break;
      case 'Tasks':
        navigate('/all-task');
        break;
      case 'Meetings':
        navigate('/all-meeting');
        break;
      case 'Calls':
        navigate('/all-call');
        break;
      case 'Reports':
        navigate('/all-report');
        break;
      // case 'Services':
      //   // Handle navigation for Services
      //   break;
      case 'Projects':
        navigate('/all-project');
        break;
      case 'User':
        navigate('/all-user');
        break;
        case 'Establishment':
          navigate('/all-establishment');
          break;
      default:
        break;
    }
  };

  const filteredItems1 = mainItems1.filter(item =>
    item.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Filter items based on search query
  const filteredItems = mainItems.filter(item =>
    item.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const [padding, setPadding] = useState('10px');
  useEffect(() => {
    const updatePadding = () => {
      if (window.innerWidth <= 600) {
        setPadding('10px');
      } else if (window.innerWidth > 1400 && window.innerWidth <= 1700) {
        setPadding('18px');
      } else {
        setPadding('10px');
      }
    };

    updatePadding(); // Set padding on initial render
    window.addEventListener('resize', updatePadding); // Update padding on window resize

    return () => window.removeEventListener('resize', updatePadding); // Cleanup on component unmount
  }, []);
  return (
    <Box display="flex" justifyContent="space-between" p={2} position="fixed" width="100%" bgcolor="rebeccapurple" zIndex="1000"  padding={padding}>
      <>
        <span style={{ marginTop: '10px' }}>
          <img className='imageMobile' src={logo} alt="Logo" width="30" height="30" />
        </span>
        
        <Typography variant="h5" color={colors.grey[100]} sx={{ ml: 1, }}  className='websiteName' >
          ContactTab.com
        </Typography>
      </>

      {/* NAVIGATION LINKS */}
      {!isMobile && (
        <Box display="flex" alignItems="center">
  <Box mr={2}>
    <Button 
      style={{ fontSize: '16px', textTransform: 'none' }} 
      color="inherit" 
      className="underline-on-hover" 
      onClick={() => navigate('/dashboard')}
    >
      Home
    </Button>
  </Box>
          <Box mr={2}>
            <Button  style={{ fontSize: '16px', textTransform: 'none' }}  color="inherit" className="underline-on-hover" onClick={() => navigate('/all-lead')}>
              Leads
            </Button>
          </Box>
          <Box mr={2}>
            <Button  style={{ fontSize: '16px', textTransform: 'none' }}  color="inherit" className="underline-on-hover" onClick={() => navigate('/all-contact')}>
              Contacts
            </Button>
          </Box>
          <Box mr={2}>
            <Button  style={{ fontSize: '16px', textTransform: 'none' }}  color="inherit" className="underline-on-hover" onClick={() => navigate('/all-account')}>
              Accounts
            </Button>
          </Box>
          <Box mr={2}>
            <Button style={{ fontSize: '16px', textTransform: 'none' }} color="inherit" className="underline-on-hover" onClick={() => navigate('/all-deal')}>
              Deals
            </Button>
          </Box>
          <Box mr={2}>
            <Button  style={{ fontSize: '16px', textTransform: 'none' }}  color="inherit" className="underline-on-hover" onClick={() => navigate('/all-task')}>
              Tasks
            </Button>
          </Box>
          <Box mr={2}>
            <Button  style={{ fontSize: '16px', textTransform: 'none' }}  color="inherit" className="underline-on-hover" onClick={() => navigate('/all-meeting')}>
              Meetings
            </Button>
          </Box>
          <Box mr={2}>
            <Button  style={{ fontSize: '16px', textTransform: 'none' }}  color="inherit" className="underline-on-hover" onClick={() => navigate('/all-call')}>
              Calls
            </Button>
          </Box>
          <Box mr={2}>
            <Button  style={{ fontSize: '16px', textTransform: 'none' }}  color="inherit" className="underline-on-hover" onClick={() => navigate('/all-report')}>
              Reports
            </Button>
          </Box>
          {/* <Box mr={2}>
            <Button color="inherit" className="underline-on-hover">
              Services
            </Button>
          </Box> */}
          <Box mr={2}>
            <Button  style={{ fontSize: '16px', textTransform: 'none' }}  color="inherit" className="underline-on-hover" onClick={() => navigate('/all-project')}>
              Projects
            </Button>
          </Box>
          {/* <Box mr={2}>
            <Button  style={{ fontSize: '16px', textTransform: 'none' }}  color="inherit" className="underline-on-hover" onClick={() => navigate('/all-user')}>
             User
            </Button>
          </Box> */}
                 <Box>
       <IconButton onClick={handleOpenMenu} sx={{ marginTop: '10px', cursor: 'pointer' }} className="more-modules">
          <MoreHorizIcon />
         </IconButton>
         <Popover
           open={Boolean(anchorEl)}
           anchorEl={anchorEl}
           onClose={handleCloseMenu}
           anchorOrigin={{
             vertical: 'bottom',
             horizontal: 'right',
           }}
           transformOrigin={{
             vertical: 'top',
             horizontal: 'right',
           }}
         >
           <Box p={2} width={200}>
             <Box mb={1}>
               <InputBase
                 placeholder="Search..."
                 fullWidth
                 value={searchQuery}
                 onChange={(e) => setSearchQuery(e.target.value)}
                 startAdornment={<SearchIcon sx={{ mr: 1 }} />}
               />
             </Box>
             <List component="nav">
               {filteredItems.map((item, index) => (
                 <ListItem button key={index} onClick={handleCloseMenu}>
                   <ListItemText primary={item} />
                 </ListItem>
               ))}
             </List>
           </Box>
         </Popover>
       </Box>
             </Box>
      )}

      <Box>
        <Typography variant="body1" component="div">
          <span className='enterprise'>Enterprise-Trial</span>
          <br />
          <span style={{ cursor: 'pointer', textDecoration: 'underline', color: '#007bff', marginLeft: '10px' }}>UPGRADE</span>
        </Typography>
      </Box>

      <Box>
        <IconButton onClick={handleOpenMenu1} sx={{ marginTop: '-8px', cursor: 'pointer' }}>
          <AddBoxIcon style={{ fontSize: 40, cursor: 'pointer' }} className='addIcon'/>
        </IconButton>
        <Popover
          open={Boolean(addAnchorEl)}
          anchorEl={addAnchorEl}
          onClose={handleCloseMenu1}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Box p={2} width={200}>
            <Box mb={1}>
              <InputBase
                placeholder="Search..."
                fullWidth
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                startAdornment={<SearchIcon sx={{ mr: 1 }} />}
              />
            </Box>
            <List component="nav">
              {filteredItems1.map((item, index) => (
                <ListItem button key={index} onClick={() => handleListItemClick(item)}>
                  <ListItemText primary={item} />
                </ListItem>
              ))}
            </List>
          </Box>
        </Popover>
      </Box>
      
      {/* SEARCH BAR */}
      <Box display="flex" alignItems="center">
        <Box ref={searchRef}>
          <IconButton onClick={toggleSearch} sx={{ p: 1 }}>
            <SearchIcon />
          </IconButton>
          {searchOpen && (
            <Box
              display="flex"
              backgroundColor={colors.primary[400]}
              borderRadius="3px"
              alignItems="center"
              position="absolute"
              top="25px" // Adjust as needed
              left="75%"
              zIndex={1000}
            >
              <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
              <IconButton onClick={toggleSearch} sx={{ p: 1 }}>
                <SearchIcon />
              </IconButton>
            </Box>
          )}
        </Box>
      </Box>

      {/* ICONS */}
      <Box display="flex">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === 'light' ? (
            <LightModeOutlinedIcon />
          ) : (
            <DarkModeOutlinedIcon />
          )}
        </IconButton>
        <IconButton onClick={openModal}>
          <Badge badgeContent={notifications.length} color="error" variant="dot">
            <NotificationsOutlinedIcon />
          </Badge>
        </IconButton>
        <IconButton onClick={handleLogout}>
          <PersonOutlinedIcon />
        </IconButton>
      </Box>

      {/* MODAL */}
      <Modal open={modalOpen} onClose={closeModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            width: 500,
            borderRadius: 3,
          }}
        >
          <IconButton
            edge="end"
            color="inherit"
            onClick={closeModal}
            sx={{ position: 'absolute', top: 0, right: 5 }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h5" mb={2}>
            Reminders
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <List>
            {notifications.map((notification, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={`${notification.ownername}, Your ${notification.sourcetable} expiry date is on: ${new Date(
                    notification.expiredate
                  ).toDateString()}`}
                />
              </ListItem>
            ))}
          </List>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" color="primary" onClick={handleAction}>
              Take Action
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default Topbar;



























// import React, { useContext, useState, useRef, useEffect } from 'react';
// import {
//   Box,
//   Badge,
//   Popover,
//   IconButton,
//   Typography,
//   Button,
//   Modal,
//   List,
//   ListItem,
//   ListItemText,
//   Divider,
//   InputBase,
// } from '@mui/material';
// import { useTheme, useMediaQuery } from '@mui/material';
// import { ColorModeContext, tokens } from "../../theme";
// import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
// import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
// import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
// import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
// import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
// import SearchIcon from '@mui/icons-material/Search';
// import CloseIcon from '@mui/icons-material/Close';
// import AddBoxIcon from '@mui/icons-material/AddBox';
// import axios from '../../utils/axios';
// import { useNavigate } from 'react-router-dom';
// import { useUser } from '../../contexts/auth'; // Assuming you have a context for user authentication
// import logo from '../../assets/images/logo1.svg';
// import '../../App.css';
// // import useMediaQuery from '@mui/material/useMediaQuery';

// const Topbar = () => {
//   const theme = useTheme();
//   const colorMode = useContext(ColorModeContext);
//   const colors = tokens(theme.palette.mode);
//   const [modalOpen, setModalOpen] = useState(false);
//   const [notifications, setNotifications] = useState([]);
//   const [newNotifications, setNewNotifications] = useState(false);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [addAnchorEl, setAddAnchorEl] = useState(null);
//   const [searchOpen, setSearchOpen] = useState(false);
//   const searchRef = useRef(null);
//   const navigate = useNavigate();
//   const [searchQuery, setSearchQuery] = useState('');
//   const { user } = useUser(); // Assuming useUser provides information about the current user
//   const isMobile = useMediaQuery('(max-width:500px)');
//   const isMobile1 = useMediaQuery('()');
//   const isSmallScreen = useMediaQuery('(max-width:1200px)');


//   useEffect(() => {
//     if (user) {
//       fetchNotifications();
//     }
//   }, [user]);


//   const handleOpenMenu = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleCloseMenu = () => {
//     setAnchorEl(null);
//   };

//   const handleOpenMenu1 = (event) => {
//     setAddAnchorEl(event.currentTarget);
//   };

//   const handleCloseMenu1 = () => {
//     setAddAnchorEl(null);
//   };

//   // Close search field when clicking outside of it
//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (searchRef.current && !searchRef.current.contains(event.target)) {
//         setSearchOpen(false);
//       }
//     };

//     document.addEventListener('mousedown', handleClickOutside);
//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, []);

//   const handleAction = () => {
//     closeModal();
//   };

//   const toggleSearch = () => {
//     setSearchOpen(!searchOpen);
//   };

//   const openModal = () => {
//     setModalOpen(true);
//   };

//   const closeModal = () => {
//     setModalOpen(false);
//   };

//   const fetchNotifications = async () => {
//     try {
//       const response = await axios.get('/notifications');

//       if (!response.data) {
//         throw new Error('No data received');
//       }

//       const data = response.data;
//       setNotifications(data.notifications);
//       setNewNotifications(
//         data.notifications.some((notification) => isNewNotification(notification))
//       );
//     } catch (error) {
//       console.error('Error fetching notifications:', error);
//     }
//   };

//   const isNewNotification = (notification) => {
//     const notificationDate = new Date(notification.expiredate);
//     const currentDate = new Date();
//     const oneDayInMilliseconds = 24 * 60 * 60 * 1000;

//     return notificationDate.getTime() - currentDate.getTime() < oneDayInMilliseconds;
//   };

//   useEffect(() => {
//     fetchNotifications();
//   }, []);

//   const handleLogout = async () => {
//     const confirmed = window.confirm('Do you want to log out?');
//     if (confirmed) {
//       try {
//         const response = await axios.post('/logout');

//         if (response.status === 200) {
//           localStorage.removeItem('jwtToken');
//           window.location.reload();
//           navigate('/login'); // Navigate to '/login' after logout
//         }
//       } catch (error) {
//         if (error.response.status === 403) {
//           alert('Token Expired. Please login again.');
//           localStorage.removeItem('jwtToken');
//           window.location.reload();
//           navigate('/login'); // Navigate to '/login' if token expired
//         } else if (error.response.status === 401) {
//           alert('Invalid token. Please login again.');
//           localStorage.removeItem('jwtToken');
//           window.location.reload();
//           navigate('/login'); // Navigate to '/login' if invalid token
//         } else {
//           alert('Error: ' + error.response.data.error);
//         }
//       }
//     }
//   };

//   // Main items list (hard-coded)
//   // const mainItems = [
//   //   'Demo',
//   // ];
//   const mainItems = [
//     'Home', 'Leads', 'Contacts', 'Accounts', 'Deals',
//     'Tasks', 'Meetings', 'Calls', 'Reports', 'Projects'
//   ];

//   // Items to always show in the top bar
//   const alwaysVisibleItems = ['Home', 'Leads', 'Contacts', 'Accounts', 'Deals'];

//   // Filter items for the "More" dropdown based on screen size
//   const moreItems = isSmallScreen 
//     ? mainItems.filter(item => !alwaysVisibleItems.includes(item))
//     : ['Demo']; // Original "More" items



//   const mainItems1 = [
//     'Home', 'Leads', 'Contacts', 'Accounts', 'Deals',
//     'Tasks', 'Meetings', 'Calls', 'Reports', 'Services', 'Projects'
//   ];

//   const handleListItemClick = (item) => {
//     handleCloseMenu1(); // Close the popover after clicking on an item
//     switch (item) {
//       case 'Home':
//         navigate('/dashboard');
//         break;
//       case 'Leads':
//         navigate('/all-lead');
//         break;
//       case 'Contacts':
//         navigate('/all-contact');
//         break;
//       case 'Accounts':
//         navigate('/all-account');
//         break;
//       case 'Deals':
//         navigate('/all-deal');
//         break;
//       case 'Tasks':
//         navigate('/all-task');
//         break;
//       case 'Meetings':
//         navigate('/all-meeting');
//         break;
//       case 'Calls':
//         navigate('/all-call');
//         break;
//       case 'Reports':
//         navigate('/all-report');
//         break;
//       // case 'Services':
//       //   // Handle navigation for Services
//       //   break;
//       case 'Projects':
//         navigate('/all-project');
//         break;
//       default:
//         break;
//     }
//   };

//   const filteredItems1 = mainItems1.filter(item =>
//     item.toLowerCase().includes(searchQuery.toLowerCase())
//   );

//   // Filter items based on search query
//   const filteredItems = mainItems.filter(item =>
//     item.toLowerCase().includes(searchQuery.toLowerCase())
//   );

//   return (
//     <Box display="flex" justifyContent="space-between" p={2} position="fixed" width="100%" bgcolor="rebeccapurple" zIndex="1000">
//     <Box display="flex" alignItems="center">
//       <Box display="flex" alignItems="center">
//         <span style={{ marginTop: '10px' }}>
//           <img className='imageMobile' src={logo} alt="Logo" width="30" height="30" />
//         </span>
        
//         <Typography variant="h5" color={colors.grey[100]} sx={{ ml: 1, }}  className='websiteName' >
//           ContactTab.com
//         </Typography>
//       </Box>

//       {/* NAVIGATION LINKS */}
//       {!isMobile && (
//         <Box display="flex" alignItems="center">
//   <Box mr={2}>
//     <Button 
//       style={{ fontSize: '16px', textTransform: 'none' }} 
//       color="inherit" 
//       className="underline-on-hover" 
//       onClick={() => navigate('/dashboard')}
//     >
//       Home
//     </Button>
//   </Box>
//           <Box mr={2}>
//             <Button  style={{ fontSize: '16px', textTransform: 'none' }}  color="inherit" className="underline-on-hover" onClick={() => navigate('/all-lead')}>
//               Leads
//             </Button>
//           </Box>
//           <Box mr={2}>
//             <Button  style={{ fontSize: '16px', textTransform: 'none' }}  color="inherit" className="underline-on-hover" onClick={() => navigate('/all-contact')}>
//               Contacts
//             </Button>
//           </Box>
//           <Box mr={2}>
//             <Button  style={{ fontSize: '16px', textTransform: 'none' }}  color="inherit" className="underline-on-hover" onClick={() => navigate('/all-account')}>
//               Accounts
//             </Button>
//           </Box>
//           <Box mr={2}>
//             <Button style={{ fontSize: '16px', textTransform: 'none' }} color="inherit" className="underline-on-hover" onClick={() => navigate('/all-deal')}>
//               Deals
//             </Button>
//           </Box>
//           <Box mr={2}>
//             <Button  style={{ fontSize: '16px', textTransform: 'none' }}  color="inherit" className="underline-on-hover" onClick={() => navigate('/all-task')}>
//               Tasks
//             </Button>
//           </Box>
//           <Box mr={2}>
//             <Button  style={{ fontSize: '16px', textTransform: 'none' }}  color="inherit" className="underline-on-hover" onClick={() => navigate('/all-meeting')}>
//               Meetings
//             </Button>
//           </Box>
//           <Box mr={2}>
//             <Button  style={{ fontSize: '16px', textTransform: 'none' }}  color="inherit" className="underline-on-hover" onClick={() => navigate('/all-call')}>
//               Calls
//             </Button>
//           </Box>
//           <Box mr={2}>
//             <Button  style={{ fontSize: '16px', textTransform: 'none' }}  color="inherit" className="underline-on-hover" onClick={() => navigate('/all-report')}>
//               Reports
//             </Button>
//           </Box>
       
//           <Box mr={2}>
//             <Button  style={{ fontSize: '16px', textTransform: 'none' }}  color="inherit" className="underline-on-hover" onClick={() => navigate('/all-project')}>
//               Projects
//             </Button>
//           </Box>
//                  <Box>
//        <IconButton onClick={handleOpenMenu} sx={{ marginTop: '10px', cursor: 'pointer' }} className="more-modules">
//           <MoreHorizIcon />
//          </IconButton>
//          <Popover
//            open={Boolean(anchorEl)}
//            anchorEl={anchorEl}
//            onClose={handleCloseMenu}
//            anchorOrigin={{
//              vertical: 'bottom',
//              horizontal: 'right',
//            }}
//            transformOrigin={{
//              vertical: 'top',
//              horizontal: 'right',
//            }}
//          >
//            <Box p={2} width={200}>
//              <Box mb={1}>
//                <InputBase
//                  placeholder="Search..."
//                  fullWidth
//                  value={searchQuery}
//                  onChange={(e) => setSearchQuery(e.target.value)}
//                  startAdornment={<SearchIcon sx={{ mr: 1 }} />}
//                />
//              </Box>
//              <List component="nav">
//                {filteredItems.map((item, index) => (
//                  <ListItem button key={index} onClick={handleCloseMenu}>
//                    <ListItemText primary={item} />
//                  </ListItem>
//                ))}
//              </List>
//            </Box>
//          </Popover>
//        </Box>
//              </Box>
//       )}

// </Box>


//       <Box display="flex" alignItems="center">
//       <Box>
//         <Typography variant="body1" component="div">
//           <span className='enterprise'>Enterprise-Trial</span>
//           <br />
//           <span style={{ cursor: 'pointer', textDecoration: 'underline', color: '#007bff', marginLeft: '10px' }}>UPGRADE</span>
//         </Typography>
//       </Box>

//       <Box>
//         <IconButton onClick={handleOpenMenu1} sx={{ marginTop: '-8px', cursor: 'pointer' }}>
//           <AddBoxIcon style={{ fontSize: 40, cursor: 'pointer' }} className='addIcon'/>
//         </IconButton>
//         <Popover
//           open={Boolean(addAnchorEl)}
//           anchorEl={addAnchorEl}
//           onClose={handleCloseMenu1}
//           anchorOrigin={{
//             vertical: 'bottom',
//             horizontal: 'right',
//           }}
//           transformOrigin={{
//             vertical: 'top',
//             horizontal: 'right',
//           }}
//         >
//           <Box p={2} width={200}>
//             <Box mb={1}>
//               <InputBase
//                 placeholder="Search..."
//                 fullWidth
//                 value={searchQuery}
//                 onChange={(e) => setSearchQuery(e.target.value)}
//                 startAdornment={<SearchIcon sx={{ mr: 1 }} />}
//               />
//             </Box>
//             <List component="nav">
//               {filteredItems1.map((item, index) => (
//                 <ListItem button key={index} onClick={() => handleListItemClick(item)}>
//                   <ListItemText primary={item} />
//                 </ListItem>
//               ))}
//             </List>
//           </Box>
//         </Popover>
//       </Box>
      
//       {/* SEARCH BAR */}
//       <Box display="flex" alignItems="center">
//         <Box ref={searchRef}>
//           <IconButton onClick={toggleSearch} sx={{ p: 1 }}>
//             <SearchIcon />
//           </IconButton>
//           {searchOpen && (
//             <Box
//               display="flex"
//               backgroundColor={colors.primary[400]}
//               borderRadius="3px"
//               alignItems="center"
//               position="absolute"
//               top="25px" // Adjust as needed
//               left="75%"
//               zIndex={1000}
//             >
//               <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
//               <IconButton onClick={toggleSearch} sx={{ p: 1 }}>
//                 <SearchIcon />
//               </IconButton>
//             </Box>
//           )}
//         </Box>
//       </Box>

//       {/* ICONS */}
//       <Box display="flex">
//         <IconButton onClick={colorMode.toggleColorMode}>
//           {theme.palette.mode === 'light' ? (
//             <LightModeOutlinedIcon />
//           ) : (
//             <DarkModeOutlinedIcon />
//           )}
//         </IconButton>
//         <IconButton onClick={openModal}>
//           <Badge badgeContent={notifications.length} color="error" variant="dot">
//             <NotificationsOutlinedIcon />
//           </Badge>
//         </IconButton>
//         <IconButton onClick={handleLogout}>
//           <PersonOutlinedIcon />
//         </IconButton>
//       </Box>
//       </Box>

//       {/* MODAL */}
//       <Modal open={modalOpen} onClose={closeModal}>
//         <Box
//           sx={{
//             position: 'absolute',
//             top: '50%',
//             left: '50%',
//             transform: 'translate(-50%, -50%)',
//             bgcolor: 'background.paper',
//             boxShadow: 24,
//             p: 4,
//             width: 500,
//             borderRadius: 3,
//           }}
//         >
//           <IconButton
//             edge="end"
//             color="inherit"
//             onClick={closeModal}
//             sx={{ position: 'absolute', top: 0, right: 5 }}
//           >
//             <CloseIcon />
//           </IconButton>
//           <Typography variant="h5" mb={2}>
//             Reminders
//           </Typography>
//           <Divider sx={{ mb: 2 }} />
//           <List>
//             {notifications.map((notification, index) => (
//               <ListItem key={index}>
//                 <ListItemText
//                   primary={`${notification.ownername}, Your ${notification.sourcetable} expiry date is on: ${new Date(
//                     notification.expiredate
//                   ).toDateString()}`}
//                 />
//               </ListItem>
//             ))}
//           </List>
//           <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
//             <Button variant="contained" color="primary" onClick={handleAction}>
//               Take Action
//             </Button>
//           </Box>
//         </Box>
//       </Modal>
//     </Box>
//   );
// };

// export default Topbar;
