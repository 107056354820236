import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet-async';
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "../../utils/axios";
import {
  TextField,
  Button,
  Grid,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  styled,
} from "@mui/material";
import { useUser } from "../../contexts/auth";

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: 'white',
      color: 'white'
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
}));

const CustomSelect = styled(Select)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
}));

const CreateAccount = () => {
  const {
    userrole,
    username,
    userid,
    companycode,
    isAuthRole,
    isAuthorized,
    token,
  } = useUser();
  const [companyCodes, setCompanyCodes] = useState([]);
  const [selectedFileNamePan, setSelectedFileNamePan] = useState("");
  const initialValues ={
    accountOwner: "",
    accountName: "",
    accountSite: "",
    parentAccount: "",
    accountNumber: "",
    accountType: "",
    industry: "",
    annualRevenue: "",
    rating: "",
    phone: "",
    fax: "",
    website: "",
    tickerSymbol: "",
    ownership: "",
    employees: "",
    sicCode: "",
    billingStreet: "",
    billingCity: "",
    billingState: "",
    billingCode: "",
    billingCountry: "",
    shippingStreet: "",
    shippingCity: "",
    shippingState: "",
    shippingCode: "",
    shippingCountry: "",
    description: "",
    companyCodes:
      userrole === "Superadmin" && companycode === 1 ? "" : companycode, // Initialize company code
  }
  const [accountImage, setAccountImage] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const validationSchema = yup.object({
    accountName: yup.string().required("Account Name is required"),
  });
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema:validationSchema,
    onSubmit: async () => {
      try {
        if (userrole !== "Superadmin" || companycode !== 1) {
          values.companyCodes = companycode;
        }
        const formDataToSend = new FormData();

        formDataToSend.append("formData", JSON.stringify(values));
        formDataToSend.append("accountImage",accountImage);
        console.log(JSON.stringify(values));
        const response = await axios.post("/addaccount", formDataToSend);
        if (response.status === 200) {
          // Update state with success message
          setSuccessMessage("contact data added successful");
          setOpenSuccess(true);
          // Hide the success message after 3 seconds (adjust duration as needed)
          setTimeout(() => {
            setOpenSuccess(false);
            setSuccessMessage("");
          }, 3000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);

        // Handle specific error cases
        if (error.response) {
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
        }
      }
    },
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/getCompanyRegistrations");
        console.log(response.data);
        const formattedCodes = response.data.map(
          (company) => company.establishmentid
        );
        setCompanyCodes(formattedCodes);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  const handleImageChange = (event) => {
    const fileName = event.target.files[0].name;
    setSelectedFileNamePan(fileName);
    const selectedFiles = event.target.files[0];
    if (selectedFiles) {
      // Set the file in the state
      setAccountImage(selectedFiles);
      handleChange({
        target: {
          name: "accountimage",
          value: selectedFiles,
          size: selectedFiles.size,
        },
      });
    }
  };
  // const handleImageChange = (event) => {
  //   if (event.target.files && event.target.files[0]) {
  //     setAccountImage(URL.createObjectURL(event.target.files[0]));
  //   }
  // };

  return (
    <>
  <Helmet>
      <title>Create Account - Contacttab.com</title>
    </Helmet>
    <form onSubmit={handleSubmit}>
      <Box m="20px">
        <h1>Create Account</h1>

        <h2>Account Image</h2>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <input
            accept="image/*"
            id="account-image-upload"
            name="accountimage"
            type="file"
            style={{ display: 'none' }}
            onChange={handleImageChange}
          />
          <label htmlFor="account-image-upload">
            <Button
              variant="contained"
              component="span"
            >
              Upload Image
            </Button>
          </label>
          {accountImage && (
            <Box sx={{ ml: 2 }}>
              {/* <img src={accountImage} alt="Account" style={{ width: 100, height: 100, objectFit: 'cover' }} /> */}
              {selectedFileNamePan && (
                        <div>Selected file: {selectedFileNamePan}</div>
                      )}
            </Box>
          )}
        </Box>

        <h2>Account Information</h2>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Account Owner</InputLabel>
              <CustomSelect
                name="accountOwner"
                value={values.accountOwner}
                onChange={handleChange}
              >
                <MenuItem value="Vivek Kumar Yadav">Vivek Kumar Yadav</MenuItem>
              </CustomSelect>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Rating</InputLabel>
              <CustomSelect
                name="rating"
                value={values.rating}
                onChange={handleChange}
              >
                <MenuItem value="-None-">-None-</MenuItem>
              </CustomSelect>
            </FormControl>
          </Grid>
          {userrole === "Superadmin" && companycode === 1 ? (
  <Grid item xs={6}>
    <FormControl
      fullWidth
    >
      <InputLabel>Company Code</InputLabel>
      <CustomSelect
        labelId="companyCode-label"
        id="companyCodes"
        onChange={handleChange}
        value={values.companyCodes}
        name="companyCodes"
        error={touched.companyCodes && !!errors.companyCodes}
        helperText={
          touched.companyCodes && errors.companyCodes
            ? errors.companyCodes
            : ""
        }
        label="Company Code"
        MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
      >
        <MenuItem value="">Select Company Code</MenuItem>
        {companyCodes.map((code, index) => (
          <MenuItem key={index} value={code}>
            {code}
          </MenuItem>
        ))}
      </CustomSelect>
    </FormControl>
  </Grid>
) : (
  <Grid item xs={6}>
    <TextField
      fullWidth
      variant="filled"
      type="text"
      label="Company Code"
      value={companycode}
      name="companyCodes"
      sx={{ gridColumn: "span 2" }}
    />
  </Grid>
)}
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="accountName"
              label="Account Name"
              value={values.accountName}
              onChange={handleChange}
              error={touched.accountName && Boolean(errors.accountName)}
              helperText={touched.accountName && errors.accountName}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="phone"
              label="Phone"
              value={values.phone}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="accountSite"
              label="Account Site"
              value={values.accountSite}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="fax"
              label="Fax"
              value={values.fax}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="parentAccount"
              label="Parent Account"
              value={values.parentAccount}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="website"
              label="Website"
              value={values.website}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="accountNumber"
              label="Account Number"
              value={values.accountNumber}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="tickerSymbol"
              label="Ticker Symbol"
              value={values.tickerSymbol}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Account Type</InputLabel>
              <CustomSelect
                name="accountType"
                value={values.accountType}
                onChange={handleChange}
              >
                <MenuItem value="-None-">-None-</MenuItem>
              </CustomSelect>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Ownership</InputLabel>
              <CustomSelect
                name="ownership"
                value={values.ownership}
                onChange={handleChange}
              >
                <MenuItem value="-None-">-None-</MenuItem>
              </CustomSelect>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Industry</InputLabel>
              <CustomSelect
                name="industry"
                value={values.industry}
                onChange={handleChange}
              >
                <MenuItem value="-None-">-None-</MenuItem>
              </CustomSelect>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="employees"
              label="Employees"
              value={values.employees}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="annualRevenue"
              label="Annual Revenue"
              value={values.annualRevenue}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              fullWidth
              name="sicCode"
              label="SIC Code"
              value={values.sicCode}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <h2>Address Information</h2>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="billingStreet"
              label="Billing Street"
              value={values.billingStreet}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="shippingStreet"
              label="Shipping Street"
              value={values.shippingStreet}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="billingCity"
              label="Billing City"
              value={values.billingCity}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="shippingCity"
              label="Shipping City"
              value={values.shippingCity}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="billingState"
              label="Billing State"
              value={values.billingState}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="shippingState"
              label="Shipping State"
              value={values.shippingState}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="billingCode"
              label="Billing Code"
              value={values.billingCode}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="shippingCode"
              label="Shipping Code"
              value={values.shippingCode}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="billingCountry"
              label="Billing Country"
              value={values.billingCountry}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="shippingCountry"
              label="Shipping Country"
              value={values.shippingCountry}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <h2>Description Information</h2>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomTextField
              fullWidth
              name="description"
              label="Description"
              multiline
              rows={4}
              value={values.description}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button type="button" onClick={() => resetForm()} variant="contained" style={{ margin: '20px', background: 'blue', padding: '5px 10px' }}>
            Cancel
          </Button>
          {/* <Button type="button" variant="contained" style={{ margin: '20px', background: 'blue', padding: '5px 10px' }}>
            Save and New
          </Button> */}
          <Button type="submit" variant="contained" style={{ margin: '20px', background: 'blue', padding: '5px 10px' }}>
            Save
          </Button>
        </Box>
      </Box>
    </form>
   <br/>
    </>
  );
};

export default CreateAccount;