import React, { useState, useEffect } from "react";
import { Box, Typography, IconButton, InputLabel, FormControlLabel, Checkbox, Select, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Paper, Button, Grid, Menu, MenuItem, FormControl } from '@mui/material';
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import { Helmet } from 'react-helmet-async';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "../../../utils/axios";
import { useNavigate } from "react-router-dom";
import Header from "../../../assets/charts/Header";
import { useUser } from "../../../contexts/auth";
import { useTheme } from "@mui/material/styles";
import '../../../App.css'
import ViewListIcon from '@mui/icons-material/ViewList'; // Import the List View icon
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import GridViewIcon from '@mui/icons-material/GridView';
import TableViewIcon from '@mui/icons-material/TableView';
import TocIcon from '@mui/icons-material/Toc';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';


const AllLead = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [leads, setLeads] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteLeadId, setDeleteLeadId] = useState(null);
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState(null);
  const { userrole, companycode } = useUser();
  const [selectedView, setSelectedView] = useState('All Leads');
  const [viewAnchorEl, setViewAnchorEl] = useState(null);
  const [systemFiltersVisible, setSystemFiltersVisible] = useState(true);
  const [fieldFiltersVisible, setFieldFiltersVisible] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [nameFilter, setNameFilter] = useState('All');

  const [systemDefinedFilters, setSystemDefinedFilters] = useState({
    touchedRecords: { enabled: false, by: 'User & System', timeRange: '2 days' },
    untouchedRecords: { enabled: false, by: 'User & System', timeRange: '2 days' },
    recordAction: { enabled: false, actionType: 'Modified', by: 'User & System', timeRange: '2 days' },
    relatedRecordsAction: { enabled: false, actionType: 'Done', by: 'User & System', timeRange: '2 days' },
    latestEmailStatus: { enabled: false, status: 'sent', timeRange: '2 days', isBounced: false },
    activities: { enabled: false, type: 'Activity Due', overdueOption: 'Today + Overdue' },
    notes: { enabled: false, withoutNotes: true, timeRange: '2 days' },
    campaigns: { enabled: false, type: '', status: '' }
  });

  const fieldFilters = [
    "Annual Revenue",
    "City",
    "Company",
    "Converted Account",
    "Converted Contact",
    "Converted Deal",
    "Country",
    "Created By",
    "Created Time",
    "Lead Email",
    "Email Opt Out",
    "Fax",
    "First Name",
    "Industry",
    "Last Activity Time",
    "Last Name",
    "Lead Conversion Time",
    "Lead Name",
    "Lead Owner",
    "Lead Source",
    "Lead Status",
    "Mobile",
    "Modified By",
    "Modified Time",
    "No. of Employees",
    "Phone",
    "Rating",
    "Salutation",
    "Secondary Email",
    "Skype ID",
    "State",
    "Street",
    "Tag",
    "Title",
    "Twitter",
    "Unsubscribed Mode",
    "Unsubscribed Time",
    "Website",
    "Zip Code"
  ];

  const toggleSystemFilters = () => {
    setSystemFiltersVisible(!systemFiltersVisible);
  };

  const toggleFieldFilters = () => {
    setFieldFiltersVisible(!fieldFiltersVisible);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  // const filteredSystemDefinedFilters = systemDefinedFilters.filter((filter) =>
  //   filter.toLowerCase().includes(searchTerm)
  // );

  const filteredFieldFilters = fieldFilters.filter((filter) =>
    filter.toLowerCase().includes(searchTerm)
  );

  const handleViewMenuOpen = (event) => {
    setViewAnchorEl(event.currentTarget);
  };

  const handleChange = (event) => {
    setSelectedView(event.target.value);
  };

  const handleViewMenuClose = () => {
    setViewAnchorEl(null);
  };

  const viewMenuOpen = Boolean(viewAnchorEl);

  const handleNameFilterChange = (event) => {
    setNameFilter(event.target.value);
  };

  const fetchData = async () => {
    try {
      if (companycode !== undefined && userrole !== undefined) {
        const response = await axios.get("/getLeads", {
          params: {
            companycode: companycode,
            userrole: userrole,
          },
        });
        if (response && response.data) {
          setLeads(response.data);
        }
      }
    } catch (error) {
      console.error("Error fetching leads:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [companycode, userrole]);

  const [filteredLeads, setFilteredLeads] = useState(leads);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setFilteredLeads(
      leads.filter(lead => {
        if (nameFilter === 'All') return true;
        return lead.leadname && lead.leadname.charAt(0).toUpperCase() === nameFilter;
      })
    );
  }, [nameFilter, leads]);

  const columns = [
    { field: "id", headerName: "ID", flex: 0.3 },
    {
      field: "leadname",
      headerName: "Lead Name",
      flex: 0.3,
      // renderHeader: (params) => (
      //   <Box sx={{ display: 'flex', alignItems: 'center' }}>
      //     <Typography>{params.colDef.headerName}</Typography>
      //     <Select
      //       value={nameFilter}
      //       onChange={handleNameFilterChange}
      //       sx={{ ml: 1, height: 30 }}
      //     >
      //       <MenuItem value="All">All</MenuItem>
      //       {Array.from(Array(26)).map((_, i) => (
      //         <MenuItem key={i} value={String.fromCharCode(65 + i)}>
      //           {String.fromCharCode(65 + i)}
      //         </MenuItem>
      //       ))}
      //     </Select>
      //   </Box>
      // ),
      // renderCell: (params) => (
      //   <Typography
      //     sx={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
      //     onClick={() => navigate(`/leads/${params.row.id}`)}
      //   >
      //     {params.value}
      //   </Typography>
      // ),
    },
    { field: "leademail", headerName: "Email", flex: 0.3 },
    { field: "contactno", headerName: "Contact Number", flex: 0.3 },
    { field: "leadsource", headerName: "Source", flex: 0.3 },
    { field: "leadcomments", headerName: "Comments", flex: 0.3 },
    {
      field: "leadstate",
      headerName: "Status",
      flex: 0.3,
      renderCell: (params) => <span>{params.row.leadstate}</span>,
    },
    { field: "companycode", headerName: "Company Code", flex: 0.3 },
    {
      field: "leadStatus",
      headerName: "Lead State",
      flex: 0.3,
      renderCell: (params) => (
        <Select
          className="select-menu"
          value={params.row.leadType}
          onChange={(event) => handleStatusChange(event, params.row.id)}
        >
          <MenuItem value="Pitched">Pitched</MenuItem>
          <MenuItem value="Negotiation">Negotiation</MenuItem>
          <MenuItem value="Demo">Demo</MenuItem>
          <MenuItem value="Closed Lost">Closed Lost</MenuItem>
          <MenuItem value="Closed Won">Closed Won</MenuItem>
          <MenuItem value="RNR">RNR</MenuItem>
          <MenuItem value="Junk">Junk</MenuItem>
        </Select>
      ),
    },
    {
      headerName: "Actions",
      flex: 0.3,
      renderCell: (props) => (
        <>
          <EditIcon
            style={{ marginRight: "10px" }}
            onClick={() => handleEdit(props.row.id)}
          />
          <DeleteIcon onClick={() => handleDelete(props.row.id)} />
        </>
      ),
    },
  ];

  const handleStatusChange = async (event, lead) => {
    try {
      const updatedLeads = leads.map((leadItem) =>
        leadItem.id === lead ? { ...leadItem, leadstatus: event.target.value } : leadItem
      );
      setLeads(updatedLeads);

      const response = await axios.put(
        `/updateLeadState/${lead}`,
        {
          leadstatus: event.target.value,
        }
      );

      if (response && response.status === 200) {
        fetchData();
        setDeleteSuccessMessage("Lead status updated successfully!");
      } else {
        console.error("Failed to update lead status");
      }
    } catch (error) {
      console.error("Error updating lead status:", error);
    }
  };

  const handleEdit = (lead) => {
    navigate(`/updatelead/${lead}`);
  };

  const handleDelete = (leadId) => {
    setDeleteLeadId(leadId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete(`/deleteLead/${deleteLeadId}`);
      if (response && response.status === 200) {
        setLeads((prevLeads) =>
          prevLeads.filter((lead) => lead.id !== deleteLeadId)
        );
        setDeleteSuccessMessage("Lead deleted successfully!");
        setTimeout(() => {
          setDeleteSuccessMessage(null);
        }, 3000);
      } else {
        console.error("Failed to delete lead");
      }
    } catch (error) {
      console.error("Error deleting lead:", error);
    }
    setOpenDialog(false);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const menuOpen = Boolean(anchorEl);
  const [selectedSystemFilters, setSelectedSystemFilters] = useState([]);
  const [selectedFieldFilters, setSelectedFieldFilters] = useState([]);
  // Handle system filter checkbox selection
  const handleSystemFilterChange = (filterName, configKey, value) => {
    setSystemDefinedFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: {
        ...prevFilters[filterName],
        [configKey]: value
      }
    }));
  };
  const [fieldSearchTerms, setFieldSearchTerms] = useState({});

  const handleFieldFilterChange = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      setSelectedFieldFilters((prev) => [...prev, name]);
      setFieldSearchTerms((prev) => ({ ...prev, [name]: "" }));
    } else {
      setSelectedFieldFilters((prev) => prev.filter((filter) => filter !== name));
      setFieldSearchTerms((prev) => {
        const { [name]: removed, ...rest } = prev;
        return rest;
      });
    }
  };

  const handleFieldSearchChange = (event, filterName) => {
    setFieldSearchTerms((prev) => ({
      ...prev,
      [filterName]: event.target.value
    }));
  };

  useEffect(() => {
    let updatedLeads = leads;

    // // Apply system filters
    // if (selectedSystemFilters.length > 0) {
    //   updatedLeads = updatedLeads.filter((lead) => {
    //     // Add your system-defined filtering logic here.
    //     // For demonstration, I'm just filtering by a simple condition.
    //     // You'll need to customize this based on the system filters.
    //     return selectedSystemFilters.every((filter) =>
    //       lead[filter.toLowerCase().replace(/\s+/g, '')]
    //     );
    //   });
    // }
    // Apply Touched Records Filter
    if (systemDefinedFilters.touchedRecords.enabled) {
      updatedLeads = updatedLeads.filter((lead) => {
        // Add your filtering logic based on touched records.
        // For demonstration, assuming a `touchedBy` field exists in your lead data.
        // return lead.touchedBy === systemDefinedFilters.touchedRecords.by && 
        //        withinLastDays(lead.touchedDate, systemDefinedFilters.touchedRecords.timeRange);
      });
    }

    // Apply Untouched Records Filter
    if (systemDefinedFilters.untouchedRecords.enabled) {
      updatedLeads = updatedLeads.filter((lead) => {
        // Add your filtering logic for untouched records.
      });
    }
    // Apply Record Action Filter
    if (systemDefinedFilters.recordAction.enabled) {
      updatedLeads = updatedLeads.filter((lead) => {
        // Add your filtering logic based on record action.
      });
    }
    // Apply Related Records Action Filter
    if (systemDefinedFilters.relatedRecordsAction.enabled) {
      updatedLeads = updatedLeads.filter((lead) => {
        // Add logic for related records action
      });
    }

    // Apply Latest Email Status Filter
    if (systemDefinedFilters.latestEmailStatus.enabled) {
      updatedLeads = updatedLeads.filter((lead) => {
        // Add logic for latest email status
      });
    }

    // Apply Activities Filter
    if (systemDefinedFilters.activities.enabled) {
      updatedLeads = updatedLeads.filter((lead) => {
        // Add logic for activities filter
      });
    }

    // Apply Notes Filter
    if (systemDefinedFilters.notes.enabled) {
      updatedLeads = updatedLeads.filter((lead) => {
        // Add logic for notes filter
      });
    }

    // Apply Campaigns Filter
    if (systemDefinedFilters.campaigns.enabled) {
      updatedLeads = updatedLeads.filter((lead) => {
        // Add logic for campaigns filter
      });
    }
    // Apply field filters
    if (selectedFieldFilters.length > 0) {
      updatedLeads = updatedLeads.filter((lead) => {
        return selectedFieldFilters.every((filter) => {
          const field = filter.toLowerCase().replace(/\s+/g, '');
          return fieldSearchTerms[filter] ? lead[field]?.includes(fieldSearchTerms[filter]) : true;
        });
      });
    }

    setFilteredLeads(updatedLeads);
  }, [selectedSystemFilters, selectedFieldFilters, leads, fieldSearchTerms]);
  //   // Apply field filters
  //   if (selectedFieldFilters.length > 0) {
  //     updatedLeads = updatedLeads.filter((lead) => {
  //       // Add your field-based filtering logic here
  //       // Example: if the field filter is "Company", check if `lead.company` exists
  //       return selectedFieldFilters.every((filter) =>
  //         lead[filter.toLowerCase().replace(/\s+/g, '')]
  //       );
  //     });
  //   }

  //   setFilteredLeads(updatedLeads);
  // }, [selectedSystemFilters, selectedFieldFilters, leads]);
  const [style, setStyle] = useState({
    padding: '10px',
    height: '74vh',
    marginTop: '20px',
    overflow: 'auto',
    color: 'black',
  });
  useEffect(() => {
    const updateStyle = () => {
      if (window.innerWidth >= 1201 && window.innerWidth <= 1380) {
        setStyle({
          padding: '10px',
          height: '70vh',
          marginTop: '12px',
          overflow: 'auto',
          color: 'black',
        });
      } else {
        setStyle({
          padding: '10px',
          height: '74vh',
          marginTop: '20px',
          overflow: 'auto',
          color: 'black',
        });
      }
    };

    // Call the function initially and add a listener for resizing
    updateStyle();
    window.addEventListener('resize', updateStyle);

    // Clean up the listener on component unmount
    return () => window.removeEventListener('resize', updateStyle);
  }, []);

  return (
    <Box>
      <Helmet>
        <title>Leads - Contacttab.com</title>
      </Helmet>
      {/* Header Section */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2, backgroundColor: 'white', color: 'black', marginTop: "5.2%", padding: '5px' }}>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small" style={{ color: 'white', background: 'black', borderRadius: '5px' }}>
          <Select
            labelId="lead-select-label"
            value={selectedView}
            onChange={handleChange}
            label="Public Views"
            style={{ color: 'white' }}
          >
            <MenuItem value="All Leads">All Leads</MenuItem>
            <MenuItem value="All Locked Leads">All Locked Leads</MenuItem>
            <MenuItem value="Converted Leads">Converted Leads</MenuItem>
            <MenuItem value="Junk Leads">Junk Leads</MenuItem>
            <MenuItem value="Mailing Labels">Mailing Labels</MenuItem>
            <MenuItem value="My Converted Leads">My Converted Leads</MenuItem>
            <MenuItem value="My Leads">My Leads</MenuItem>
            <MenuItem value="Not Qualified Leads">Not Qualified Leads</MenuItem>
            <MenuItem value="Open Leads">Open Leads</MenuItem>
            <MenuItem value="Recently Created Leads">Recently Created Leads</MenuItem>
            <MenuItem value="Recently Modified Leads">Recently Modified Leads</MenuItem>
            <MenuItem value="Today's Leads">Today's Leads</MenuItem>
            <MenuItem value="Unread Leads">Unread Leads</MenuItem>
          </Select>
        </FormControl>
        <Box>
          <Button variant="contained" onClick={handleViewMenuOpen} startIcon={<ViewListIcon />}>List View</Button> {/* Added List View Button */}
          <Button variant="contained" color="primary" sx={{ mr: 2 }} onClick={() => navigate('/add-lead')}>Create Lead</Button>
          <Button variant="contained" onClick={handleMenuOpen}>Actions</Button>
          <Menu anchorEl={anchorEl} open={menuOpen} onClose={handleMenuClose}>
            <MenuItem onClick={handleMenuClose}>Mass Delete</MenuItem>
            <MenuItem onClick={handleMenuClose}>Mass Update</MenuItem>
            <MenuItem onClick={handleMenuClose}>Mass Convert</MenuItem>
            <MenuItem onClick={handleMenuClose}>Manage Tags</MenuItem>
            <MenuItem onClick={handleMenuClose}>Drafts</MenuItem>
            <MenuItem onClick={handleMenuClose}>Mass Email</MenuItem>
            <MenuItem onClick={handleMenuClose}>Autoresponders</MenuItem>
            <MenuItem onClick={handleMenuClose}>Approve Leads</MenuItem>
            <MenuItem onClick={handleMenuClose}>Deduplicate Leads</MenuItem>
            <MenuItem onClick={handleMenuClose}>Add to Campaigns</MenuItem>
            <MenuItem onClick={handleMenuClose}>Create Client Script</MenuItem>
            <MenuItem onClick={handleMenuClose}>Export Leads</MenuItem>
            <MenuItem onClick={handleMenuClose}>Zoho Sheet View</MenuItem>
            <MenuItem onClick={handleMenuClose}>Print View</MenuItem>
          </Menu>
          <Menu anchorEl={viewAnchorEl} open={viewMenuOpen} onClose={handleViewMenuClose}> {/* Added List View Menu */}
            <MenuItem onClick={handleViewMenuClose}><span style={{ marginRight: '10px', marginLeft: '-5px' }}><TocIcon /></span> List View</MenuItem>
            <MenuItem onClick={handleViewMenuClose}><span style={{ marginRight: '10px', marginLeft: '-5px' }}><ViewKanbanIcon /></span> Kanban View</MenuItem>
            <MenuItem onClick={handleViewMenuClose}><span style={{ marginRight: '10px', marginLeft: '-5px' }}><ViewListIcon /></span> Custom List View</MenuItem>
            <MenuItem onClick={handleViewMenuClose}><span style={{ marginRight: '10px', marginLeft: '-5px' }}><GridViewIcon /></span> Tile View</MenuItem>
            <MenuItem onClick={handleViewMenuClose}><span style={{ marginRight: '10px', marginLeft: '-5px' }}><TableViewIcon /></span> Table View</MenuItem>
          </Menu>
        </Box>
      </Box>

      {/* Main Content */}
      <Grid container columnGap={5}>
        <Grid item xs={2}>
          <Box
            sx={{
              p: 2,
              borderRight: "1px solid #e0e0e0",
              height: "76vh",
              background: "black",
              overflowY: "auto",
              marginTop: "20px"
            }}
          >
            <Typography variant="h6" color="white" style={{ fontSize: "16px" }}>Filter Leads by</Typography>
            <TextField
              variant="outlined"
              placeholder="Search"
              size="small"
              fullWidth
              sx={{ mb: 2 }}
              value={searchTerm}
              onChange={handleSearchChange}
            />

            <Typography
              variant="body2"
              color="white"
              sx={{ mb: 1, cursor: "pointer", fontSize: "16px" }}
              onClick={toggleSystemFilters}
            >
              System Defined Filters <IconButton
                sx={{
                  color: "white",
                  transform: systemFiltersVisible ? 'rotate(90deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s'
                }}
              >
                <ArrowRightIcon />
              </IconButton>
            </Typography>
           {systemFiltersVisible && (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
              {/* Touched Records */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={systemDefinedFilters.touchedRecords.enabled}
                    onChange={() =>
                      handleSystemFilterChange('touchedRecords', 'enabled', !systemDefinedFilters.touchedRecords.enabled)
                    }
                  />
                }
                label="Touched Records"
              />
              {systemDefinedFilters.touchedRecords.enabled && (
                <>
                  <Select
                    value={systemDefinedFilters.touchedRecords.by}
                    onChange={(event) => handleSystemFilterChange('touchedRecords', 'by', event.target.value)}
                  >
                    <MenuItem value="User & System">User & System</MenuItem>
                    <MenuItem value="User Only">User Only</MenuItem>
                    <MenuItem value="System Only">System Only</MenuItem>
                  </Select>
                  <Select
                    value={systemDefinedFilters.touchedRecords.timeRange}
                    onChange={(event) => handleSystemFilterChange('touchedRecords', 'timeRange', event.target.value)}
                  >
                    <MenuItem value="2 days">in the last 2 days</MenuItem>
                    <MenuItem value="7 days">in the last 7 days</MenuItem>
                    <MenuItem value="30 days">in the last 30 days</MenuItem>
                  </Select>
                </>
              )}

              {/* Untouched Records */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={systemDefinedFilters.untouchedRecords.enabled}
                    onChange={() =>
                      handleSystemFilterChange('untouchedRecords', 'enabled', !systemDefinedFilters.untouchedRecords.enabled)
                    }
                  />
                }
                label="Untouched Records"
              />
              {systemDefinedFilters.untouchedRecords.enabled && (
                <>
                  <Select
                    value={systemDefinedFilters.untouchedRecords.by}
                    onChange={(event) => handleSystemFilterChange('untouchedRecords', 'by', event.target.value)}
                  >
                    <MenuItem value="User & System">User & System</MenuItem>
                    <MenuItem value="User Only">User Only</MenuItem>
                    <MenuItem value="System Only">System Only</MenuItem>
                  </Select>
                  <Select
                    value={systemDefinedFilters.untouchedRecords.timeRange}
                    onChange={(event) => handleSystemFilterChange('untouchedRecords', 'timeRange', event.target.value)}
                  >
                    <MenuItem value="2 days">in the last 2 days</MenuItem>
                    <MenuItem value="7 days">in the last 7 days</MenuItem>
                    <MenuItem value="30 days">in the last 30 days</MenuItem>
                  </Select>
                </>
              )}

              {/* Record Action */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={systemDefinedFilters.recordAction.enabled}
                    onChange={() =>
                      handleSystemFilterChange('recordAction', 'enabled', !systemDefinedFilters.recordAction.enabled)
                    }
                  />
                }
                label="Record Action"
              />
              {systemDefinedFilters.recordAction.enabled && (
                <>
                  <Select
                    value={systemDefinedFilters.recordAction.actionType}
                    onChange={(event) => handleSystemFilterChange('recordAction', 'actionType', event.target.value)}
                  >
                    <MenuItem value="Modified">Modified</MenuItem>
                    <MenuItem value="Created">Created</MenuItem>
                  </Select>
                  <Select
                    value={systemDefinedFilters.recordAction.by}
                    onChange={(event) => handleSystemFilterChange('recordAction', 'by', event.target.value)}
                  >
                    <MenuItem value="User & System">User & System</MenuItem>
                    <MenuItem value="User Only">User Only</MenuItem>
                    <MenuItem value="System Only">System Only</MenuItem>
                  </Select>
                  <Select
                    value={systemDefinedFilters.recordAction.timeRange}
                    onChange={(event) => handleSystemFilterChange('recordAction', 'timeRange', event.target.value)}
                  >
                    <MenuItem value="2 days">in the last 2 days</MenuItem>
                    <MenuItem value="7 days">in the last 7 days</MenuItem>
                    <MenuItem value="30 days">in the last 30 days</MenuItem>
                  </Select>
                </>
              )}
              {/* Related Records Action Filter */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={systemDefinedFilters.relatedRecordsAction.enabled}
                    onChange={() =>
                      handleSystemFilterChange('relatedRecordsAction', 'enabled', !systemDefinedFilters.relatedRecordsAction.enabled)
                    }
                  />
                }
                label="Related Records Action"
              />
              {systemDefinedFilters.relatedRecordsAction.enabled && (
                <>
                  <Select
                    value={systemDefinedFilters.relatedRecordsAction.actionType}
                    onChange={(event) => handleSystemFilterChange('relatedRecordsAction', 'actionType', event.target.value)}
                  >
                    <MenuItem value="Done">Done</MenuItem>
                    <MenuItem value="Pending">Pending</MenuItem>
                  </Select>
                  <Select
                    value={systemDefinedFilters.relatedRecordsAction.by}
                    onChange={(event) => handleSystemFilterChange('relatedRecordsAction', 'by', event.target.value)}
                  >
                    <MenuItem value="User & System">User & System</MenuItem>
                    <MenuItem value="User Only">User Only</MenuItem>
                  </Select>
                  <Select
                    value={systemDefinedFilters.relatedRecordsAction.timeRange}
                    onChange={(event) => handleSystemFilterChange('relatedRecordsAction', 'timeRange', event.target.value)}
                  >
                    <MenuItem value="2 days">in the last 2 days</MenuItem>
                    <MenuItem value="7 days">in the last 7 days</MenuItem>
                    <MenuItem value="30 days">in the last 30 days</MenuItem>
                  </Select>
                </>
              )}
              {/* Latest Email Status Filter: */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={systemDefinedFilters.latestEmailStatus.enabled}
                    onChange={() =>
                      handleSystemFilterChange('latestEmailStatus', 'enabled', !systemDefinedFilters.latestEmailStatus.enabled)
                    }
                  />
                }
                label="Latest Email Status"
              />
              {systemDefinedFilters.latestEmailStatus.enabled && (
                <>
                  <Select
                    value={systemDefinedFilters.latestEmailStatus.status}
                    onChange={(event) => handleSystemFilterChange('latestEmailStatus', 'status', event.target.value)}
                  >
                    <MenuItem value="sent">Sent</MenuItem>
                    <MenuItem value="opened">Opened</MenuItem>
                    <MenuItem value="clicked">Clicked</MenuItem>
                  </Select>
                  <Select
                    value={systemDefinedFilters.latestEmailStatus.timeRange}
                    onChange={(event) => handleSystemFilterChange('latestEmailStatus', 'timeRange', event.target.value)}
                  >
                    <MenuItem value="2 days">in the last 2 days</MenuItem>
                    <MenuItem value="7 days">in the last 7 days</MenuItem>
                    <MenuItem value="30 days">in the last 30 days</MenuItem>
                  </Select>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={systemDefinedFilters.latestEmailStatus.isBounced}
                        onChange={(event) => handleSystemFilterChange('latestEmailStatus', 'isBounced', event.target.checked)}
                      />
                    }
                    label="Bounced"
                  />
                </>
              )}
              {/* Activities Filter: */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={systemDefinedFilters.activities.enabled}
                    onChange={() =>
                      handleSystemFilterChange('activities', 'enabled', !systemDefinedFilters.activities.enabled)
                    }
                  />
                }
                label="Activities"
              />
              {systemDefinedFilters.activities.enabled && (
                <>
                  <RadioGroup
                    value={systemDefinedFilters.activities.type}
                    onChange={(event) => handleSystemFilterChange('activities', 'type', event.target.value)}
                  >
                    <FormControlLabel value="Without Open Activity" control={<Radio />} label="Without Open Activity" />
                    <FormControlLabel value="Overdue" control={<Radio />} label="Overdue" />
                    <FormControlLabel value="Activity Due" control={<Radio />} label="Activity Due" />
                  </RadioGroup>
                  {systemDefinedFilters.activities.type === 'Activity Due' && (
                    <Select
                      value={systemDefinedFilters.activities.overdueOption}
                      onChange={(event) => handleSystemFilterChange('activities', 'overdueOption', event.target.value)}
                    >
                      <MenuItem value="Today + Overdue">Today + Overdue</MenuItem>
                      <MenuItem value="Tomorrow">Tomorrow</MenuItem>
                    </Select>
                  )}
                </>
              )}
              {/* Notes Filter: */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={systemDefinedFilters.notes.enabled}
                    onChange={() => handleSystemFilterChange('notes', 'enabled', !systemDefinedFilters.notes.enabled)}
                  />
                }
                label="Notes"
              />
              {systemDefinedFilters.notes.enabled && (
                <>
                  <RadioGroup
                    value={systemDefinedFilters.notes.withoutNotes ? 'withoutNotes' : 'withNotes'}
                    onChange={(event) =>
                      handleSystemFilterChange('notes', 'withoutNotes', event.target.value === 'withoutNotes')
                    }
                  >
                    <FormControlLabel value="withoutNotes" control={<Radio />} label="Without Any Notes" />
                    <FormControlLabel value="withNotes" control={<Radio />} label="Notes Added" />
                  </RadioGroup>
                  <Select
                    value={systemDefinedFilters.notes.timeRange}
                    onChange={(event) => handleSystemFilterChange('notes', 'timeRange', event.target.value)}
                  >
                    <MenuItem value="2 days">in the last 2 days</MenuItem>
                    <MenuItem value="7 days">in the last 7 days</MenuItem>
                    <MenuItem value="30 days">in the last 30 days</MenuItem>
                  </Select>
                </>
              )}
              {/* Campaigns Filter: */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={systemDefinedFilters.campaigns.enabled}
                    onChange={() => handleSystemFilterChange('campaigns', 'enabled', !systemDefinedFilters.campaigns.enabled)}
                  />
                }
                label="Campaigns"
              />
              {systemDefinedFilters.campaigns.enabled && (
                <>
                  <Select
                    value={systemDefinedFilters.campaigns.type}
                    onChange={(event) => handleSystemFilterChange('campaigns', 'type', event.target.value)}
                  >
                    <MenuItem value="Campaign Type 1">Campaign Type 1</MenuItem>
                    <MenuItem value="Campaign Type 2">Campaign Type 2</MenuItem>
                  </Select>
                  <Select
                    value={systemDefinedFilters.campaigns.status}
                    onChange={(event) => handleSystemFilterChange('campaigns', 'status', event.target.value)}
                  >
                    <MenuItem value="Active">Active</MenuItem>
                    <MenuItem value="Inactive">Inactive</MenuItem>
                  </Select>
                </>
              )}

            </Box>
         )}
            <Typography
              variant="body2"
              color="white"
              sx={{ mt: 2, mb: 1, cursor: "pointer", fontSize: "16px" }}
              onClick={toggleFieldFilters}
            >
              Filter By Fields <IconButton
                sx={{
                  color: "white",
                  transform: fieldFiltersVisible ? 'rotate(90deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s'
                }}
              >
                <ArrowRightIcon />
              </IconButton>
            </Typography>
            {fieldFiltersVisible && (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
            {fieldFilters.map((filter) => (
              <Box key={filter}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedFieldFilters.includes(filter)}
                      onChange={handleFieldFilterChange}
                      name={filter}
                    />
                  }
                  label={filter}
                />
                {selectedFieldFilters.includes(filter) && (
                  <TextField
                    label={`Search ${filter}`}
                    value={fieldSearchTerms[filter] || ""}
                    onChange={(event) => handleFieldSearchChange(event, filter)}
                  />
                )}
              </Box>
            ))}
            </Box>
            )}
          </Box>
        </Grid>

        <Grid item xs={9.5}>
          <Paper elevation={3} style={style} className="listViewStyle">
            <Typography variant="h6" sx={{ mb: 2 }} style={{ color: 'white' }}>Total Records: {filteredLeads.length}</Typography>
            <DataGrid
              columns={columns}
              rows={filteredLeads}
              pageSize={10}
              rowsPerPageOptions={[10]}
              checkboxSelection
              disableSelectionOnClick
              getRowId={(row) => row.id}
              // autoHeight
              // style={{ overflowX: 'auto' }}
              //sx={{ '& .MuiDataGrid-columnHeaderTitleContainer': { overflow: 'visible' } }}
              //disableColumnMenu
            />

            {/* Confirmation Dialog */}
            <Dialog open={openDialog} onClose={handleCloseDialog}>
              <DialogTitle>Confirm Delete</DialogTitle>
              <DialogContent>Are you sure you want to delete this lead?</DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                  No
                </Button>
                <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AllLead;
