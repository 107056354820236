import React, { useState, useEffect } from "react";
import { Box, Typography,  IconButton, InputLabel,FormControlLabel, Checkbox, Select, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Paper, Button, Grid, Menu, MenuItem, FormControl } from '@mui/material';
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "../../utils/axios";
import { useNavigate } from "react-router-dom";
import Header from "../../assets/charts/Header";
import { useUser } from "../../contexts/auth";
import { useTheme } from "@mui/material/styles";
import '../../App.css'
import ViewListIcon from '@mui/icons-material/ViewList'; // Import the List View icon
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import GridViewIcon from '@mui/icons-material/GridView';
import TableViewIcon from '@mui/icons-material/TableView';
import TocIcon from '@mui/icons-material/Toc';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const AllCall = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [leads, setLeads] = useState([]);
//   const [accounts, setAccounts] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteLeadId, setDeleteLeadId] = useState(null);
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState(null);
  const { userrole, companycode } = useUser();
  const [selectedView, setSelectedView] = useState('All Calls');
  const [viewAnchorEl, setViewAnchorEl] = useState(null);
 const [systemFiltersVisible, setSystemFiltersVisible] = useState(true);
  const [fieldFiltersVisible, setFieldFiltersVisible] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [alphabetFilter, setAlphabetFilter] = useState('');
  const [nameFilter, setNameFilter] = useState('All');
  const [openCreateCallMenu, setOpenCreateCallMenu] = useState(null);
  const [openScheduleCallDialog, setOpenScheduleCallDialog] = useState(false);
  const [openLogCallDialog, setOpenLogCallDialog] = useState(false);
  const [scheduleCallData, setScheduleCallData] = useState({
    callTo: "",
    relatedTo: "",
    callType: "Outbound",
    outgoingCallStatus: "Scheduled",
    callStartTime: "",
    callOwner: "",
    subject: "",
    purposeOfOutgoingCall: "",
  });
  const [logCallData, setLogCallData] = useState({
    callTo: "",
    relatedTo: "",
    callType: "Outbound",
    outgoingCallStatus: "Completed",
    callStartTime: "",
    callDuration: "",
    subject: "",
    voiceRecording: "",
  });

  const handleCreateCallClick = (event) => {
    setOpenCreateCallMenu(event.currentTarget);
  };

  const handleCreateCallClose = () => {
    setOpenCreateCallMenu(null);
  };

  const handleScheduleCall = () => {
    setOpenScheduleCallDialog(true);
    handleCreateCallClose();
  };

  const handleLogCall = () => {
    setOpenLogCallDialog(true);
    handleCreateCallClose();
  };

  const handleScheduleCallInputChange = (event) => {
    const { name, value } = event.target;
    setScheduleCallData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleLogCallInputChange = (event) => {
    const { name, value } = event.target;
    setLogCallData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleScheduleCallSubmit = () => {
    console.log("Scheduled Call Data:", scheduleCallData);
    setOpenScheduleCallDialog(false);
    // Here you would typically send this data to your backend
  };

  const handleLogCallSubmit = () => {
    console.log("Logged Call Data:", logCallData);
    setOpenLogCallDialog(false);
    // Here you would typically send this data to your backend
  };





  const meetings = [
    { id: 1, subject: "Follow up with Lead", relatedTo: "Chau Kitzman (Sample)", callStartTime: "08/07/2024 01:24 PM"},
   
    // More mock data can be added here
  ];

  const [filteredAccounts, setFilteredAccounts] = useState(meetings);

  useEffect(() => {
    setFilteredAccounts(
        meetings.filter(account => {
        if (nameFilter === 'All') return true;
        return account.title && account.title.charAt(0).toUpperCase() === nameFilter;
      })
    );
  }, [nameFilter, meetings]);

  const systemDefinedFilters = [
    "Touched Records",
    "Untouched Records",
    "Record Action",
    "Related Records Action",
  ];

  const fieldFilters = [
    "Call Agenda",
    "Call Duration",
    "Call Duration (in seconds)",
    "Call Owner",
    "Call Purpose",
    "Call Result",
    "Call Start Time",
    "Call Type",
    "Caller ID",
    "Contact Name",
    "Created By",
    "Created Time",
    "Dialled Number",
    "Modified By",
    "Modified Time",
    "Outgoing Call Status",
    "Related To",
    "Scheduled in CRM",
    "Subject",
    "Tag",
  ];

  const toggleSystemFilters = () => {
    setSystemFiltersVisible(!systemFiltersVisible);
  };

  const toggleFieldFilters = () => {
    setFieldFiltersVisible(!fieldFiltersVisible);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredSystemDefinedFilters = systemDefinedFilters.filter((filter) =>
    filter.toLowerCase().includes(searchTerm)
  );

  const filteredFieldFilters = fieldFilters.filter((filter) =>
    filter.toLowerCase().includes(searchTerm)
  );
  const handleViewMenuOpen = (event) => {
    setViewAnchorEl(event.currentTarget);
  };


  const handleChange = (event) => {
    setSelectedView(event.target.value);
  };

  const handleViewMenuClose = () => {
    setViewAnchorEl(null);
  };

  const viewMenuOpen = Boolean(viewAnchorEl);


  const handleNameFilterChange = (event) => {
    setNameFilter(event.target.value);
  };



  
const columns = [
    { 
      field: "subject", 
      headerName: "Subject", 
      flex: 0.3,
      renderHeader: (params) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography>{params.colDef.headerName}</Typography>
          <Select
            value={nameFilter}
            onChange={handleNameFilterChange}
            sx={{ ml: 1, height: 30 }}
          >
            <MenuItem value="All">All</MenuItem>
            {Array.from(Array(26)).map((_, i) => (
              <MenuItem key={i} value={String.fromCharCode(65 + i)}>
                {String.fromCharCode(65 + i)}
              </MenuItem>
            ))}
          </Select>
        </Box>
      )
    },
    { field: "relatedTo", headerName: "Related To", flex: 0.3 },
    { field: "callStartTime", headerName: "Call Start Time", flex: 0.3 },
 
  ];

  const handletoChange = async (event, lead) => {
    try {
      const updatedLeads = leads.map((leadItem) =>
        leadItem.id === lead ? { ...leadItem, leadto: event.target.value } : leadItem
      );
      setLeads(updatedLeads);

      const response = await axios.put(
        `/updateLeadState/${lead}`,
        {
          leadto: event.target.value,
        }
      );

      if (response && response.to === 200) {
        // fetchData();
        setDeleteSuccessMessage("Lead to updated successfully!");
      } else {
        console.error("Failed to update lead to");
      }
    } catch (error) {
      console.error("Error updating lead to:", error);
    }
  };

  const handleEdit = (lead) => {
    navigate(`/updatelead/${lead}`);
  };

  const handleDelete = (leadId) => {
    setDeleteLeadId(leadId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete(`/deleteLead/${deleteLeadId}`);
      if (response && response.to === 200) {
        setLeads((prevLeads) =>
          prevLeads.filter((lead) => lead.id !== deleteLeadId)
        );
        setDeleteSuccessMessage("Lead deleted successfully!");
        setTimeout(() => {
          setDeleteSuccessMessage(null);
        }, 3000);
      } else {
        console.error("Failed to delete lead");
      }
    } catch (error) {
      console.error("Error deleting lead:", error);
    }
    setOpenDialog(false);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuOpen = Boolean(anchorEl);

  return (
    <Box>
      {/* Header Section */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2, backgroundColor: 'white', color: 'black', marginTop: "5.2%", padding: '5px'}}>
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small" style={{color: 'white', background: 'black', borderRadius: '5px'}}>
      {/* <InputLabel id="lead-select-label"  style={{color: 'white'}} >Public Views</InputLabel> */}
      <Select
        labelId="lead-select-label"
        value={selectedView}
        onChange={handleChange}
        label="Public Views"
        style={{color: 'white'}}
      >
        <MenuItem value="All Calls">All Calls</MenuItem>
        <MenuItem value="Attended Calls">Attended Calls</MenuItem>
        <MenuItem value="Cancelled Calls">Cancelled Calls</MenuItem>
        <MenuItem value="Completed Calls">Completed Calls</MenuItem>
        <MenuItem value="Incoming Calls">Incoming Calls</MenuItem>
        <MenuItem value="Missed Calls">Missed Calls</MenuItem>
        <MenuItem value="My Attended Calls">My Attended Calls</MenuItem>
        <MenuItem value="My Calls">My Calls</MenuItem>
        <MenuItem value="My Cancelled Calls">My Cancelled Calls</MenuItem>
        <MenuItem value="My Incoming Calls">My Incoming Calls</MenuItem>
        <MenuItem value="My Missed Calls">My Missed Calls</MenuItem>
        <MenuItem value="My Outgoing Calls">My Outgoing Calls</MenuItem>
        <MenuItem value="My Overdue Calls">My Overdue Calls</MenuItem>
        
      </Select>
    </FormControl>
        <Box>
        <Button variant="contained" onClick={handleViewMenuOpen} startIcon={<ViewListIcon />}>List View</Button> {/* Added List View Button */}
        <Button variant="contained" color="primary" sx={{ mr: 2 }} onClick={handleCreateCallClick}>Create Call</Button>
          <Menu
            anchorEl={openCreateCallMenu}
            open={Boolean(openCreateCallMenu)}
            onClose={handleCreateCallClose}
          >
            <MenuItem onClick={handleScheduleCall}>Schedule a call</MenuItem>
            <MenuItem onClick={handleLogCall}>Log a call</MenuItem>
          </Menu>
          <Button variant="contained" onClick={handleMenuOpen}>Actions</Button>
          <Menu anchorEl={anchorEl} open={menuOpen} onClose={handleMenuClose}>
          <MenuItem onClick={handleMenuClose}>Mass Delete</MenuItem>
            <MenuItem onClick={handleMenuClose}>Mass Update</MenuItem>
            <MenuItem onClick={handleMenuClose}>Manage Tags</MenuItem>
            <MenuItem onClick={handleMenuClose}>Export Calls</MenuItem>
            <MenuItem onClick={handleMenuClose}>Print View</MenuItem>
          </Menu>
          <Menu anchorEl={viewAnchorEl} open={viewMenuOpen} onClose={handleViewMenuClose}> {/* Added List View Menu */}
            <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><TocIcon /></span> List View</MenuItem>
            <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><ViewKanbanIcon /></span> Kanban View</MenuItem>
            <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><ViewListIcon /></span> Custom List View</MenuItem>
            <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><GridViewIcon /></span> Tile View</MenuItem>
            <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><TableViewIcon /></span> Table View</MenuItem>
          </Menu>
        </Box>
      </Box>

      {/* Main Content */}
      <Grid container columnGap={5}> 
     
        <Grid item xs={2}>
      <Box
        sx={{
          p: 2,
          borderRight: "1px solid #e0e0e0",
          height: "76vh",
          background: "black",
          overflowY: "auto",
          marginTop: "20px"
        }}
      >
        <Typography variant="h6" color="white" style={{fontSize: "16px"}}>Filter Calls by</Typography>
        <TextField
          variant="outlined"
          placeholder="Search"
          size="small"
          fullWidth
          sx={{ mb: 2 }}
          value={searchTerm}
          onChange={handleSearchChange}
        />

        <Typography
          variant="body2"
          color="white"
          sx={{ mb: 1, cursor: "pointer", fontSize: "16px"}}
          onClick={toggleSystemFilters}
        >
        System Defined Filters <IconButton
            sx={{
              color: "white",
              transform: systemFiltersVisible ? 'rotate(90deg)' : 'rotate(0deg)',
              transition: 'transform 0.3s'
            }}
          >
            <ArrowRightIcon />
          </IconButton>
         
        </Typography>
        {systemFiltersVisible && (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {filteredSystemDefinedFilters.map((filter) => (
              <FormControlLabel
                key={filter}
                control={<Checkbox />}
                label={filter}
                sx={{ color: "white" }}
              />
            ))}
          </Box>
        )}

        <Typography
          variant="body2"
          color="white"
          sx={{ mt: 2, mb: 1, cursor: "pointer", fontSize: "16px" }}
          onClick={toggleFieldFilters}
        >
          Filter By Fields <IconButton
            sx={{
              color: "white",
              transform: fieldFiltersVisible ? 'rotate(90deg)' : 'rotate(0deg)',
              transition: 'transform 0.3s'
            }}
          >
            <ArrowRightIcon />
          </IconButton>
        </Typography>
        {fieldFiltersVisible && (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {filteredFieldFilters.map((filter) => (
              <FormControlLabel
                key={filter}
                control={<Checkbox />}
                label={filter}
                sx={{ color: "white" }}
              />
            ))}
          </Box>
        )}
      </Box>
    </Grid>
            <Grid item xs={9.5}>
          <Paper elevation={3} style={{ padding: '20px', height: '77vh', marginTop: '20px', color: 'black' }} className="listViewStyle">
            <Typography variant="h6" sx={{ mb: 2 }} style={{color: 'white'}}>Total Records: {filteredAccounts.length}</Typography>
            <DataGrid
              columns={columns}
              rows={filteredAccounts}
              pageSize={10}
              rowsPerPageOptions={[10]}
              checkboxSelection
              disableSelectionOnClick
            />
            
            
            {/* Confirmation Dialog */}
            <Dialog open={openDialog} onClose={handleCloseDialog}>
              <DialogTitle>Confirm Delete</DialogTitle>
              <DialogContent>Are you sure you want to delete this lead?</DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                  No
                </Button>
                <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
        </Grid>
      </Grid>
       {/* Schedule Call Dialog */}
       <Dialog open={openScheduleCallDialog} onClose={() => setOpenScheduleCallDialog(false)}>
        <DialogTitle>Schedule a call</DialogTitle>
        <DialogContent>
          <TextField
            select
            fullWidth
            margin="dense"
            name="callTo"
            label="Call To"
            value={scheduleCallData.callTo}
            onChange={handleScheduleCallInputChange}
          >
            <MenuItem value="Contact">Contact</MenuItem>
          </TextField>
          <TextField
            select
            fullWidth
            margin="dense"
            name="relatedTo"
            label="Related To"
            value={scheduleCallData.relatedTo}
            onChange={handleScheduleCallInputChange}
          >
            <MenuItem value="Account">Account</MenuItem>
          </TextField>
          <TextField
            select
            fullWidth
            margin="dense"
            name="callType"
            label="Call Type"
            value={scheduleCallData.callType}
            onChange={handleScheduleCallInputChange}
          >
            <MenuItem value="Outbound">Outbound</MenuItem>
          </TextField>
          <TextField
            fullWidth
            margin="dense"
            name="outgoingCallStatus"
            label="Outgoing Call Status"
            value={scheduleCallData.outgoingCallStatus}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            fullWidth
            margin="dense"
            name="callStartTime"
            label="Call Start Time"
            type="datetime-local"
            value={scheduleCallData.callStartTime}
            onChange={handleScheduleCallInputChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            select
            fullWidth
            margin="dense"
            name="callOwner"
            label="Call Owner"
            value={scheduleCallData.callOwner}
            onChange={handleScheduleCallInputChange}
          >
            <MenuItem value="Vivek Kumar Yadav">Vivek Kumar Yadav</MenuItem>
          </TextField>
          <TextField
            fullWidth
            margin="dense"
            name="subject"
            label="Subject"
            value={scheduleCallData.subject}
            onChange={handleScheduleCallInputChange}
          />
          <TextField
            fullWidth
            margin="dense"
            name="purposeOfOutgoingCall"
            label="Purpose Of Outgoing Call"
            multiline
            rows={4}
            value={scheduleCallData.purposeOfOutgoingCall}
            onChange={handleScheduleCallInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenScheduleCallDialog(false)} variant="contained" color="primary">Cancel</Button>
          <Button onClick={handleScheduleCallSubmit} variant="contained" color="primary">Schedule</Button>
        </DialogActions>
      </Dialog>

      {/* Log Call Dialog */}
      <Dialog open={openLogCallDialog} onClose={() => setOpenLogCallDialog(false)}>
        <DialogTitle>Log a call</DialogTitle>
        <DialogContent>
          <TextField
            select
            fullWidth
            margin="dense"
            name="callTo"
            label="Call To"
            value={logCallData.callTo}
            onChange={handleLogCallInputChange}
          >
            <MenuItem value="Contact">Contact</MenuItem>
          </TextField>
          <TextField
            select
            fullWidth
            margin="dense"
            name="relatedTo"
            label="Related To"
            value={logCallData.relatedTo}
            onChange={handleLogCallInputChange}
          >
            <MenuItem value="Account">Account</MenuItem>
          </TextField>
          <TextField
            select
            fullWidth
            margin="dense"
            name="callType"
            label="Call Type"
            value={logCallData.callType}
            onChange={handleLogCallInputChange}
          >
            <MenuItem value="Outbound">Outbound</MenuItem>
          </TextField>
          <TextField
            fullWidth
            margin="dense"
            name="outgoingCallStatus"
            label="Outgoing Call Status"
            value={logCallData.outgoingCallStatus}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            fullWidth
            margin="dense"
            name="callStartTime"
            label="Call Start Time"
            type="datetime-local"
            value={logCallData.callStartTime}
            onChange={handleLogCallInputChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            fullWidth
            margin="dense"
            name="callDuration"
            label="Call Duration"
            value={logCallData.callDuration}
            onChange={handleLogCallInputChange}
            placeholder="00 minutes 00 seconds"
          />
          <TextField
            fullWidth
            margin="dense"
            name="subject"
            label="Subject"
            value={logCallData.subject}
            onChange={handleLogCallInputChange}
          />
          <TextField
            fullWidth
            margin="dense"
            name="voiceRecording"
            label="Voice Recording"
            value={logCallData.voiceRecording}
            onChange={handleLogCallInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenLogCallDialog(false)} variant="contained" color="primary">Cancel</Button>
          <Button onClick={handleLogCallSubmit} variant="contained" color="primary">Save</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AllCall;
