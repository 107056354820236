// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import { Box, Typography, Grid, Paper, Menu, Tabs, Tab, Avatar, Button, MenuItem, Select, InputLabel, FormControl, IconButton } from '@mui/material';
// import axios from '../../../utils/axios';
// import { BsThreeDots } from "react-icons/bs";
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';

// const LeadDetails = () => {
//   const { id } = useParams();
//   const [lead, setLead] = useState(null);
//   const [activeTab, setActiveTab] = useState(0);
//   const [leadStatus, setLeadStatus] = useState('');
//   const [anchorEl, setAnchorEl] = useState(null);

//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };

//   const handleMenuOpen = (event) => {
//     setAnchorEl(event.currentTarget);
//   };


//   const menuOpen = Boolean(anchorEl);


  // useEffect(() => {
  //   const fetchLeadDetails = async () => {
  //     try {
  //       const response = await axios.get(`/getLeads/${id}`);
  //       setLead(response.data);
  //       setLeadStatus(response.data.leadstate);  // Set initial lead status
  //     } catch (error) {
  //       console.error('Error fetching lead details:', error);
  //     }
  //   };
  //   fetchLeadDetails();
  // }, [id]);

//   const handleTabChange = (event, newValue) => {
//     setActiveTab(newValue);
//   };

//   const handleStatusChange = (event) => {
//     setLeadStatus(event.target.value);
//     // Here you can also make an API call to save the new status
//   };

//   if (!lead) return <Typography>Loading...</Typography>;

//   return (
//     <Box sx={{ p: 2, mt: 10 }}>
//       <Grid container alignItems="center" spacing={2} style={{background: 'green', padding: '10px', position: 'fixed', zIndex: '1000' }}>
//         <Grid item>
//           <Avatar alt={lead.leadname} src={lead.photoUrl} sx={{ width: 56, height: 56 }} />
//         </Grid>
//         <Grid item>
//           <Typography variant="h5">{lead.leadname}</Typography>
//           <Typography variant="subtitle1">{lead.company}</Typography>
//         </Grid>
//         <Grid item xs>
//           <Box display="flex" justifyContent="flex-end" gap={1}>
//             <Button variant="contained" color="primary">Send Email</Button>
//             <Button variant="contained">Convert</Button>
//             <Button variant="contained">Edit</Button>
//             <Button variant="contained" onClick={handleMenuOpen}><BsThreeDots /></Button>
//             <Menu anchorEl={anchorEl} open={menuOpen} onClose={handleMenuClose}>
//             <MenuItem onClick={handleMenuClose}>Clone</MenuItem>
//             <MenuItem onClick={handleMenuClose}>Delete</MenuItem>
//             <MenuItem onClick={handleMenuClose}>Print Preview</MenuItem>
//             <MenuItem onClick={handleMenuClose}>Find and Merge Duplicates</MenuItem>
//             <MenuItem onClick={handleMenuClose}>Customize Business Card</MenuItem>
//             <MenuItem onClick={handleMenuClose}>Organize Lead Details</MenuItem>
//           </Menu>
//           </Box>
//         </Grid>
//       </Grid>

//       <Grid container columnGap={5} sx={{mt: 10}}>
//       <Grid item xs={2}>
//           <Paper sx={{ p: 2 }} style={{height: '140 %'}}>
//             <Typography variant="h6">Related List</Typography>
//             <Typography>Notes</Typography>
//             <Typography>Attachments</Typography>
//             <Typography>Open Activities</Typography>
//             <Typography>Closed Activities</Typography>
//             <Typography>Invited Meetings</Typography>
//             <Typography>Emails</Typography>
//             <Typography>Campaigns</Typography>
//             <Typography>Social</Typography>
//           </Paper>
//         </Grid>

//         <Grid item xs={9.5}>
//           <Paper sx={{ p: 2 }}>
//             <Tabs value={activeTab} onChange={handleTabChange}>
//               <Tab label="Overview" />
//               <Tab label="Timeline" />
//             </Tabs>
//             {activeTab === 0 && (
//               <Box sx={{ mt: 2 }}>
//                 <Typography variant="h6">Lead Information</Typography>
//                 <Typography>Lead Owner: {lead.leadowner}</Typography>
//                 <Typography>Email: {lead.leademail}</Typography>
//                 <Typography>Phone: {lead.contactno}</Typography>
//                 <FormControl fullWidth sx={{ mt: 2 }}>
//                   <InputLabel id="lead-status-label">Lead Status</InputLabel>
//                   <Select
//                     labelId="lead-status-label"
//                     value={leadStatus}
//                     onChange={handleStatusChange}
//                   >
//                     <MenuItem value="None">None</MenuItem>
//                     <MenuItem value="Attempted to Contact">Attempted to Contact</MenuItem>
//                     <MenuItem value="Contact in Future">Contact in Future</MenuItem>
//                     <MenuItem value="Contacted">Contacted</MenuItem>
//                     <MenuItem value="Junk Lead">Junk Lead</MenuItem>
//                     <MenuItem value="Lost Lead">Lost Lead</MenuItem>
//                     <MenuItem value="Not Contacted">Not Contacted</MenuItem>
//                   </Select>
//                 </FormControl>
//               </Box>
//             )}
//             {activeTab === 1 && (
//               <Box sx={{ mt: 2 }}>
//                 <Typography variant="h6">Timeline Content</Typography>
//                 {/* Add timeline information here */}
//               </Box>
//             )}
//           </Paper>
      //     <Grid container spacing={2} sx={{ mt: 2 }}>
      //   <Grid item xs={12}>
      //     <Paper sx={{ p: 2 }}>
      //       <Typography variant="h6">Notes</Typography>
      //       <Box component="form">
      //         <FormControl fullWidth>
      //           <InputLabel htmlFor="add-note">Add a note...</InputLabel>
      //         </FormControl>
      //       </Box>
      //     </Paper>
      //   </Grid>
      // </Grid>
//         </Grid>

//       </Grid>
//     </Box>
//   );
// };

// export default LeadDetails;


// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import { Box, Typography, FormControl, TextField, Grid, Paper, Tabs, Tab, Avatar, Button, Menu, MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
// import { DropzoneArea } from 'material-ui-dropzone';
// import axios from '../../../utils/axios';
// import { BsThreeDots } from "react-icons/bs";

// const LeadDetails = () => {
//   const { id } = useParams();
//   const [lead, setLead] = useState(null);
//   const [activeTab, setActiveTab] = useState(0);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [attachAnchorEl, setAttachAnchorEl] = useState(null);
//   const [openUploadDialog, setOpenUploadDialog] = useState(false);
//   const [showDetails, setShowDetails] = useState(false);

//   useEffect(() => {
//     const fetchLeadDetails = async () => {
//       try {
//         const response = await axios.get(`/getLeads/${id}`);
//         setLead(response.data);
//       } catch (error) {
//         console.error('Error fetching lead details:', error);
//       }
//     };
//     fetchLeadDetails();
//   }, [id]);

//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };

//   const handleMenuOpen = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleAttachClose = () => {
//     setAttachAnchorEl(null);
//   };

//   const handleAttachOpen = (event) => {
//     setAttachAnchorEl(event.currentTarget);
//   };

//   const handleUploadClick = () => {
//     setOpenUploadDialog(true);
//     handleAttachClose();
//   };

//   const handleCloseUploadDialog = () => {
//     setOpenUploadDialog(false);
//   };

//   const handleFilesChange = (files) => {
//     console.log(files);
//   };

//   const handleTabChange = (event, newValue) => {
//     setActiveTab(newValue);
//   };

//   if (!lead) return <Typography>Loading...</Typography>;

//   return (
//     <Box sx={{ p: 2, mt: 10 }}>
//       <Grid container alignItems="center" spacing={2} style={{ background: 'green', padding: '10px', position: 'fixed', zIndex: '1000', width: '100%' }}>
//         <Grid item>
//           <Avatar alt={lead.leadname} src={lead.photoUrl} sx={{ width: 56, height: 56 }} />
//         </Grid>
//         <Grid item>
//           <Typography variant="h5">{lead.leadname}</Typography>
//           <Typography variant="subtitle1">{lead.company}</Typography>
//         </Grid>
//         <Grid item xs>
//           <Box display="flex" justifyContent="flex-end" gap={1}>
//             <Button variant="contained" color="primary">Send Email</Button>
//             <Button variant="contained">Convert</Button>
//             <Button variant="contained">Edit</Button>
//             <Button variant="contained" onClick={handleMenuOpen}><BsThreeDots /></Button>
//             <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
//               <MenuItem onClick={handleMenuClose}>Clone</MenuItem>
//               <MenuItem onClick={handleMenuClose}>Delete</MenuItem>
//               <MenuItem onClick={handleMenuClose}>Print Preview</MenuItem>
//               <MenuItem onClick={handleMenuClose}>Find and Merge Duplicates</MenuItem>
//               <MenuItem onClick={handleMenuClose}>Customize Business Card</MenuItem>
//               <MenuItem onClick={handleMenuClose}>Organize Lead Details</MenuItem>
//             </Menu>
//           </Box>
//         </Grid>
//       </Grid>

//       <Grid container columnGap={5} sx={{ mt: 10 }}>
//         <Grid item xs={2}>
//           <Paper sx={{ p: 2 }} style={{ height: '70vh' }}>
//             <Typography variant="h6">Related List</Typography>
//             <Typography>Notes</Typography>
//             <Typography>Attachments</Typography>
//             <Typography>Open Activities</Typography>
//             <Typography>Closed Activities</Typography>
//             <Typography>Invited Meetings</Typography>
//             <Typography>Emails</Typography>
//             <Typography>Campaigns</Typography>
//             <Typography>Social</Typography>
//           </Paper>
//         </Grid>

//         <Grid item xs={9.5}>
//           <Paper sx={{ p: 2, overflowY: 'auto', height: '72vh', overflow: 'auto' }}>
//             <Tabs value={activeTab} onChange={handleTabChange} style={{ color: 'grey' }}>
//               <Tab label="Overview" />
//               <Tab label="Timeline" />
//             </Tabs>
//             {activeTab === 0 && (
//               <Box sx={{ mt: 2 }}>
//                 <Typography variant="h6">Lead Information</Typography>
//                 <Typography>Lead Owner: {lead.leadname}</Typography>
//                 <Typography>Email: {lead.leademail}</Typography>
//                 <Typography>Phone: {lead.contactno}</Typography>
//                 <Typography>Lead Statue: {lead.leadstate}</Typography>

//                 <Grid container spacing={2} sx={{ mt: 2 }}>
//                   <Grid item xs={12}>
//                     <Box sx={{ mt: 2 }} style={{ background: 'grey' }}>
//                       <Button onClick={() => setShowDetails(!showDetails)}>
//                         {showDetails ? "Hide Details" : "Show Details"}
//                       </Button>

//                       {showDetails && (
//                         <Box>
//                           <Typography variant="h5">Lead Information</Typography>
//                           <Grid container spacing={2}>
//                             <Grid item xs={6}>
//                               <Typography>Lead Owner: {lead.leadname}</Typography>
//                               <Typography>Title: {lead.title}</Typography>
//                               <Typography>Phone: {lead.contactno}</Typography>
//                               <Typography>Lead Source: {lead.leadSource}</Typography>
//                               <Typography>Industry: {lead.industry}</Typography>
//                               <Typography>Annual Revenue: {lead.annualRevenue}</Typography>
//                             </Grid>
//                             <Grid item xs={6}>
//                               <Typography>Company: {lead.company}</Typography>
//                               <Typography>Lead Name: {lead.name}</Typography>
//                               <Typography>Email: {lead.email}</Typography>
//                               <Typography>Website: {lead.website}</Typography>
//                               <Typography>Lead Status: {lead.leadStatus}</Typography>
//                             </Grid>
//                           </Grid>
//                           <Typography variant="h5" sx={{ mt: 2 }}>Address Information</Typography>
//                           <Grid container spacing={2}>
//                             <Grid item xs={6}>
//                               <Typography>Street: {lead.address.street}</Typography>
//                               <Typography>State: {lead.address.state}</Typography>
//                               <Typography>Country: {lead.address.country}</Typography>
//                             </Grid>
//                             <Grid item xs={6}>
//                               <Typography>City: {lead.address.city}</Typography>
//                               <Typography>Zip Code: {lead.address.zipCode}</Typography>
//                             </Grid>
//                           </Grid>
//                         </Box>
//                       )}
//                     </Box>
//                   </Grid>
//                 </Grid>

//                 <Grid container spacing={2} sx={{ mt: 2 }}>
//                   <Grid item xs={12}>
//                     <Paper sx={{ p: 2 }} style={{ background: 'red' }}>
//                       <Typography variant="h6">Notes</Typography>
//                       <Box component="form">
//                         <FormControl fullWidth>
//                           <TextField
//                             id="add-note"
//                             label="Add a note..."
//                             multiline
//                             rows={4}
//                             variant="outlined"
//                           />
//                         </FormControl>
//                       </Box>
//                     </Paper>
//                   </Grid>
//                 </Grid>

//                 <Grid container spacing={2} sx={{ mt: 2 }}>
//                   <Grid item xs={12}>
//                     <Paper sx={{ p: 2 }} style={{ background: 'grey' }}>
//                       <Typography variant="h6">Attachments</Typography>
//                       <Button variant="outlined" onClick={handleAttachOpen}>Attach</Button>
//                       <Menu
//                         anchorEl={attachAnchorEl}
//                         open={Boolean(attachAnchorEl)}
//                         onClose={handleAttachClose}
//                       >
//                         <MenuItem onClick={handleUploadClick}>Upload File</MenuItem>
//                         <MenuItem onClick={handleAttachClose}>Attach from Cloud</MenuItem>
//                       </Menu>
//                       <Dialog open={openUploadDialog} onClose={handleCloseUploadDialog}>
//                         <DialogTitle>Attach File</DialogTitle>
//                         <DialogContent>
//                           <DialogContentText>
//                             Click here or drop files here to attach.
//                           </DialogContentText>
//                           <DropzoneArea
//                             onChange={handleFilesChange}
//                             acceptedFiles={['image/*', 'application/pdf']}
//                             showPreviews={false}
//                             showFileNames
//                             maxFileSize={5000000}
//                           />
//                         </DialogContent>
//                         <DialogActions>
//                           <Button onClick={handleCloseUploadDialog}>Cancel</Button>
//                           <Button onClick={handleCloseUploadDialog}>Attach</Button>
//                         </DialogActions>
//                       </Dialog>
//                     </Paper>
//                   </Grid>
//                 </Grid>

//                 <Grid container spacing={2} sx={{ mt: 2 }}>
//                   <Grid item xs={12}>
//                     <Paper sx={{ p: 2 }} style={{ background: 'purple' }}>
//                       <Typography variant="h6">Open Activities</Typography>
//                       <Typography>No open activities available.</Typography>
//                     </Paper>
//                   </Grid>
//                 </Grid>

//                 <Grid container spacing={2} sx={{ mt: 2 }}>
//                   <Grid item xs={12}>
//                     <Paper sx={{ p: 2 }} style={{ background: 'orange' }}>
//                       <Typography variant="h6">Closed Activities</Typography>
//                       <Typography>No closed activities available.</Typography>
//                     </Paper>
//                   </Grid>
//                 </Grid>
//               </Box>
//             )}
//             {activeTab === 1 && (
//               <Box sx={{ mt: 2 }}>
//                 <Typography variant="h6">Timeline</Typography>
//                 <Typography>Timeline details go here...</Typography>
//               </Box>
//             )}
//           </Paper>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// export default LeadDetails;


// import React, { useState, useEffect, useRef } from 'react';
// import { useParams } from 'react-router-dom';
// import { Box, Typography, FormControl, TextField, Grid, Paper, Tabs, Tab, Avatar, Button, Menu, MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, List, ListItem, ListItemText, ListItemAvatar, IconButton } from '@mui/material';
// import { DropzoneArea } from 'material-ui-dropzone';
// import axios from '../../../utils/axios';
// import { BsThreeDots, BsPaperclip } from "react-icons/bs";
// import ArrowRightIcon from '@mui/icons-material/ArrowRight';

// const LeadDetails = () => {
//   const { id } = useParams();
//   const [lead, setLead] = useState(null);
//   const [activeTab, setActiveTab] = useState(0);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [attachAnchorEl, setAttachAnchorEl] = useState(null);
//   const [openUploadDialog, setOpenUploadDialog] = useState(false);
//   const [attachments, setAttachments] = useState([]);
//   const [showDetails, setShowDetails] = useState(false);
//   const [lead1, setLead1] = useState(null);
//   const [systemFiltersVisible, setSystemFiltersVisible] = useState(true);
//   const [arrowRotated, setArrowRotated] = useState(false);
//   const [highlightedSection, setHighlightedSection] = useState('');
//   const [openEmailDialog, setOpenEmailDialog] = useState(false);

//   const notesRef = useRef(null);
//   const attachmentsRef = useRef(null);
//   const openActivitiesRef = useRef(null);
//   const closedActivitiesRef = useRef(null);


//   const handleShowDetails = () => {
//     setShowDetails(!showDetails);
//     setArrowRotated(!arrowRotated);
//   };

//   const scrollToSection = (sectionRef, sectionName) => {
//     sectionRef.current.scrollIntoView({ behavior: 'smooth' });
//     setHighlightedSection(sectionName);
//     setTimeout(() => {
//       setHighlightedSection('');
//     }, 1000); // 2 seconds delay to reset the highlight
//   };


//   const handleSendEmail = () => {
//     setOpenEmailDialog(true);
//   };

//   const handleCloseEmailDialog = () => {
//     setOpenEmailDialog(false);
//   };
  
//   useEffect(() => {
//     // Simulate fetching lead data
//   setLead1({
//     name: "Mr. Christopher Maclead (Sample)",
//     company: "Rangoni Of Florence",
//     leadOwner: "Vivek Kumar Yadav",
//     title: "VP Accounting",
//     phone: "555-555-5555",
//     email: "christopher.maclead@noemail.com",
//     leadSource: "Cold Call",
//     industry: "Service Provider",
//     annualRevenue: "$850,000.00",
//     leadStatus: "Lost Lead",
//     website: "http://www.rangoniofflorence.com",
//     skypeId: "christopher-maclead",
//     twitter: "christophermaclead_sample",
//     address: {
//       street: "37275 St Rt 17m M",
//       city: "Middle Island",
//       state: "NY",
//       zipCode: "11953",
//       country: "United States"
//     }
//   });
// }, []);

//   useEffect(() => {
//     const fetchLeadDetails = async () => {
//       try {
//         const response = await axios.get(`/getLeads/${id}`);
//         setLead(response.data);
//       } catch (error) {
//         console.error('Error fetching lead details:', error);
//       }
//     };
//     fetchLeadDetails();
//   }, [id]);

//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };

//   const handleMenuOpen = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleAttachClose = () => {
//     setAttachAnchorEl(null);
//   };



//   const handleAttachOpen = (event) => {
//     setAttachAnchorEl(event.currentTarget);
//   };

//   const handleUploadClick = () => {
//     setOpenUploadDialog(true);
//     handleAttachClose();
//   };

//   const handleCloseUploadDialog = () => {
//     setOpenUploadDialog(false);
//   };

//   const toggleSystemFilters = () => {
//     setSystemFiltersVisible(!systemFiltersVisible);
//   };

//   const handleFilesChange = (files) => {
//     setAttachments(files.map(file => ({
//       name: file.name,
//       size: file.size,
//       url: URL.createObjectURL(file),
//       uploadedBy: "Vivek Kumar Yadav",
//       dateAdded: new Date().toLocaleString()
//     })));
//   };

//   const handleTabChange = (event, newValue) => {
//     setActiveTab(newValue);
//   };

//   if (!lead) return <Typography>Loading...</Typography>;

//   return (
//     <Box sx={{ p: 2, mt: 10 }}>
//       <Grid container alignItems="center" spacing={2} style={{ background: 'green', padding: '10px', position: 'fixed', zIndex: '1000', width: '100%' }}>
//         <Grid item>
//           <Avatar alt={lead.leadname} src={lead.photoUrl} sx={{ width: 56, height: 56 }} />
//         </Grid>
//         <Grid item>
//           <Typography variant="h5">{lead.leadname}</Typography>
//           <Typography variant="subtitle1">{lead.company}</Typography>
//         </Grid>
//         <Grid item xs>
//           <Box display="flex" justifyContent="flex-end" gap={1}>
//             <Button variant="contained" color="primary" onClick={handleSendEmail}>Send Email</Button>
//             <Button variant="contained">Convert</Button>
//             <Button variant="contained">Edit</Button>
//             <Button variant="contained" onClick={handleMenuOpen}><BsThreeDots /></Button>
//             <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
//               <MenuItem onClick={handleMenuClose}>Clone</MenuItem>
//               <MenuItem onClick={handleMenuClose}>Delete</MenuItem>
//               <MenuItem onClick={handleMenuClose}>Print Preview</MenuItem>
//               <MenuItem onClick={handleMenuClose}>Find and Merge Duplicates</MenuItem>
//               <MenuItem onClick={handleMenuClose}>Customize Business Card</MenuItem>
//               <MenuItem onClick={handleMenuClose}>Organize Lead Details</MenuItem>
//             </Menu>
//           </Box>
//         </Grid>
//       </Grid>

//       <Grid container columnGap={5} sx={{ mt: 10 }}>
//         <Grid item xs={2}>
//           <Paper sx={{ p: 2 }} style={{ height: '70vh' }}>
//           <Typography variant="h6">Related List</Typography>
//             <Typography onClick={() => scrollToSection(notesRef, 'Notes')}>Notes</Typography>
//             <Typography onClick={() => scrollToSection(attachmentsRef, 'Attachments')}>Attachments</Typography>
//             <Typography onClick={() => scrollToSection(openActivitiesRef, 'Open Activities')}>Open Activities</Typography>
//             <Typography onClick={() => scrollToSection(closedActivitiesRef, 'Closed Activities')}>Closed Activities</Typography>
//             <Typography>Invited Meetings</Typography>
//             <Typography>Emails</Typography>
//             <Typography>Campaigns</Typography>
//             <Typography>Social</Typography>
//           </Paper>
//         </Grid>

//         <Grid item xs={9.5}>
//           <Paper sx={{ p: 2, overflowY: 'auto', height: '72vh', overflow: 'auto' }}>
//             <Tabs value={activeTab} onChange={handleTabChange} style={{ color: 'grey' }}>
//               <Tab label="Overview" />
//               <Tab label="Timeline" />
//             </Tabs>
//             {activeTab === 0 && (
//               <Box sx={{ mt: 2 }}>
//                 <Typography variant="h6">Lead Information</Typography>
//                 <Typography>Lead Owner: {lead.leadname}</Typography>
//                 <Typography>Email: {lead.leademail}</Typography>
//                 <Typography>Phone: {lead.contactno}</Typography>
//                 <Typography>Lead Statue: {lead.leadstate}</Typography>

//                 <Grid container spacing={2} sx={{ mt: 2 }}>
//                   <Grid item xs={12}>
//                     <Box sx={{ mt: 2 }} style={{ background: 'grey' }}>
//                     <Button onClick={handleShowDetails}>
//                   <ArrowRightIcon style={{ transform: arrowRotated ? 'rotate(90deg)' : 'none', transition: 'transform 0.3s' }} />
//                   {showDetails ? "Hide Details" : "Show Details"}
//                 </Button>

//                       {showDetails && (
//                         <Box>
//                           <Typography variant="h5">Lead Information</Typography>
//                           <Grid container spacing={2}>
//                             <Grid item xs={6}>
//                               <Typography>Lead Owner: {lead1.leadname}</Typography>
//                               <Typography>Title: {lead1.title}</Typography>
//                               <Typography>Phone: {lead1.contactno}</Typography>
//                               <Typography>Lead Source: {lead1.leadSource}</Typography>
//                               <Typography>Industry: {lead1.industry}</Typography>
//                               <Typography>Annual Revenue: {lead1.annualRevenue}</Typography>
//                             </Grid>
//                             <Grid item xs={6}>
//                               <Typography>Company: {lead1.company}</Typography>
//                               <Typography>Lead Name: {lead1.name}</Typography>
//                               <Typography>Email: {lead1.email}</Typography>
//                               <Typography>Website: {lead1.website}</Typography>
//                               <Typography>Lead Status: {lead1.leadStatus}</Typography>
//                             </Grid>
//                           </Grid>
//                           <Typography variant="h5" sx={{ mt: 2 }}>Address Information</Typography>
//                           <Grid container spacing={2}>
//                             <Grid item xs={6}>
//                               <Typography>Street: {lead1.address.street}</Typography>
//                               <Typography>State: {lead1.address.state}</Typography>
//                               <Typography>Country: {lead1.address.country}</Typography>
//                             </Grid>
//                             <Grid item xs={6}>
//                               <Typography>City: {lead1.address.city}</Typography>
//                               <Typography>Zip Code: {lead1.address.zipCode}</Typography>
//                             </Grid>
//                           </Grid>
//                         </Box>
//                       )}
//                     </Box>
//                   </Grid>
//                 </Grid>

//                 <Grid container spacing={2} sx={{ mt: 2 }}>
//                 <Grid item xs={12} ref={notesRef}>
//                     <Paper sx={{ p: 2, backgroundColor: highlightedSection === 'Notes' ? 'yellow' : 'grey' }}>
//                       <Typography variant="h6" sx={{ p: 2, backgroundColor: highlightedSection === 'Notes' ? 'yellow' : 'grey' }}>Notes</Typography>
//                       <Box component="form">
//                         <FormControl fullWidth>
//                           <TextField
//                             id="add-note"
//                             label="Add a note..."
//                             multiline
//                             rows={4}
//                             variant="outlined"
//                           />
//                         </FormControl>
//                       </Box>
//                     </Paper>
//                   </Grid>
//                 </Grid>

//                 <Grid container spacing={2} sx={{ mt: 2 }}>
//                 <Grid item xs={12} ref={attachmentsRef}>
//                     <Paper sx={{ p: 2, backgroundColor: highlightedSection === 'Attachments' ? 'yellow' : 'grey' }}>
        
//                       <Typography variant="h6">Attachments</Typography>
//                       <Button variant="outlined" onClick={handleAttachOpen}>Attach</Button>
//                       <Menu
//                         anchorEl={attachAnchorEl}
//                         open={Boolean(attachAnchorEl)}
//                         onClose={handleAttachClose}
//                       >
//                         <MenuItem onClick={handleUploadClick}>Upload File</MenuItem>
//                         {/* <MenuItem onClick={handleAttachClose}>Attach from Cloud</MenuItem> */}
//                       </Menu>
//                       <Dialog open={openUploadDialog} onClose={handleCloseUploadDialog}>
//                         <DialogTitle>Attach File</DialogTitle>
//                         <DialogContent>
//                           <DialogContentText>
//                             Click here or drop files here to attach.
//                           </DialogContentText>
//                           <DropzoneArea
//                             onChange={handleFilesChange}
//                             acceptedFiles={['image/*', 'application/pdf']}
//                             showPreviews={false}
//                             showFileNames
//                             maxFileSize={5000000}
//                             filesLimit={1}
//                           />
//                         </DialogContent>
//                         <DialogActions>
//                           <Button onClick={handleCloseUploadDialog}>Cancel</Button>
//                           <Button onClick={handleCloseUploadDialog}>Upload</Button>
//                         </DialogActions>
//                       </Dialog>
//                       <List>
//                         {attachments.map((file, index) => (
//                           <ListItem key={index}>
//                             <ListItemAvatar>
//                               <Avatar>
//                                 <BsPaperclip />
//                               </Avatar>
//                             </ListItemAvatar>
//                             <ListItemText
//                               primary={file.name}
//                               secondary={`Size: ${(file.size / 1024).toFixed(2)} KB | Uploaded by: ${file.uploadedBy} on ${file.dateAdded}`}
//                             />
//                             <IconButton edge="end" onClick={() => window.open(file.url, '_blank')}>
//                               <BsPaperclip />
//                             </IconButton>
//                           </ListItem>
//                         ))}
//                       </List>
//                     </Paper>
//                   </Grid>
//                 </Grid>

//                 <Grid container spacing={2} sx={{ mt: 2 }}>
//                 <Grid item xs={12} ref={openActivitiesRef}>
//                 <Paper sx={{ p: 2, backgroundColor: highlightedSection === 'Open Activities' ? 'yellow' : 'grey' }}>
//                       <Typography variant="h6">Open Activities</Typography>
//                       <Typography>No open activities available.</Typography>
//                     </Paper>
//                   </Grid>
//                 </Grid>

//                 <Grid container spacing={2} sx={{ mt: 2 }}>
//                 <Grid item xs={12} ref={closedActivitiesRef}>
//                 <Paper sx={{ p: 2, backgroundColor: highlightedSection === 'Closed Activities' ? 'yellow' : 'grey' }}>
//                       <Typography variant="h6">Closed Activities</Typography>
//                       <Typography>No closed activities available.</Typography>
//                     </Paper>
//                   </Grid>
//                 </Grid>

// <Dialog open={openEmailDialog} onClose={handleCloseEmailDialog} fullWidth maxWidth="md">
//   <DialogTitle>New Message</DialogTitle>
//   <DialogContent>
//     <TextField fullWidth label="To" value={lead.leademail} margin="normal" />
//     <TextField fullWidth label="Subject" margin="normal" />
//     <TextField fullWidth multiline rows={10} label="Message" margin="normal" />
//   </DialogContent>
//   <DialogActions>
//     <Button onClick={handleCloseEmailDialog}>Cancel</Button>
//     <Button onClick={handleCloseEmailDialog} variant="contained" color="primary">Send</Button>
//   </DialogActions>
// </Dialog>

//               </Box>
//             )}
//             {activeTab === 1 && (
//               <Box sx={{ mt: 2 }}>
//                 <Typography>Timeline Content</Typography>
//                 {/* Add timeline information here */}
//               </Box>
//             )}
//           </Paper>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// export default LeadDetails;


// import React, { useState, useEffect, useRef } from 'react';
// import { useParams } from 'react-router-dom';
// import { Helmet } from 'react-helmet-async';
// import { Box, Typography, FormControl, TextField, Grid, Paper, Tabs, Tab, Avatar, Button, Menu, MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, List, ListItem, ListItemText, ListItemAvatar, IconButton } from '@mui/material';
// import { DropzoneArea } from 'material-ui-dropzone';
// import axios from '../../../utils/axios';
// import { BsThreeDots, BsPaperclip } from "react-icons/bs";
// import ArrowRightIcon from '@mui/icons-material/ArrowRight';

// const LeadDetails = () => {
//   const { id } = useParams();
//   const [lead, setLead] = useState(null);
//   const [activeTab, setActiveTab] = useState(0);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [attachAnchorEl, setAttachAnchorEl] = useState(null);
//   const [openUploadDialog, setOpenUploadDialog] = useState(false);
//   const [attachments, setAttachments] = useState([]);
//   const [showDetails, setShowDetails] = useState(false);
//   const [lead1, setLead1] = useState(null);
//   const [systemFiltersVisible, setSystemFiltersVisible] = useState(true);
//   const [arrowRotated, setArrowRotated] = useState(false);
//   const [highlightedSection, setHighlightedSection] = useState('');
//   const [openEmailDialog, setOpenEmailDialog] = useState(false);
//   const [timelineData, setTimelineData] = useState([]);
//   const [leadData, setLeadData] = useState(null);

//   const notesRef = useRef(null);
//   const attachmentsRef = useRef(null);
//   const openActivitiesRef = useRef(null);
//   const closedActivitiesRef = useRef(null);

//   const handleShowDetails = () => {
//     setShowDetails(!showDetails);
//     setArrowRotated(!arrowRotated);
//   };

//   const fetchCompanyData = async () => {
//     try {
//       const response = await axios.get(`/getUpdatedLeadById/${id}`);
//       const data = response.data;
//       console.log("Lead Data:", data);
//       setLeadData(data);
//     } catch (error) {
//       console.error("Error fetching company data:", error);
//     }
//   };

//   useEffect(() => {
//     fetchCompanyData();
//   }, [id]);


//   const scrollToSection = (sectionRef, sectionName) => {
//     sectionRef.current.scrollIntoView({ behavior: 'smooth' });
//     setHighlightedSection(sectionName);
//     setTimeout(() => {
//       setHighlightedSection('');
//     }, 2000); // 2 seconds delay to reset the highlight
//   };

//   const handleSendEmail = () => {
//     setOpenEmailDialog(true);
//   };

//   const handleCloseEmailDialog = () => {
//     setOpenEmailDialog(false);
//   };
  
//   useEffect(() => {
//     setLead1({
//       name: "Mr. Christopher Maclead (Sample)",
//       company: "Rangoni Of Florence",
//       leadOwner: "Vivek Kumar Yadav",
//       title: "VP Accounting",
//       phone: "555-555-5555",
//       email: "christopher.maclead@noemail.com",
//       leadSource: "Cold Call",
//       industry: "Service Provider",
//       annualRevenue: "$850,000.00",
//       leadStatus: "Lost Lead",
//       website: "http://www.rangoniofflorence.com",
//       skypeId: "christopher-maclead",
//       twitter: "christophermaclead_sample",
//       address: {
//         street: "37275 St Rt 17m M",
//         city: "Middle Island",
//         state: "NY",
//         zipCode: "11953",
//         country: "United States"
//       }
//     });
//   }, []);

//   const fetchLeadDetails = async () => {
//     try {
//       const response = await axios.get(`/getLeads/${id}`);
//       setLead(response.data);
//     } catch (error) {
//       console.error('Error fetching lead details:', error);
//     }
//   };

//   useEffect(() => {

//     fetchLeadDetails();
//   }, [id]);

//   useEffect(() => {
//     if (activeTab === 1) {
//       const fetchTimelineData = async () => {
//         try {
//           const response = await axios.get(`/leadstimeline/${id}`);
//           setTimelineData(response.data);
//         } catch (error) {
//           console.error('Error fetching timeline data:', error);
//         }
//       };
//       fetchTimelineData();
//     }
//   }, [activeTab, id]);

//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };

//   const handleMenuOpen = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleAttachClose = () => {
//     setAttachAnchorEl(null);
//   };

//   const handleAttachOpen = (event) => {
//     setAttachAnchorEl(event.currentTarget);
//   };

//   const handleUploadClick = () => {
//     setOpenUploadDialog(true);
//     handleAttachClose();
//   };

//   const handleCloseUploadDialog = () => {
//     setOpenUploadDialog(false);
//   };

//   const toggleSystemFilters = () => {
//     setSystemFiltersVisible(!systemFiltersVisible);
//   };

//   const handleFilesChange = (files) => {
//     setAttachments(files.map(file => ({
//       name: file.name,
//       size: file.size,
//       url: URL.createObjectURL(file),
//       uploadedBy: "Vivek Kumar Yadav",
//       dateAdded: new Date().toLocaleString()
//     })));
//   };

//   const handleTabChange = (event, newValue) => {
//     setActiveTab(newValue);
//   };

//   if (!lead) return <Typography>Loading...</Typography>;

//   return (
//     <Box sx={{ p: 2, mt: 10 }}>
//     <Helmet>
//       <title>{lead.leadname} (Leads) - Contacttab.com</title>
//     </Helmet>
//       <Grid container alignItems="center" spacing={2} style={{ background: 'green', padding: '10px', position: 'fixed', zIndex: '1000', width: '100%' }}>
//         <Grid item>
//           <Avatar alt={lead.leadname} src={lead.photoUrl} sx={{ width: 56, height: 56 }} />
//         </Grid>
//         <Grid item>
//           <Typography variant="h5">{lead.leadname}</Typography>
//           <Typography variant="subtitle1">{lead.company}</Typography>
//         </Grid>
//         <Grid item xs>
//           <Box display="flex" justifyContent="flex-end" gap={1}>
//             <Button variant="contained" color="primary" onClick={handleSendEmail}>Send Email</Button>
//             <Button variant="contained">Convert</Button>
//             <Button variant="contained">Edit</Button>
//             <Button variant="contained" onClick={handleMenuOpen}><BsThreeDots /></Button>
//             <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
//               <MenuItem onClick={handleMenuClose}>Clone</MenuItem>
//               <MenuItem onClick={handleMenuClose}>Delete</MenuItem>
//               <MenuItem onClick={handleMenuClose}>Print Preview</MenuItem>
//               <MenuItem onClick={handleMenuClose}>Find and Merge Duplicates</MenuItem>
//               <MenuItem onClick={handleMenuClose}>Customize Business Card</MenuItem>
//               <MenuItem onClick={handleMenuClose}>Organize Lead Details</MenuItem>
//             </Menu>
//           </Box>
//         </Grid>
//       </Grid>

//       <Grid container columnGap={5} sx={{ mt: 10 }}>
//         <Grid item xs={2}>
//           <Paper sx={{ p: 2 }} style={{ height: '70vh' }}>
//             <Typography variant="h6">Related List</Typography>
//             <Typography onClick={() => scrollToSection(notesRef, 'Notes')}>Notes</Typography>
//             <Typography onClick={() => scrollToSection(attachmentsRef, 'Attachments')}>Attachments</Typography>
//             <Typography onClick={() => scrollToSection(openActivitiesRef, 'Open Activities')}>Open Activities</Typography>
//             <Typography onClick={() => scrollToSection(closedActivitiesRef, 'Closed Activities')}>Closed Activities</Typography>
//             <Typography>Invited Meetings</Typography>
//             <Typography>Emails</Typography>
//             <Typography>Campaigns</Typography>
//             <Typography>Social</Typography>
//           </Paper>
//         </Grid>

//         <Grid item xs={9.5}>
//           <Paper sx={{ p: 2, overflowY: 'auto', height: '72vh', overflow: 'auto' }}>
//             <Tabs value={activeTab} onChange={handleTabChange} style={{ color: 'grey' }}>
//               <Tab label="Overview" />
//               <Tab label="Timeline" />
//             </Tabs>
//             {activeTab === 0 && (
//               <Box sx={{ mt: 2 }}>
//                 <Typography variant="h6">Lead Information</Typography>
//                 <Typography>Lead Owner: {lead.leadname}</Typography>
//                 <Typography>Email: {lead.leademail}</Typography>
//                 <Typography>Phone: {lead.contactno}</Typography>
//                 <Typography>Lead Status: {lead.leadstate}</Typography>

//                 <Grid container spacing={2} sx={{ mt: 2 }}>
//                   <Grid item xs={12}>
//                     <Box sx={{ mt: 2 }} style={{ background: 'grey' }}>
//                       <Button onClick={handleShowDetails}>
//                         <ArrowRightIcon style={{ transform: arrowRotated ? 'rotate(90deg)' : 'none', transition: 'transform 0.3s' }} />
//                         {showDetails ? "Hide Details" : "Show Details"}
//                       </Button>

//                       {showDetails && (
//                         <Box>
//                           <Typography variant="h5">Lead Information</Typography>
//                           <Grid container spacing={2}>
//                             <Grid item xs={6}>
//                               <Typography>Lead Owner: {lead.leadname}</Typography>
//                               <Typography>Title: {lead1.title}</Typography>
//                               <Typography>Phone: {lead.contactno}</Typography>
//                               <Typography>Lead Source: {lead1.leadSource}</Typography>
//                               <Typography>Industry: {lead1.industry}</Typography>
//                               <Typography>Annual Revenue: {lead1.annualRevenue}</Typography>
//                             </Grid>
//                             <Grid item xs={6}>
//                               <Typography>Company: {lead1.company}</Typography>
//                               <Typography>Lead Name: {lead1.name}</Typography>
//                               <Typography>Email: {lead1.email}</Typography>
//                               <Typography>Website: {lead1.website}</Typography>
//                               <Typography>Lead Status: {lead1.leadStatus}</Typography>
//                             </Grid>
//                           </Grid>
//                           <Typography variant="h5" sx={{ mt: 2 }}>Address Information</Typography>
//                           <Grid container spacing={2}>
//                             <Grid item xs={6}>
//                               <Typography>Street: {lead1.address.street}</Typography>
//                               <Typography>State: {lead1.address.state}</Typography>
//                               <Typography>Country: {lead1.address.country}</Typography>
//                             </Grid>
//                             <Grid item xs={6}>
//                               <Typography>City: {lead1.address.city}</Typography>
//                               <Typography>Zip Code: {lead1.address.zipCode}</Typography>
//                             </Grid>
//                           </Grid>
//                         </Box>
//                       )}
//                     </Box>
//                   </Grid>
//                 </Grid>

//                 <Grid container spacing={2} sx={{ mt: 2 }}>
//                   <Grid item xs={12} ref={notesRef}>
//                     <Paper sx={{ p: 2, backgroundColor: highlightedSection === 'Notes' ? 'yellow' : 'grey' }}>
//                       <Typography variant="h6" sx={{ p: 2, backgroundColor: highlightedSection === 'Notes' ? 'yellow' : 'grey' }}>Notes</Typography>
//                       <Box component="form">
//                         <FormControl fullWidth>
//                           <TextField
//                             id="add-note"
//                             label="Add a note..."
//                             multiline
//                             rows={4}
//                             variant="outlined"
//                           />
//                         </FormControl>
//                       </Box>
//                     </Paper>
//                   </Grid>
//                 </Grid>

//                 <Grid container spacing={2} sx={{ mt: 2 }}>
//                   <Grid item xs={12} ref={attachmentsRef}>
//                     <Paper sx={{ p: 2, backgroundColor: highlightedSection === 'Attachments' ? 'yellow' : 'grey' }}>
//                       <Typography variant="h6">Attachments</Typography>
//                       <Button variant="outlined" onClick={handleAttachOpen}>Attach</Button>
//                       <Menu
//                         anchorEl={attachAnchorEl}
//                         open={Boolean(attachAnchorEl)}
//                         onClose={handleAttachClose}
//                       >
//                         <MenuItem onClick={handleUploadClick}>Upload File</MenuItem>
//                         {/* <MenuItem onClick={handleAttachClose}>Attach from Cloud</MenuItem> */}
//                       </Menu>
//                       <Dialog open={openUploadDialog} onClose={handleCloseUploadDialog}>
//                         <DialogTitle>Attach File</DialogTitle>
//                         <DialogContent>
//                           <DialogContentText>
//                             Click here or drop files here to attach.
//                           </DialogContentText>
//                           <DropzoneArea
//                             onChange={handleFilesChange}
//                             acceptedFiles={['image/*', 'application/pdf']}
//                             showPreviews={false}
//                             showFileNames
//                             maxFileSize={5000000}
//                             filesLimit={1}
//                           />
//                         </DialogContent>
//                         <DialogActions>
//                           <Button onClick={handleCloseUploadDialog}>Cancel</Button>
//                           <Button onClick={handleCloseUploadDialog}>Upload</Button>
//                         </DialogActions>
//                       </Dialog>
//                       <List>
//                         {attachments.map((file, index) => (
//                           <ListItem key={index}>
//                             <ListItemAvatar>
//                               <Avatar>
//                                 <BsPaperclip />
//                               </Avatar>
//                             </ListItemAvatar>
//                             <ListItemText
//                               primary={file.name}
//                               secondary={`Size: ${(file.size / 1024).toFixed(2)} KB | Uploaded by: ${file.uploadedBy} on ${file.dateAdded}`}
//                             />
//                             <IconButton edge="end" onClick={() => window.open(file.url, '_blank')}>
//                               <BsPaperclip />
//                             </IconButton>
//                           </ListItem>
//                         ))}
//                       </List>
//                     </Paper>
//                   </Grid>
//                 </Grid>

//                 <Grid container spacing={2} sx={{ mt: 2 }}>
//                   <Grid item xs={12} ref={openActivitiesRef}>
//                     <Paper sx={{ p: 2, backgroundColor: highlightedSection === 'Open Activities' ? 'yellow' : 'grey' }}>
//                       <Typography variant="h6">Open Activities</Typography>
//                       <Typography>No open activities available.</Typography>
//                     </Paper>
//                   </Grid>
//                 </Grid>

//                 <Grid container spacing={2} sx={{ mt: 2 }}>
//                   <Grid item xs={12} ref={closedActivitiesRef}>
//                     <Paper sx={{ p: 2, backgroundColor: highlightedSection === 'Closed Activities' ? 'yellow' : 'grey' }}>
//                       <Typography variant="h6">Closed Activities</Typography>
//                       <Typography>No closed activities available.</Typography>
//                     </Paper>
//                   </Grid>
//                 </Grid>

//                 <Dialog open={openEmailDialog} onClose={handleCloseEmailDialog} fullWidth maxWidth="md">
//                   <DialogTitle>New Message</DialogTitle>
//                   <DialogContent>
//                     <TextField fullWidth label="To" value={lead.leademail} margin="normal" />
//                     <TextField fullWidth label="Subject" margin="normal" />
//                     <TextField fullWidth multiline rows={10} label="Message" margin="normal" />
//                   </DialogContent>
//                   <DialogActions>
//                     <Button onClick={handleCloseEmailDialog}>Cancel</Button>
//                     <Button onClick={handleCloseEmailDialog} variant="contained" color="primary">Send</Button>
//                   </DialogActions>
//                 </Dialog>
//               </Box>
//             )}
//             {activeTab === 1 && (
//               <Box sx={{ mt: 2 }}>
//                 {/* <Typography variant="h6">Timeline</Typography> */}
//                 <div>
//         {leadData ? (
//           <div>
//             <h2>User Timeline</h2>
//             <ul>
//               {Object.entries(leadData).map(([fieldName, value]) => (
//                 <li key={fieldName}>
//                   {typeof value === 'object' && value !== null ? (
//                     <>
//                       {/* {fieldName}: */}
//                       <ul>
//                         <li>
//                         {fieldName}  was updated from {value.oldValue !== undefined ? value.oldValue : 'blank'} to {value.newValue !== undefined ? value.newValue : 'blank'}
//                         </li>
//                       </ul>
//                     </>
//                   ) : (
//                     `${fieldName} was updated from blank value to : ${value}`

                    
//                   )}
//                 </li>
//               ))}
//             </ul>
//           </div>
//         ) : (
//           <div>Loading...</div>
//         )}
//       </div>
//               </Box>
//             )}
//           </Paper>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// export default LeadDetails;


import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Helmet } from 'react-helmet-async';
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";

import {
  TextField,
  Button,
  Grid,
  Typography,
  Divider,
  Box,
  Snackbar,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useParams } from "react-router-dom";
import axios from "../../../utils/axios";
import { useUser } from "../../../contexts/auth";
import Header from "../../../assets/charts/Header";
// import { AuthContext } from "../../../contexts/auth";
// import { useUser } from "../../../Provider";
import '../../../App.css'

const LeadDetails = () => {
  const { lead } = useParams();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [leadImage, setLeadImage] = useState(null);
  const {
    userrole,
    username,
    userid,
    companycode,
    isAuthRole,
    isAuthorized,
    token,
  } = useUser();

  const navigate = useNavigate();
  const initialValues = {
    leadname: "",
    contactno: "",
    leademail: "",
    leadlocation: "",
    leadsource: "",
    leadcomments: "",
    status: "active",
    leadstatus: "None",
    rating: "Active",
    fax: "",
    website: "",
    industry: "None",
    employeenumber:"",
    annualrevenue: "",
    twitter: "",
    skypeid: "",
    secondaryemail: "",
    street:"",
    city:"",
    state:"",
    zipcode:"",
    country:"",
    leadcomments:"",
    companyCodes:
      userrole === "Superadmin" && companycode === 1 ? "" : companycode, // Initialize company code
  };
  const [companyCodes, setCompanyCodes] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  useEffect(() => {
    const fetchLeadData = async () => {
      try {
        const response = await axios.get(`/getLeadById/${lead}`);
       console.log(response.data)
       const leadData = response.data;
        if (response && response.data) {
          setValues({
            ...leadData,
           
          });
          console.log(response.data)
        } else {
          // Handle error for failed request
        }
      } catch (error) {
        // Handle network errors
      }
    };

    fetchLeadData();
  }, [lead]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/getCompanyRegistrations");
        console.log(response.data);
        const formattedCodes = response.data.map(
          (company) => company.establishmentid
        );
        setCompanyCodes(formattedCodes);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const ValidationSchema = yup.object().shape({
    leadname: yup.string().required("Lead name is required"),
    contactno: yup
      .string()
      .matches(/^[0-9]+$/, "Mobile Number should only contain numbers")
      .min(10, "Mobile Number must be at least 10 characters")
      .max(10, "Mobile Number must be at most 10 characters")
      .required("Phone Number is required"),
    leademail: yup
      .string()
      .email("Email must be a valid")
      .required("Please enter the email"),
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    setValues,
    handleChange,
    handleReset,
    handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema: ValidationSchema,
    onSubmit: async () => {
      try {
        if (userrole !== "Superadmin" || companycode !== 1) {
          values.companyCodes = companycode;
        }
        const leadDataWithUsername = {
          ...values,
          username: username,
        };

        const response = await axios.post(`/updateLeadById/${lead}`, leadDataWithUsername);

        if (response.status === 201) {
          // Update state with success message
          setSuccessMessage("Lead data added successful");
          setOpenSuccess(true);
          // sendMsg();
          // Hide the success message after 3 seconds (adjust duration as needed)
          setTimeout(() => {
            setOpenSuccess(false);
            setSuccessMessage("Lead data not added");
          }, 3000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);

        // Handle specific error cases
        if (error.response) {
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
        }
      }
    },
  });

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setLeadImage(URL.createObjectURL(event.target.files[0]));
    }
  };

  return (
    <>
    <Helmet>
      <title>Create Lead - Contacttab.com</title>
    </Helmet>
      <form onSubmit={handleSubmit}>
      {/* <Box display="flex" alignItem="center" justifyContent="space-between" position="fixed" bgcolor="white" style={{ width: '100%', zIndex: 1000, padding: '10px 20px', }}>
          <Header style={{color: 'black'}} title="Create Lead" />
          <Box>
            <Button type="submit" color="secondary" variant="contained" style={{ marginLeft: '10px' }}>
              SUBMIT
            </Button>
            <Button type="button" color="secondary" variant="contained" style={{ marginLeft: '10px' }}>
              Cancel
            </Button>
          </Box>
        </Box> */}
        <Box m="20px">
          <h4>Lead Image</h4>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <input
            accept="image/*"
            id="lead-image-upload"
            type="file"
            style={{ display: 'none' }}
            onChange={handleImageChange}
          />
          <label htmlFor="lead-image-upload">
            <Button
              variant="contained"
              component="span"
            >
              Upload Image
            </Button>
          </label>
          {leadImage && (
            <Box sx={{ ml: 2 }}>
              <img src={leadImage} alt="lead" style={{ width: 100, height: 100, objectFit: 'cover' }} />
            </Box>
          )}
        </Box>
          <h4>Lead Information</h4>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Name"
              // onChange={handleChange}
              value={values.leadname}
              name="leadname"
              error={touched.leadname && !!errors.leadname} // Show error style if touched and error exists
              helperText={
                touched.leadname && errors.leadname
                  ? "leadname is required"
                  : ""
              }
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Phone Number"
              // onChange={handleChange}
              value={values.contactno}
              name="contactno"
              error={touched.contactno && !!errors.contactno} // Show error style if touched and error exists
              helperText={
                touched.contactno && errors.contactno
                  ? "contactno is required"
                  : ""
              }
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Email"
              // onChange={handleChange}
              value={values.leademail}
              name="leademail"
              error={touched.leademail && !!errors.leademail} // Show error style if touched and error exists
              helperText={
                touched.leademail && errors.leademail
                  ? "leademail is required"
                  : ""
              }
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Location"
              // onChange={handleChange}
              value={values.leadlocation}
              name="leadlocation"
              // error={!!touched.leadlocation && !!errors.leadlocation}
              // helperText={touched.leadlocation && errors.leadlocation}
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Fax"
              // onChange={handleChange}
              value={values.fax}
              name="fax"
              // error={!!touched.fax && !!errors.fax}
              // helperText={touched.fax && errors.fax}
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Website"
              // onChange={handleChange}
              value={values.website}
              name="website"
              // error={!!touched.website && !!errors.website}
              // helperText={touched.website && errors.website}
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Lead Source"
              // onChange={handleChange}
              value={values.leadsource}
              name="leadsource"
              // error={!!touched.leadsource && !!errors.leadsource}
              // helperText={touched.leadsource && errors.leadsource}
              sx={{ gridColumn: "span 2" }}
            />
             <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Companycode"
              value={values.companycode}
              name="companycode"
              // error={!!touched.leadsource && !!errors.leadsource}
              // helperText={touched.leadsource && errors.leadsource}
              sx={{ gridColumn: "span 2" }}
            />

            <FormControl
              fullWidth
              variant="filled"
              sx={{ gridColumn: "span 2" }}
            >
              <InputLabel id="leadstatus-label">Lead Status</InputLabel>
              <Select
                labelId="leadstatus-label"
                id="leadstatus"
                // onChange={handleChange}
                value={values.leadstatus}
                name="leadstatus"
                error={touched.leadstatus && !!errors.leadstatus}
                label="Lead Status"
                MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
              >
                <MenuItem value="None">None</MenuItem>
                <MenuItem value="Attempted to Contact">
                  Attempted to Contact
                </MenuItem>
                <MenuItem value="Contact in Future">Contact in Future</MenuItem>
                <MenuItem value="Contacted">Contacted</MenuItem>
                <MenuItem value="Junk Lead">Junk Lead</MenuItem>
                <MenuItem value="Lost Lead">Lost Lead</MenuItem>
                <MenuItem value="Not Contacted">Not Contacted</MenuItem>
              </Select>
            </FormControl>


            <FormControl
              fullWidth
              variant="filled"
              sx={{ gridColumn: "span 2" }}
            >
              <InputLabel id="leadstatus-label">Industry</InputLabel>
              <Select
                labelId="industry-label"
                id="industry"
                // onChange={handleChange}
                value={values.industry}
                name="industry"
                error={touched.industry && !!errors.industry}
                label="Industry"
                MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
              >
                 <MenuItem value="None">-None-</MenuItem>
                <MenuItem value="ASP (Application Service Provider)">
                ASP (Application Service Provider)
                </MenuItem>
                <MenuItem value="Data/Telecom OEM">Data/Telecom OEM</MenuItem>
                <MenuItem value="ERP (Enterprise Resource Planning)">ERP (Enterprise Resource Planning)</MenuItem>
                <MenuItem value="Government/Military">Government/Military</MenuItem>
                <MenuItem value="Large Enterprise">Large Enterprise</MenuItem>
                <MenuItem value="ManagementISV">ManagementISV</MenuItem>
                <MenuItem value="MSP (Management Service Provider)">MSP (Management Service Provider)</MenuItem>
                <MenuItem value="Network Equipment Enterprise">Network Equipment Enterprise</MenuItem>
                <MenuItem value="Non-management ISV">Non-management ISV</MenuItem>
                <MenuItem value="Optical Networking">Optical Networking</MenuItem>
                <MenuItem value="Service Provider">Service Provider</MenuItem>
                <MenuItem value="Small/Medium Enterprise">Small/Medium Enterprise</MenuItem>
                <MenuItem value="Storage Equipment">Storage Equipment</MenuItem>
                <MenuItem value="Storage Service Provider">Storage Service Provider</MenuItem>
                <MenuItem value="Systems Integrator">Systems Integrator</MenuItem>
                <MenuItem value="Wireless Industry">Wireless Industry</MenuItem>
                <MenuItem value="ERP">ERP</MenuItem>
                <MenuItem value="Management ISV">Management ISV</MenuItem>
              </Select>
            </FormControl>

            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="No. of Employees"
              // onChange={handleChange}
              value={values.employeenumber}
              name="employeenumber"
              error={touched.employeenumber && !!errors.employeenumber} // Show error style if touched and error exists
              helperText={
                touched.employeenumber && errors.employeenumber
                  ? "employeenumber is required"
                  : ""
              }
              sx={{ gridColumn: "span 2" }}
            />

<TextField
              fullWidth
              variant="filled"
              type="text"
              label="Annual Revenue"
              // onChange={handleChange}
              value={values.annualrevenue}
              name="annualrevenue"
              error={touched.annualrevenue && !!errors.annualrevenue} // Show error style if touched and error exists
              helperText={
                touched.annualrevenue && errors.annualrevenue
                  ? "annualrevenue is required"
                  : ""
              }
              sx={{ gridColumn: "span 2" }}
            />

            
            <FormControl
              fullWidth
              variant="filled"
              sx={{ gridColumn: "span 2" }}
            >
              <InputLabel id="rating-label">Rating</InputLabel>
              <Select
                labelId="rating-label"
                id="rating"
                // onChange={handleChange}
                value={values.rating}
                name="rating"
                error={touched.rating && !!errors.rating}
                label="Rating"
                MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
              >
                <MenuItem value="">-None-</MenuItem>
                <MenuItem value="Acquired">Acquired</MenuItem>
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Market Failed">Market Failed</MenuItem>
                <MenuItem value="Project Cancelled">Project Cancelled</MenuItem>
                <MenuItem value="Shut Down">Shut Down</MenuItem>
              </Select>
            </FormControl>

            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Skype ID"
              // onChange={handleChange}
              value={values.skypeid}
              name="skypeid"
              error={touched.skypeid && !!errors.skypeid} // Show error style if touched and error exists
              helperText={
                touched.skypeid && errors.skypeid ? "skypeid is required" : ""
              }
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Secondary Email"
              // onChange={handleChange}
              value={values.secondaryemail}
              name="secondaryemail"
              error={touched.secondaryemail && !!errors.secondaryemail} // Show error style if touched and error exists
              helperText={
                touched.secondaryemail && errors.secondaryemail ? "secondaryemail is required" : ""
              }
              sx={{ gridColumn: "span 2" }}
            />
             <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Twitter"
              // onChange={handleChange}
              value={values.twitter}
              name="twitter"
              error={touched.twitter && !!errors.twitter} // Show error style if touched and error exists
              helperText={
                touched.twitter && errors.twitter ? "twitter is required" : ""
              }
              sx={{ gridColumn: "span 2" }}
            />
          </Box>

          <h4 style={{margin: '30px 0px'}}>Address Information</h4>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Street"
              // onChange={handleChange}
              value={values.street}
              name="street"
              // error={touched.street && !!errors.street} // Show error style if touched and error exists
              // helperText={
              //   touched.street && errors.street
              //     ? "street is required"
              //     : ""
              // }
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="City"
              // onChange={handleChange}
              value={values.city}
              name="city"
              // error={touched.city && !!errors.city} // Show error style if touched and error exists
              // helperText={
              //   touched.city && errors.city
              //     ? "city is required"
              //     : ""
              // }
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="State"
              // onChange={handleChange}
              value={values.state}
              name="state"
              // error={touched.leademail && !!errors.leademail} // Show error style if touched and error exists
              // helperText={
              //   touched.leademail && errors.leademail
              //     ? "leademail is required"
              //     : ""
              // }
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Zip Code"
              // onChange={handleChange}
              value={values.zipcode}
              name="zipcode"
              // error={!!touched.leadlocation && !!errors.leadlocation}
              // helperText={touched.leadlocation && errors.leadlocation}
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Country"
              // onChange={handleChange}
              value={values.country}
              name="country"
              // error={!!touched.fax && !!errors.fax}
              // helperText={touched.fax && errors.fax}
              sx={{ gridColumn: "span 4" }}
            />

          </Box>

          <h4 style={{margin: '30px 0px'}}>Description Information</h4>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Comment"
              // onChange={handleChange}
              value={values.leadcomments}
              name="leadcomments"
              // error={!!touched.leadcomments && !!errors.leadcomments}
              // helperText={touched.leadcomments && errors.leadcomments}
              sx={{ gridColumn: "span 4" }}
            />  
          </Box>
          <Box display="flex" justifyContent="end" mt="20px" mb="50px">
            {/* <Button type="submit" color="secondary" variant="contained">
              SUBMIT
            </Button> */}
          </Box>
        </Box>
      </form>
      <br/>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSuccess}
        autoHideDuration={3000}
        // onClose={handleSuccessClose}
        message={successMessage}
      />
    </>
  );
};

export default LeadDetails;
