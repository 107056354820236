import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand, faSyncAlt, faClock, faHistory, faCog } from '@fortawesome/free-solid-svg-icons';
import './footerStyle.css'; // Make sure to create this CSS file
import { RiStickyNoteAddLine } from "react-icons/ri";
import { TfiAnnouncement } from "react-icons/tfi";
import { PiNotebookDuotone } from "react-icons/pi";
import { LiaQuestionCircleSolid } from "react-icons/lia";

const Footer = () => {
  return (
    <footer className="crm-footer">
      {/* <div className="footer-left">
        <span>1 - 10</span>
        <button className="nav-button" disabled>&lt;</button>
        <button className="nav-button">&gt;</button>
      </div> */}
      <div className="footer-right">
        <button className="icon-button" title="Announcement">
        <TfiAnnouncement /> |
        </button>
        <button className="icon-button" title="Sticky Notes">
          {/* <FontAwesomeIcon icon={faSyncAlt} /> */}
          <RiStickyNoteAddLine /> |
        </button>
        <button className="icon-button" title="Activity Reminders">
          <FontAwesomeIcon icon={faClock} /> |
        </button>
        <button className="icon-button" title="Recent Items">
          <FontAwesomeIcon icon={faHistory} /> |
        </button>
        <button className="help-button">Help</button>
        <button className="icon-button" title="BBS Notebook">
        | <PiNotebookDuotone />
        </button>
      </div>
    </footer>
  );
};

export default Footer;