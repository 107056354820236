import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Helmet } from 'react-helmet-async';
import {
  TextField,
  Button,
  Grid,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  styled,
} from "@mui/material";

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: 'white',
      color: 'white'
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
}));

const CustomSelect = styled(Select)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
}));

const CreateDeal = () => {
  const validationSchema = yup.object({
    dealName: yup.string().required("Deal Name is required"),
  });

  const formik = useFormik({
    initialValues: {
      dealOwner: "Vivek Kumar Yadav",
      dealName: "",
      accountName: "",
      amount: "",
      closingDate: "",
      stage: "Qualification",
      type: "-None-",
      probability: "10",
      nextStep: "",
      expectedRevenue: "",
      leadSource: "-None-",
      campaignSource: "",
      contactName: "",
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
        console.log("Form Values:", values);
        // Here you would typically make an API call to save the contact
        // After successful submission, reset the form
        resetForm();
      },
  });

  return (
    <>
    <Helmet>
      <title>Create Deal - Contacttab.com</title>
    </Helmet>

    <form onSubmit={formik.handleSubmit}>
      <Box m="20px">
        <h1>Create Deal</h1>

        <h2>Deal Information</h2>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Deal Owner</InputLabel>
              <CustomSelect
                name="dealOwner"
                value={formik.values.dealOwner}
                onChange={formik.handleChange}
              >
                <MenuItem value="Vivek Kumar Yadav">Vivek Kumar Yadav</MenuItem>
              </CustomSelect>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="amount"
              label="Amount"
              value={formik.values.amount}
              onChange={formik.handleChange}
              InputProps={{
                startAdornment: '$',
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="dealName"
              label="Deal Name"
              value={formik.values.dealName}
              onChange={formik.handleChange}
              error={formik.touched.dealName && Boolean(formik.errors.dealName)}
              helperText={formik.touched.dealName && formik.errors.dealName}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="closingDate"
              label="Closing Date"
              type="date"
              value={formik.values.closingDate}
              onChange={formik.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="accountName"
              label="Account Name"
              value={formik.values.accountName}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Stage</InputLabel>
              <CustomSelect
                name="stage"
                value={formik.values.stage}
                onChange={formik.handleChange}
              >
                <MenuItem value="Qualification">Qualification</MenuItem>
                {/* Add other stages as needed */}
              </CustomSelect>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Type</InputLabel>
              <CustomSelect
                name="type"
                value={formik.values.type}
                onChange={formik.handleChange}
              >
                <MenuItem value="-None-">-None-</MenuItem>
                {/* Add other types as needed */}
              </CustomSelect>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="probability"
              label="Probability (%)"
              value={formik.values.probability}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="nextStep"
              label="Next Step"
              value={formik.values.nextStep}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="expectedRevenue"
              label="Expected Revenue"
              value={formik.values.expectedRevenue}
              onChange={formik.handleChange}
              InputProps={{
                startAdornment: '$',
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Lead Source</InputLabel>
              <CustomSelect
                name="leadSource"
                value={formik.values.leadSource}
                onChange={formik.handleChange}
              >
                <MenuItem value="-None-">-None-</MenuItem>
                {/* Add other lead sources as needed */}
              </CustomSelect>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="campaignSource"
              label="Campaign Source"
              value={formik.values.campaignSource}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth
              name="contactName"
              label="Contact Name"
              value={formik.values.contactName}
              onChange={formik.handleChange}
            />
          </Grid>
        </Grid>

        <h2>Description Information</h2>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomTextField
              fullWidth
              name="description"
              label="Description"
              multiline
              rows={4}
              value={formik.values.description}
              onChange={formik.handleChange}
            />
          </Grid>
        </Grid>

        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button type="button" onClick={() => formik.resetForm()} variant="contained" style={{ margin: '20px', background: 'blue', padding: '5px 10px' }}>
            Cancel
          </Button>
          {/* <Button type="button" variant="contained" style={{ margin: '20px', background: 'blue', padding: '5px 10px' }}>
            Save and New
          </Button> */}
          <Button type="submit" variant="contained" style={{ margin: '20px', background: 'blue', padding: '5px 10px' }}>
            Save
          </Button>
        </Box>
      </Box>
    </form>

    </>
  );
};

export default CreateDeal;