// import React, { useState, useEffect } from "react";
// import { Box, Typography,  IconButton, InputLabel,FormControlLabel, Checkbox, Select, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Paper, Button, Grid, Menu, MenuItem, FormControl } from '@mui/material';
// import { DataGrid } from "@mui/x-data-grid";
// import { tokens } from "../../theme";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";
// import axios from "../../utils/axios";
// import { useNavigate } from "react-router-dom";
// import Header from "../../assets/charts/Header";
// import { useUser } from "../../contexts/auth";
// import { useTheme } from "@mui/material/styles";
// import '../../App.css'
// import ViewListIcon from '@mui/icons-material/ViewList'; // Import the List View icon
// import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
// import GridViewIcon from '@mui/icons-material/GridView';
// import TableViewIcon from '@mui/icons-material/TableView';
// import TocIcon from '@mui/icons-material/Toc';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import ExpandLessIcon from '@mui/icons-material/ExpandLess';
// import ArrowRightIcon from '@mui/icons-material/ArrowRight';

// const AllReport = () => {
//   const [anchorEl, setAnchorEl] = useState(null);
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);
//   const navigate = useNavigate();
//   const [leads, setLeads] = useState([]);
// //   const [accounts, setAccounts] = useState([]);
//   const [openDialog, setOpenDialog] = useState(false);
//   const [deleteLeadId, setDeleteLeadId] = useState(null);
//   const [deleteSuccessMessage, setDeleteSuccessMessage] = useState(null);
//   const { userrole, companycode } = useUser();
//   const [selectedView, setSelectedView] = useState('All Tasks');
//   const [viewAnchorEl, setViewAnchorEl] = useState(null);
//  const [systemFiltersVisible, setSystemFiltersVisible] = useState(true);
//   const [fieldFiltersVisible, setFieldFiltersVisible] = useState(true);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [alphabetFilter, setAlphabetFilter] = useState('');
//   const [nameFilter, setNameFilter] = useState('All');


//   const tasks = [
//     { id: 1, reportName: "Vendors vs Purchases", description: "Vendors Based on Purchases", folder: "Vendor Reports", lastAccessedDate: "-", createdBy: "-"},
//     { id: 2, reportName: "Top 10 Users", description: "Top 10 users based on Mails Sent Rate", folder: "Email Reports", lastAccessedDate: "-", createdBy: "-"}
  
   
//     // More mock data can be added here
//   ];

//   const [filteredAccounts, setFilteredAccounts] = useState(tasks);

//   useEffect(() => {
//     setFilteredAccounts(
//       tasks.filter(account => {
//         if (nameFilter === 'All') return true;
//         return account.subject && account.subject.charAt(0).toUpperCase() === nameFilter;
//       })
//     );
//   }, [nameFilter, tasks]);

  // const systemDefinedFilters = [
  //   "All Reports",
  //   "Favorites",
  //   "Recently Viewed",
  //   "Recently Deleted",
  //   "Account and Contact Reports",
  //   "Deal Reports",
  //   "Lead Reports",
  //   "Compaign Reports",
  //   "Case and Solution Reports",
  //   "Product Reports",
  //   "Vendor Reports",
  //   "Quote Reports",
  //   "Sales Order Reports",
  //   "Purchase Order Reports",
  //   "Invoice Reports",
  //   "Sales Metrics Reports",
  //   "Email Reports",
  //   "Meeting Reports",
  // ];

//   const fieldFilters = [
//     "Closed Time",
//     "Contact Name",
//     "Created By",
//     "Created Time",
//     "Due Date",
//     "Modified By",
//     "Modified Time",
//     "Priority",
//     "Related To",
//     "Status",
//     "Subject",
//     "Tag",
//     "Task Owner",
//   ];

  // const toggleSystemFilters = () => {
  //   setSystemFiltersVisible(!systemFiltersVisible);
  // };

  // const toggleFieldFilters = () => {
  //   setFieldFiltersVisible(!fieldFiltersVisible);
  // };

//   const handleSearchChange = (event) => {
//     setSearchTerm(event.target.value.toLowerCase());
//   };

  // const filteredSystemDefinedFilters = systemDefinedFilters.filter((filter) =>
  //   filter.toLowerCase().includes(searchTerm)
  // );

  // const filteredFieldFilters = fieldFilters.filter((filter) =>
  //   filter.toLowerCase().includes(searchTerm)
  // );
  // const handleViewMenuOpen = (event) => {
  //   setViewAnchorEl(event.currentTarget);
  // };


  // const handleChange = (event) => {
  //   setSelectedView(event.target.value);
  // };

//   const handleViewMenuClose = () => {
//     setViewAnchorEl(null);
//   };

//   const viewMenuOpen = Boolean(viewAnchorEl);


//   const handleNameFilterChange = (event) => {
//     setNameFilter(event.target.value);
//   };



  
// const columns = [
//     { 
//       field: "reportName", 
//       headerName: "Report Name", 
//       flex: 0.3,
//       renderHeader: (params) => (
//         <Box sx={{ display: 'flex', alignItems: 'center' }}>
//           <Typography>{params.colDef.headerName}</Typography>
//           <Select
//             value={nameFilter}
//             onChange={handleNameFilterChange}
//             sx={{ ml: 1, height: 30 }}
//           >
//             <MenuItem value="All">All</MenuItem>
//             {Array.from(Array(26)).map((_, i) => (
//               <MenuItem key={i} value={String.fromCharCode(65 + i)}>
//                 {String.fromCharCode(65 + i)}
//               </MenuItem>
//             ))}
//           </Select>
//         </Box>
//       )
//     },
//     { field: "description", headerName: "	Description", flex: 0.3 },
//     { field: "folder", headerName: "Folder", flex: 0.3 },
//     { field: "lastAccessedDate", headerName: "Last Accessed Date", flex: 0.3 },
//     { field: "createdBy", headerName: "Created By", flex: 0.3 },
//   ];

  // const handleStatusChange = async (event, lead) => {
  //   try {
  //     const updatedLeads = leads.map((leadItem) =>
  //       leadItem.id === lead ? { ...leadItem, leadstatus: event.target.value } : leadItem
  //     );
  //     setLeads(updatedLeads);

  //     const response = await axios.put(
  //       `/updateLeadState/${lead}`,
  //       {
  //         leadstatus: event.target.value,
  //       }
  //     );

  //     if (response && response.status === 200) {
  //       // fetchData();
  //       setDeleteSuccessMessage("Lead status updated successfully!");
  //     } else {
  //       console.error("Failed to update lead status");
  //     }
  //   } catch (error) {
  //     console.error("Error updating lead status:", error);
  //   }
  // };

  // const handleEdit = (lead) => {
  //   navigate(`/updatelead/${lead}`);
  // };

  // const handleDelete = (leadId) => {
  //   setDeleteLeadId(leadId);
  //   setOpenDialog(true);
  // };

  // const handleCloseDialog = () => {
  //   setOpenDialog(false);
  // };

//   const handleConfirmDelete = async () => {
//     try {
//       const response = await axios.delete(`/deleteLead/${deleteLeadId}`);
//       if (response && response.status === 200) {
//         setLeads((prevLeads) =>
//           prevLeads.filter((lead) => lead.id !== deleteLeadId)
//         );
//         setDeleteSuccessMessage("Lead deleted successfully!");
//         setTimeout(() => {
//           setDeleteSuccessMessage(null);
//         }, 3000);
//       } else {
//         console.error("Failed to delete lead");
//       }
//     } catch (error) {
//       console.error("Error deleting lead:", error);
//     }
//     setOpenDialog(false);
//   };

//   const handleMenuOpen = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };

//   const menuOpen = Boolean(anchorEl);

//   return (
//     <Box>
      // {/* Header Section */}
      // {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2, backgroundColor: 'white', color: 'black', marginTop: "5.2%", padding: '5px'}}> */}
 
      // {/* <Select
      //   labelId="lead-select-label"
      //   value={selectedView}
      //   onChange={handleChange}
      //   label="Public Views"
      //   style={{color: 'white'}}
      // >
      //   <MenuItem value="All Tasks">All Tasks</MenuItem>
      //   <MenuItem value="All Locked Tasks">All Locked Tasks</MenuItem>
      //   <MenuItem value="Closed Tasks">Closed Tasks</MenuItem>
      //   <MenuItem value="My Closed Tasks">My Closed Tasks</MenuItem>
      //   <MenuItem value="My Next 7 Days + Overdue Tasks">My Next 7 Days + Overdue Tasks</MenuItem>
      //   <MenuItem value="My Open Tasks">My Open Tasks</MenuItem>
      //   <MenuItem value="My Overdue Tasks">My Overdue Tasks</MenuItem>
      //   <MenuItem value="My Tasks">My Tasks</MenuItem>
      //   <MenuItem value="My Today + Overdue Tasks">My Today + Overdue Tasks</MenuItem>
      //   <MenuItem value="My Today's Tasks">My Today's Tasks</MenuItem>
      //   <MenuItem value="My Tomorrow's Tasks">My Tomorrow's Tasks</MenuItem>
      //   <MenuItem value="Next 7 Days + Overdue Tasks">Next 7 Days + Overdue Tasks</MenuItem>
      //   <MenuItem value="Open Tasks">Open Tasks</MenuItem>
      //   <MenuItem value="Overdue Tasks">Overdue Tasks</MenuItem>
      //   <MenuItem value="Today + Overdue Tasks">Today + Overdue Tasks</MenuItem>
      //   <MenuItem value="Today's Tasks">Today's Tasks</MenuItem>
      //   <MenuItem value="Tomorrow's Tasks">Tomorrow's Tasks</MenuItem>
        
      // </Select> */}

      //   {/* <Box> */}
      //   {/* <Button variant="contained" onClick={handleViewMenuOpen} startIcon={<ViewListIcon />}>List View</Button> Added List View Button */}
      //     {/* <Button variant="contained" color="primary" sx={{ mr: 2 }} onClick={() => navigate('/add-lead')}>Create Task</Button> */}
      //     {/* <Button variant="contained" onClick={handleMenuOpen}>Actions</Button> */}
      //     {/* <Menu anchorEl={anchorEl} open={menuOpen} onClose={handleMenuClose}> */}
      //     {/* <MenuItem onClick={handleMenuClose}>Mass Delete</MenuItem>
      //       <MenuItem onClick={handleMenuClose}>Mass Update</MenuItem>
      //       <MenuItem onClick={handleMenuClose}>Manage Tags</MenuItem>
      //       <MenuItem onClick={handleMenuClose}>Export Tasks</MenuItem>
      //       <MenuItem onClick={handleMenuClose}>Print View</MenuItem> */}
      //     {/* </Menu> */}
      //     {/* <Menu anchorEl={viewAnchorEl} open={viewMenuOpen} onClose={handleViewMenuClose}> Added List View Menu */}
      //       {/* <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><TocIcon /></span> List View</MenuItem> */}
      //       {/* <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><ViewKanbanIcon /></span> Kanban View</MenuItem> */}
      //       {/* <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><ViewListIcon /></span> Custom List View</MenuItem> */}
      //       {/* <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><GridViewIcon /></span> Tile View</MenuItem> */}
      //       {/* <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><TableViewIcon /></span> Table View</MenuItem> */}
      //     {/* </Menu> */}
      //   {/* </Box> */}
      // {/* </Box>  */}

//       {/* Main Content */}
//       <Grid container columnGap={5}> 
     
//         <Grid item xs={2}>
//       <Box
//         sx={{
//           p: 2,
//           borderRight: "1px solid #e0e0e0",
//           height: "89vh",
//           background: "black",
//           overflowY: "auto",
//           marginTop: "29%"
//         }}
//       >
//         <Typography variant="h6" color="white" style={{fontSize: "16px"}}>Folders</Typography>
//         <TextField
//           variant="outlined"
//           placeholder="Search"
//           size="small"
//           fullWidth
//           sx={{ mb: 2 }}
//           value={searchTerm}
//           onChange={handleSearchChange}
//         />

//         <Typography
//           variant="body2"
//           color="white"
//           sx={{ mb: 1, cursor: "pointer", fontSize: "16px"}}
//           onClick={toggleSystemFilters}
//         >
//         {/* System Defined Filters <IconButton
//             sx={{
//               color: "white",
//               transform: systemFiltersVisible ? 'rotate(90deg)' : 'rotate(0deg)',
//               transition: 'transform 0.3s'
//             }}
//           >
//             <ArrowRightIcon />
//           </IconButton> */}
         
//         </Typography>
//         {systemFiltersVisible && (
//           <Box sx={{ display: "flex", flexDirection: "column" }}>
//             {filteredSystemDefinedFilters.map((filter) => (
//               <FormControlLabel
//                 key={filter}
//                 control={<Checkbox />}
//                 label={filter}
//                 sx={{ color: "white" }}
//               />
//             ))}
//           </Box>
//         )}
// {/* 
//         <Typography
//           variant="body2"
//           color="white"
//           sx={{ mt: 2, mb: 1, cursor: "pointer", fontSize: "16px" }}
//           onClick={toggleFieldFilters}
//         > */}
//           {/* Filter By Fields <IconButton
//             sx={{
//               color: "white",
//               transform: fieldFiltersVisible ? 'rotate(90deg)' : 'rotate(0deg)',
//               transition: 'transform 0.3s'
//             }}
//           >
//             <ArrowRightIcon />
//           </IconButton> */}
//         {/* </Typography> */}
//         {/* {fieldFiltersVisible && (
//           <Box sx={{ display: "flex", flexDirection: "column" }}>
//             {filteredFieldFilters.map((filter) => (
//               <FormControlLabel
//                 key={filter}
//                 control={<Checkbox />}
//                 label={filter}
//                 sx={{ color: "white" }}
//               />
//             ))}
//           </Box>
//         )} */}
//       </Box>
//     </Grid>
//             <Grid item xs={9.5}>    
//             <Box>
// <Typography variant="h6" color="white" style={{fontSize: "27px", marginTop: "8%", marginLeft: "1%",  marginBottom: "2%"}}>All Reports</Typography>
// <TextField
//           variant="outlined"
//           placeholder="Search"
//           size="small"
//           fullWidth
//           sx={{ mb: 2, width: '20%' }}
//           value={searchTerm}
//           onChange={handleSearchChange}
//         />
//         <Typography variant="h6" color="white" style={{fontSize: "27px", marginTop: "8%", marginLeft: "1%",  marginBottom: "2%"}}>Advanced Analytics for Zoho CRM
// <span>powered by Zoho Analytics</span>
// </Typography>

// </Box>
//           <Paper elevation={3} style={{ padding: '20px', height: '75vh', marginTop: '5px', color: 'black' }} className="listViewStyle">
//             <Typography variant="h6" sx={{ mb: 2 }} style={{color: 'white'}}>Total Records: {filteredAccounts.length}</Typography>
//             <DataGrid
//               columns={columns}
//               rows={filteredAccounts}
//               pageSize={10}
//               rowsPerPageOptions={[10]}
//               checkboxSelection
//               disableSelectionOnClick
//             />
            
            
//             {/* Confirmation Dialog */}
//             <Dialog open={openDialog} onClose={handleCloseDialog}>
//               <DialogTitle>Confirm Delete</DialogTitle>
//               <DialogContent>Are you sure you want to delete this lead?</DialogContent>
//               <DialogActions>
//                 <Button onClick={handleCloseDialog} color="primary">
//                   No
//                 </Button>
//                 <Button onClick={handleConfirmDelete} color="primary" autoFocus>
//                   Yes
//                 </Button>
//               </DialogActions>
//             </Dialog>
//           </Paper>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// export default AllReport;



// import React, { useState, useEffect } from "react";
// import { Box, Typography,  IconButton, InputLabel,FormControlLabel, Checkbox, Select, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Paper, Button, Grid, Menu, MenuItem, FormControl } from '@mui/material';
// import { DataGrid } from "@mui/x-data-grid";
// import { tokens } from "../../theme";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";
// import axios from "../../utils/axios";
// import { useNavigate } from "react-router-dom";
// import Header from "../../assets/charts/Header";
// import { useUser } from "../../contexts/auth";
// import { useTheme } from "@mui/material/styles";
// import '../../App.css'
// import ViewListIcon from '@mui/icons-material/ViewList'; // Import the List View icon
// import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
// import GridViewIcon from '@mui/icons-material/GridView';
// import TableViewIcon from '@mui/icons-material/TableView';
// import TocIcon from '@mui/icons-material/Toc';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import ExpandLessIcon from '@mui/icons-material/ExpandLess';
// import ArrowRightIcon from '@mui/icons-material/ArrowRight';
// import MoreVertIcon from '@mui/icons-material/MoreVert';

// const AllReport = () => {
//   const [anchorEl, setAnchorEl] = useState(null);
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);
//   const navigate = useNavigate();
//   const [leads, setLeads] = useState([]);
// //   const [accounts, setAccounts] = useState([]);
//   const [openDialog, setOpenDialog] = useState(false);
//   const [deleteLeadId, setDeleteLeadId] = useState(null);
//   const [deleteSuccessMessage, setDeleteSuccessMessage] = useState(null);
//   const { userrole, companycode } = useUser();
//   const [selectedView, setSelectedView] = useState('All Tasks');
//   const [viewAnchorEl, setViewAnchorEl] = useState(null);
//  const [systemFiltersVisible, setSystemFiltersVisible] = useState(true);
//   const [fieldFiltersVisible, setFieldFiltersVisible] = useState(true);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [searchTerm1, setSearchTerm1] = useState("");
//   const [alphabetFilter, setAlphabetFilter] = useState('');
//   const [nameFilter, setNameFilter] = useState('All');

//   const tasks = [
//     { id: 1, reportName: "Vendors vs Purchases", description: "Vendors Based on Purchases", folder: "Vendor Reports", lastAccessedDate: "-", createdBy: "-" },
//     { id: 2, reportName: "Top 10 Users", description: "Top 10 users based on Mails Sent Rate", folder: "Email Reports", lastAccessedDate: "-", createdBy: "-" }
//     // More mock data can be added here
//   ];

//   const [filteredAccounts, setFilteredAccounts] = useState(tasks);

//   useEffect(() => {
//     setFilteredAccounts(
//       tasks.filter(account => {
//         if (nameFilter === 'All') return true;
//         return account.subject && account.subject.charAt(0).toUpperCase() === nameFilter;
//       })
//     );
//   }, [nameFilter, tasks]);

  
  // const systemDefinedFilters = [
  //   "All Reports",
  //   "Favorites",
  //   "Recently Viewed",
  //   "Recently Deleted",
  //   "Account and Contact Reports",
  //   "Deal Reports",
  //   "Lead Reports",
  //   "Compaign Reports",
  //   "Case and Solution Reports",
  //   "Product Reports",
  //   "Vendor Reports",
  //   "Quote Reports",
  //   "Sales Order Reports",
  //   "Purchase Order Reports",
  //   "Invoice Reports",
  //   "Sales Metrics Reports",
  //   "Email Reports",
  //   "Meeting Reports",
  // ];

  //   const fieldFilters = [
  //   "Closed Time",
  //   "Contact Name",
  //   "Created By",
  //   "Created Time",
  //   "Due Date",
  //   "Modified By",
  //   "Modified Time",
  //   "Priority",
  //   "Related To",
  //   "Status",
  //   "Subject",
  //   "Tag",
  //   "Task Owner",
  // ];



  // const toggleSystemFilters = () => {
  //   setSystemFiltersVisible(!systemFiltersVisible);
  // };

  // const toggleFieldFilters = () => {
  //   setFieldFiltersVisible(!fieldFiltersVisible);
  // };


  // const filteredSystemDefinedFilters = systemDefinedFilters.filter((filter) =>
  //   filter.toLowerCase().includes(searchTerm)
  // );

  // const filteredSystemDefinedFilters1 = tasks.filter((filter) =>
  //   filter.toLowerCase().includes(searchTerm1)
  // );

  // const filteredFieldFilters = fieldFilters.filter((filter) =>
  //   filter.toLowerCase().includes(searchTerm)
  // );
  // const handleViewMenuOpen = (event) => {
  //   setViewAnchorEl(event.currentTarget);
  // };


  // const handleChange = (event) => {
  //   setSelectedView(event.target.value);
  // };

  // const handleSearchChange = (event) => {
  //   setSearchTerm(event.target.value.toLowerCase());
  // };

  // const handleSearchChange1 = (event) => {
  //   setSearchTerm1(event.target.value.toLowerCase());
  // };

  // const handleMenuOpen = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleMenuClose = () => {
  //   setAnchorEl(null);
  // };

  // const handleNameFilterChange = (event) => {
  //   setNameFilter(event.target.value);
  // };

  // const handleEdit = (lead) => {
  //   navigate(`/updatelead/${lead}`);
  // };

  // const handleDelete = (leadId) => {
  //   setDeleteLeadId(leadId);
  //   setOpenDialog(true);
  // };

  // const handleCloseDialog = () => {
  //   setOpenDialog(false);
  // };


  // const handleStatusChange = async (event, lead) => {
  //   try {
  //     const updatedLeads = leads.map((leadItem) =>
  //       leadItem.id === lead ? { ...leadItem, leadstatus: event.target.value } : leadItem
  //     );
  //     setLeads(updatedLeads);

  //     const response = await axios.put(
  //       `/updateLeadState/${lead}`,
  //       {
  //         leadstatus: event.target.value,
  //       }
  //     );

  //     if (response && response.status === 200) {
  //       // fetchData();
  //       setDeleteSuccessMessage("Lead status updated successfully!");
  //     } else {
  //       console.error("Failed to update lead status");
  //     }
  //   } catch (error) {
  //     console.error("Error updating lead status:", error);
  //   }
  // };

  


//   const handleConfirmDelete = async () => {
//     try {
//       const response = await axios.delete(`/deleteLead/${deleteLeadId}`);
//       if (response && response.status === 200) {
//         setFilteredAccounts((prevLeads) =>
//           prevLeads.filter((lead) => lead.id !== deleteLeadId)
//         );
//         setDeleteSuccessMessage("Lead deleted successfully!");
//         setTimeout(() => {
//           setDeleteSuccessMessage(null);
//         }, 3000);
//       } else {
//         console.error("Failed to delete lead");
//       }
//     } catch (error) {
//       console.error("Error deleting lead:", error);
//     }
//     setOpenDialog(false);
//   };

//   const columns = [
//     { 
//       field: "reportName", 
//       headerName: "Report Name", 
//       flex: 0.3,
//       renderHeader: (params) => (
//         <Box sx={{ display: 'flex', alignItems: 'center' }}>
//           <Typography>{params.colDef.headerName}</Typography>
//           <Select
//             value={nameFilter}
//             onChange={handleNameFilterChange}
//             sx={{ ml: 1, height: 30 }}
//           >
//             <MenuItem value="All">All</MenuItem>
//             {Array.from(Array(26)).map((_, i) => (
//               <MenuItem key={i} value={String.fromCharCode(65 + i)}>
//                 {String.fromCharCode(65 + i)}
//               </MenuItem>
//             ))}
//           </Select>
//         </Box>
//       )
//     },
//     { field: "description", headerName: "Description", flex: 0.3 },
//     { field: "folder", headerName: "Folder", flex: 0.3 },
//     { field: "lastAccessedDate", headerName: "Last Accessed Date", flex: 0.3 },
//     { field: "createdBy", headerName: "Created By", flex: 0.3 },
//   ];

//   return (
//     <Box>
//       <Grid container columnGap={5}>
//         <Grid item xs={2}>
//           <Box
//             sx={{
//               p: 2,
//               borderRight: "1px solid #e0e0e0",
//               height: "89vh",
//               background: "black",
//               overflowY: "auto",
//               marginTop: "29%"
//             }}
//           >
//             <Typography variant="h6" color="white" style={{ fontSize: "16px" }}>Folders</Typography>
//             <TextField
//               variant="outlined"
//               placeholder="Search"
//               size="small"
//               fullWidth
//               sx={{ mb: 2 }}
//               value={searchTerm}
//               onChange={handleSearchChange}
//             />
//         <Typography
//           variant="body2"
//           color="white"
//           sx={{ mb: 1, cursor: "pointer", fontSize: "16px"}}
//           onClick={toggleSystemFilters}
//         >
//         {/* System Defined Filters <IconButton
//             sx={{
//               color: "white",
//               transform: systemFiltersVisible ? 'rotate(90deg)' : 'rotate(0deg)',
//               transition: 'transform 0.3s'
//             }}
//           >
//             <ArrowRightIcon />
//           </IconButton> */}
         
//         </Typography>
//         {systemFiltersVisible && (
//           <Box sx={{ display: "flex", flexDirection: "column" }}>
//             {filteredSystemDefinedFilters.map((filter) => (
//               <FormControlLabel
//                 key={filter}
//                 control={<Checkbox />}
//                 label={filter}
//                 sx={{ color: "white" }}
//               />
//             ))}
//           </Box>
//         )}
// {/* 
//         <Typography
//           variant="body2"
//           color="white"
//           sx={{ mt: 2, mb: 1, cursor: "pointer", fontSize: "16px" }}
//           onClick={toggleFieldFilters}
//         > */}
//           {/* Filter By Fields <IconButton
//             sx={{
//               color: "white",
//               transform: fieldFiltersVisible ? 'rotate(90deg)' : 'rotate(0deg)',
//               transition: 'transform 0.3s'
//             }}
//           >
//             <ArrowRightIcon />
//           </IconButton> */}
//         {/* </Typography> */}
//         {/* {fieldFiltersVisible && (
//           <Box sx={{ display: "flex", flexDirection: "column" }}>
//             {filteredFieldFilters.map((filter) => (
//               <FormControlLabel
//                 key={filter}
//                 control={<Checkbox />}
//                 label={filter}
//                 sx={{ color: "white" }}
//               />
//             ))}
//           </Box>
//         )} */}
//       </Box>
//     </Grid>
//         <Grid item xs={9.5}>
//           <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '7%' }}>
//             <Typography variant="h6" color="white" style={{ fontSize: "27px", marginLeft: "1%", marginBottom: "2%" }}>All Reports</Typography>
//             <Box sx={{ display: 'flex', alignItems: 'center' }}>
//             <TextField
//   variant="outlined"
//   placeholder="Search"
//   size="small"
//   fullWidth
//   sx={{
//     mb: 2,
//     width: '50%',
//     marginTop: '4%',
//     '& .MuiOutlinedInput-root': {
//       '&.Mui-focused fieldset': {
//         borderColor: 'gray', // border color when focused
//       },
//       '& input': {
//         color: 'white', // text color
//       },
//     },
//   }}
//   value={searchTerm1}
//   onChange={handleSearchChange1}
// />

//             <Typography variant="h6" color="white" style={{ fontSize: "15px", marginLeft: "1%", width: '60%', lineHeight: '1' }}>
//   Advanced Analytics for  CRM
//   <span style={{ fontSize: "16px", marginLeft: "1%", color: "grey" }}>
//  <br/> powered by Zoho Analytics
// </span>
// </Typography>

//               <IconButton onClick={handleMenuOpen}>
//                 <MoreVertIcon style={{ color: 'white' }} />
//               </IconButton>
//               <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
//                 <MenuItem onClick={handleMenuClose}>Help</MenuItem>
//               </Menu>
//             </Box>
//           </Box>
//           <Paper elevation={3} style={{ padding: '20px', height: '75vh', marginTop: '5px', color: 'black' }}>
//             <Typography variant="h6" sx={{ mb: 2 }} style={{ color: 'white' }}>Total Records: {filteredAccounts.length}</Typography>
//             {filteredSystemDefinedFilters1.map((filter) => (
//             <DataGrid
//               key={filter}
//               columns={columns}
//               rows={filteredAccounts}
//               pageSize={10}
//               rowsPerPageOptions={[10]}
//               checkboxSelection
//               disableSelectionOnClick
//             />
//                ))}
//             {/* Confirmation Dialog */}
//             <Dialog open={openDialog} onClose={handleCloseDialog}>
//               <DialogTitle>Confirm Delete</DialogTitle>
//               <DialogContent>Are you sure you want to delete this lead?</DialogContent>
//               <DialogActions>
//                 <Button onClick={handleCloseDialog} color="primary">
//                   No
//                 </Button>
//                 <Button onClick={handleConfirmDelete} color="primary" autoFocus>
//                   Yes
//                 </Button>
//               </DialogActions>
//             </Dialog>
//           </Paper>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// export default AllReport;



// import React, { useState, useEffect } from "react";
// import { Box, Typography,  IconButton, InputLabel,FormControlLabel, Checkbox, Select, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Paper, Button, Grid, Menu, MenuItem, FormControl } from '@mui/material';
// import { DataGrid } from "@mui/x-data-grid";
// import { tokens } from "../../theme";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";
// import axios from "../../utils/axios";
// import { useNavigate } from "react-router-dom";
// import Header from "../../assets/charts/Header";
// import { useUser } from "../../contexts/auth";
// import { useTheme } from "@mui/material/styles";
// import '../../App.css'
// import ViewListIcon from '@mui/icons-material/ViewList'; // Import the List View icon
// import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
// import GridViewIcon from '@mui/icons-material/GridView';
// import TableViewIcon from '@mui/icons-material/TableView';
// import TocIcon from '@mui/icons-material/Toc';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import ExpandLessIcon from '@mui/icons-material/ExpandLess';
// import ArrowRightIcon from '@mui/icons-material/ArrowRight';
// import MoreVertIcon from '@mui/icons-material/MoreVert';

// const AllReport = () => {
//   const [anchorEl, setAnchorEl] = useState(null);
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);
//   const navigate = useNavigate();
//   const [leads, setLeads] = useState([]);
// //   const [accounts, setAccounts] = useState([]);
//   const [openDialog, setOpenDialog] = useState(false);
//   const [deleteLeadId, setDeleteLeadId] = useState(null);
//   const [deleteSuccessMessage, setDeleteSuccessMessage] = useState(null);
//   const { userrole, companycode } = useUser();
//   const [selectedView, setSelectedView] = useState('All Tasks');
//   const [viewAnchorEl, setViewAnchorEl] = useState(null);
//  const [systemFiltersVisible, setSystemFiltersVisible] = useState(true);
//   const [fieldFiltersVisible, setFieldFiltersVisible] = useState(true);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [searchTerm1, setSearchTerm1] = useState("");
//   const [alphabetFilter, setAlphabetFilter] = useState('');
//   const [nameFilter, setNameFilter] = useState('All');


//   const tasks = [
//     { id: 1, reportName: "Vendors vs Purchases", description: "Vendors Based on Purchases", folder: "Vendor Reports", lastAccessedDate: "-", createdBy: "-" },
//     { id: 2, reportName: "Top 10 Users", description: "Top 10 users based on Mails Sent Rate", folder: "Email Reports", lastAccessedDate: "-", createdBy: "-" }
//     // More mock data can be added here
//   ];

//   const [filteredAccounts, setFilteredAccounts] = useState(tasks);

//   useEffect(() => {
//     setFilteredAccounts(
//       tasks.filter(task =>
//         task.reportName.toLowerCase().includes(searchTerm1.toLowerCase())
//       )
//     );
//   }, [searchTerm1, tasks]);

//   const systemDefinedFilters = [
//     "All Reports",
//     "Favorites",
//     "Recently Viewed",
//     "Recently Deleted",
//     "Account and Contact Reports",
//     "Deal Reports",
//     "Lead Reports",
//     "Compaign Reports",
//     "Case and Solution Reports",
//     "Product Reports",
//     "Vendor Reports",
//     "Quote Reports",
//     "Sales Order Reports",
//     "Purchase Order Reports",
//     "Invoice Reports",
//     "Sales Metrics Reports",
//     "Email Reports",
//     "Meeting Reports",
//   ];

//     const fieldFilters = [
//     "Closed Time",
//     "Contact Name",
//     "Created By",
//     "Created Time",
//     "Due Date",
//     "Modified By",
//     "Modified Time",
//     "Priority",
//     "Related To",
//     "Status",
//     "Subject",
//     "Tag",
//     "Task Owner",
//   ];

  // const toggleSystemFilters = () => {
  //   setSystemFiltersVisible(!systemFiltersVisible);
  // };

  // const toggleFieldFilters = () => {
  //   setFieldFiltersVisible(!fieldFiltersVisible);
  // };


  // const filteredSystemDefinedFilters = systemDefinedFilters.filter((filter) =>
  //   filter.toLowerCase().includes(searchTerm)
  // );

  // const filteredSystemDefinedFilters1 = tasks.filter((filter) =>
  //   filter.toLowerCase().includes(searchTerm1)
  // );

  // const filteredFieldFilters = fieldFilters.filter((filter) =>
  //   filter.toLowerCase().includes(searchTerm)
  // );
  // const handleViewMenuOpen = (event) => {
  //   setViewAnchorEl(event.currentTarget);
  // };


//   const handleChange = (event) => {
//     setSelectedView(event.target.value);
//   };

//   const handleSearchChange = (event) => {
//     setSearchTerm(event.target.value.toLowerCase());
//   };

//   const handleSearchChange1 = (event) => {
//     setSearchTerm1(event.target.value.toLowerCase());
//   };

//   const handleMenuOpen = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };

//   const handleNameFilterChange = (event) => {
//     setNameFilter(event.target.value);
//   };

//   const handleEdit = (lead) => {
//     navigate(`/updatelead/${lead}`);
//   };

//   const handleDelete = (leadId) => {
//     setDeleteLeadId(leadId);
//     setOpenDialog(true);
//   };

//   const handleCloseDialog = () => {
//     setOpenDialog(false);
//   };


//   const handleStatusChange = async (event, lead) => {
//     try {
//       const updatedLeads = leads.map((leadItem) =>
//         leadItem.id === lead ? { ...leadItem, leadstatus: event.target.value } : leadItem
//       );
//       setLeads(updatedLeads);

//       const response = await axios.put(
//         `/updateLeadState/${lead}`,
//         {
//           leadstatus: event.target.value,
//         }
//       );

//       if (response && response.status === 200) {
//         // fetchData();
//         setDeleteSuccessMessage("Lead status updated successfully!");
//       } else {
//         console.error("Failed to update lead status");
//       }
//     } catch (error) {
//       console.error("Error updating lead status:", error);
//     }
//   };



//   const handleConfirmDelete = async () => {
//     try {
//       const response = await axios.delete(`/deleteLead/${deleteLeadId}`);
//       if (response && response.status === 200) {
//         setFilteredAccounts((prevLeads) =>
//           prevLeads.filter((lead) => lead.id !== deleteLeadId)
//         );
//         setDeleteSuccessMessage("Lead deleted successfully!");
//         setTimeout(() => {
//           setDeleteSuccessMessage(null);
//         }, 3000);
//       } else {
//         console.error("Failed to delete lead");
//       }
//     } catch (error) {
//       console.error("Error deleting lead:", error);
//     }
//     setOpenDialog(false);
//   };

//   const columns = [
//     { 
//       field: "reportName", 
//       headerName: "Report Name", 
//       flex: 0.3,
//       renderHeader: (params) => (
//         <Box sx={{ display: 'flex', alignItems: 'center' }}>
//           <Typography>{params.colDef.headerName}</Typography>
//           <Select
//             value={nameFilter}
//             onChange={handleNameFilterChange}
//             sx={{ ml: 1, height: 30 }}
//           >
//             <MenuItem value="All">All</MenuItem>
//             {Array.from(Array(26)).map((_, i) => (
//               <MenuItem key={i} value={String.fromCharCode(65 + i)}>
//                 {String.fromCharCode(65 + i)}
//               </MenuItem>
//             ))}
//           </Select>
//         </Box>
//       )
//     },
//     { field: "description", headerName: "Description", flex: 0.3 },
//     { field: "folder", headerName: "Folder", flex: 0.3 },
//     { field: "lastAccessedDate", headerName: "Last Accessed Date", flex: 0.3 },
//     { field: "createdBy", headerName: "Created By", flex: 0.3 },
//   ];

//   return (
//     <Box>
//       <Grid container columnGap={5}>
//         <Grid item xs={2}>
//           <Box
//             sx={{
//               p: 2,
//               borderRight: "1px solid #e0e0e0",
//               height: "89vh",
//               background: "black",
//               overflowY: "auto",
//               marginTop: "29%"
//             }}
//           >
//             <Typography variant="h6" color="white" style={{ fontSize: "16px" }}>Folders</Typography>
//             <TextField
//               variant="outlined"
//               placeholder="Search"
//               size="small"
//               fullWidth
//               sx={{ mb: 2 }}
//               value={searchTerm}
//               onChange={handleSearchChange}
//             />
//         <Typography
//           variant="body2"
//           color="white"
//           sx={{ mb: 1, cursor: "pointer", fontSize: "16px"}}
//           onClick={toggleSystemFilters}
//         >
//         {/* System Defined Filters <IconButton
//             sx={{
//               color: "white",
//               transform: systemFiltersVisible ? 'rotate(90deg)' : 'rotate(0deg)',
//               transition: 'transform 0.3s'
//             }}
//           >
//             <ArrowRightIcon />
//           </IconButton> */}
         
//         </Typography>
//         {systemFiltersVisible && (
//           <Box sx={{ display: "flex", flexDirection: "column" }}>
//             {filteredSystemDefinedFilters.map((filter) => (
//               <FormControlLabel
//                 key={filter}
//                 control={<Checkbox />}
//                 label={filter}
//                 sx={{ color: "white" }}
//               />
//             ))}
//           </Box>
//         )}
// {/* 
//         <Typography
//           variant="body2"
//           color="white"
//           sx={{ mt: 2, mb: 1, cursor: "pointer", fontSize: "16px" }}
//           onClick={toggleFieldFilters}
//         > */}
//           {/* Filter By Fields <IconButton
//             sx={{
//               color: "white",
//               transform: fieldFiltersVisible ? 'rotate(90deg)' : 'rotate(0deg)',
//               transition: 'transform 0.3s'
//             }}
//           >
//             <ArrowRightIcon />
//           </IconButton> */}
//         {/* </Typography> */}
//         {/* {fieldFiltersVisible && (
//           <Box sx={{ display: "flex", flexDirection: "column" }}>
//             {filteredFieldFilters.map((filter) => (
//               <FormControlLabel
//                 key={filter}
//                 control={<Checkbox />}
//                 label={filter}
//                 sx={{ color: "white" }}
//               />
//             ))}
//           </Box>
//         )} */}
//       </Box>
//     </Grid>
//         <Grid item xs={9.5}>
//           <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '7%' }}>
//             <Typography variant="h6" color="white" style={{ fontSize: "27px", marginLeft: "1%", marginBottom: "2%" }}>All Reports</Typography>
//             <Box sx={{ display: 'flex', alignItems: 'center' }}>
//               <TextField
//                 variant="outlined"
//                 placeholder="Search"
//                 size="small"
//                 fullWidth
//                 sx={{
//                   mb: 2,
//                   width: '50%',
//                   marginTop: '4%',
//                   '& .MuiOutlinedInput-root': {
//                     '&.Mui-focused fieldset': {
//                       borderColor: 'gray', // border color when focused
//                     },
//                     '& input': {
//                       color: 'white', // text color
//                     },
//                   },
//                 }}
//                 value={searchTerm1}
//                 onChange={handleSearchChange1}
//               />
//               <Typography variant="h6" color="white" style={{ fontSize: "15px", marginLeft: "1%", width: '60%', lineHeight: '1' }}>
//                 Advanced Analytics for CRM
//                 <span style={{ fontSize: "16px", marginLeft: "1%", color: "grey" }}>
//                   <br /> powered by Zoho Analytics
//                 </span>
//               </Typography>
//               <IconButton onClick={handleMenuOpen}>
//                 <MoreVertIcon style={{ color: 'white' }} />
//               </IconButton>
//               <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
//                 <MenuItem onClick={handleMenuClose}>Help</MenuItem>
//               </Menu>
//             </Box>
//           </Box>
//           <Paper elevation={3} style={{ padding: '20px', height: '75vh', marginTop: '5px', color: 'black' }}>
//             <Typography variant="h6" sx={{ mb: 2 }} style={{ color: 'white' }}>Total Records: {filteredAccounts.length}</Typography>
//             <DataGrid
//               columns={columns}
//               rows={filteredAccounts}
//               pageSize={10}
//               rowsPerPageOptions={[10]}
//               checkboxSelection
//               disableSelectionOnClick
//             />
//             {/* Confirmation Dialog */}
//             <Dialog open={openDialog} onClose={handleCloseDialog}>
//               <DialogTitle>Confirm Delete</DialogTitle>
//               <DialogContent>Are you sure you want to delete this lead?</DialogContent>
//               <DialogActions>
//                 <Button onClick={handleCloseDialog} color="primary">
//                   No
//                 </Button>
//                 <Button onClick={handleConfirmDelete} color="primary" autoFocus>
//                   Yes
//                 </Button>
//               </DialogActions>
//             </Dialog>
//           </Paper>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// export default AllReport;


import React, { useState, useEffect } from "react";
import { Box, Typography,  IconButton, InputLabel,FormControlLabel, Checkbox, Select, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Paper, Button, Grid, Menu, MenuItem, FormControl } from '@mui/material';
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "../../utils/axios";
import { useNavigate } from "react-router-dom";
import Header from "../../assets/charts/Header";
import { useUser } from "../../contexts/auth";
import { useTheme } from "@mui/material/styles";
import '../../App.css'
import ViewListIcon from '@mui/icons-material/ViewList'; // Import the List View icon
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import GridViewIcon from '@mui/icons-material/GridView';
import TableViewIcon from '@mui/icons-material/TableView';
import TocIcon from '@mui/icons-material/Toc';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const AllReport = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [tasks] = useState([
    { id: 1, reportName: "Vendors vs Purchases", description: "Vendors Based on Purchases", folder: "Vendor Reports", lastAccessedDate: "-", createdBy: "-" },
    { id: 2, reportName: "Top 10 Users", description: "Top 10 users based on Mails Sent Rate", folder: "Email Reports", lastAccessedDate: "-", createdBy: "-" }
    // More mock data can be added here
  ]);
  const [filteredAccounts, setFilteredAccounts] = useState(tasks);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTerm1, setSearchTerm1] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const { userrole, companycode } = useUser();
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteLeadId, setDeleteLeadId] = useState(null);
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState(null);
  const colors = tokens(theme.palette.mode);
  const [leads, setLeads] = useState([]);
  const [selectedView, setSelectedView] = useState('All Tasks');
  const [viewAnchorEl, setViewAnchorEl] = useState(null);
 const [systemFiltersVisible, setSystemFiltersVisible] = useState(true);
  const [fieldFiltersVisible, setFieldFiltersVisible] = useState(true);
  const [alphabetFilter, setAlphabetFilter] = useState('');
  const [nameFilter, setNameFilter] = useState('All');


  useEffect(() => {
    setFilteredAccounts(
      tasks.filter(task =>
        task.reportName.toLowerCase().includes(searchTerm1.toLowerCase())
      )
    );
  }, [searchTerm1, tasks]);



  const systemDefinedFilters = [
    "All Reports",
    "Favorites",
    "Recently Viewed",
    "Recently Deleted",
    "Account and Contact Reports",
    "Deal Reports",
    "Lead Reports",
    "Compaign Reports",
    "Case and Solution Reports",
    "Product Reports",
    "Vendor Reports",
    "Quote Reports",
    "Sales Order Reports",
    "Purchase Order Reports",
    "Invoice Reports",
    "Sales Metrics Reports",
    "Email Reports",
    "Meeting Reports",
  ];

  const fieldFilters = [
    "Closed Time",
    "Contact Name",
    "Created By",
    "Created Time",
    "Due Date",
    "Modified By",
    "Modified Time",
    "Priority",
    "Related To",
    "Status",
    "Subject",
    "Tag",
    "Task Owner",
  ];

  const toggleSystemFilters = () => {
    setSystemFiltersVisible(!systemFiltersVisible);
  };

  const toggleFieldFilters = () => {
    setFieldFiltersVisible(!fieldFiltersVisible);
  };


  // const filteredSystemDefinedFilters = systemDefinedFilters.filter((filter) =>
  //   filter.toLowerCase().includes(searchTerm)
  // );

  // const filteredSystemDefinedFilters1 = tasks.filter((filter) =>
  //   filter.toLowerCase().includes(searchTerm1)
  // );

  // const filteredFieldFilters = fieldFilters.filter((filter) =>
  //   filter.toLowerCase().includes(searchTerm)
  // );


  const handleViewMenuOpen = (event) => {
    setViewAnchorEl(event.currentTarget);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };


  const handleSearchChange1 = (event) => {
    setSearchTerm1(event.target.value);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const filteredSystemDefinedFilters = systemDefinedFilters.filter((filter) =>
    filter.toLowerCase().includes(searchTerm)
  );

  const handleDelete = (leadId) => {
    setDeleteLeadId(leadId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete(`/deleteLead/${deleteLeadId}`);
      if (response && response.status === 200) {
        setFilteredAccounts((prevLeads) =>
          prevLeads.filter((lead) => lead.id !== deleteLeadId)
        );
        setDeleteSuccessMessage("Lead deleted successfully!");
        setTimeout(() => {
          setDeleteSuccessMessage(null);
        }, 3000);
      } else {
        console.error("Failed to delete lead");
      }
    } catch (error) {
      console.error("Error deleting lead:", error);
    }
    setOpenDialog(false);
  };

  const columns = [
    { field: "reportName", headerName: "Report Name", flex: 0.3 },
    { field: "description", headerName: "Description", flex: 0.3 },
    { field: "folder", headerName: "Folder", flex: 0.3 },
    { field: "lastAccessedDate", headerName: "Last Accessed Date", flex: 0.3 },
    { field: "createdBy", headerName: "Created By", flex: 0.3 },
  ];

  return (
    <Box>
      <Grid container columnGap={5}>
        <Grid item xs={2}>
          <Box
            sx={{
              p: 2,
              borderRight: "1px solid #e0e0e0",
              height: "89vh",
              background: "black",
              overflowY: "auto",
              marginTop: "29%"
            }}
          >
            <Typography variant="h6" color="white" style={{ fontSize: "16px" }}>Folders</Typography>
            <TextField
              variant="outlined"
              placeholder="Search"
              size="small"
              fullWidth
              sx={{ mb: 2 }}
              value={searchTerm}
              onChange={handleSearchChange}
            />
        <Typography
          variant="body2"
          color="white"
          sx={{ mb: 1, cursor: "pointer", fontSize: "16px"}}
          onClick={toggleSystemFilters}
        >
        {/* System Defined Filters <IconButton
            sx={{
              color: "white",
              transform: systemFiltersVisible ? 'rotate(90deg)' : 'rotate(0deg)',
              transition: 'transform 0.3s'
            }}
          >
            <ArrowRightIcon />
          </IconButton> */}
         
        </Typography>
        {systemFiltersVisible && (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {filteredSystemDefinedFilters.map((filter) => (
              <FormControlLabel
                key={filter}
                control={<Checkbox />}
                label={filter}
                sx={{ color: "white" }}
              />
            ))}
          </Box>
        )}
{/* 
        <Typography
          variant="body2"
          color="white"
          sx={{ mt: 2, mb: 1, cursor: "pointer", fontSize: "16px" }}
          onClick={toggleFieldFilters}
        > */}
          {/* Filter By Fields <IconButton
            sx={{
              color: "white",
              transform: fieldFiltersVisible ? 'rotate(90deg)' : 'rotate(0deg)',
              transition: 'transform 0.3s'
            }}
          >
            <ArrowRightIcon />
          </IconButton> */}
        {/* </Typography> */}
        {/* {fieldFiltersVisible && (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {filteredFieldFilters.map((filter) => (
              <FormControlLabel
                key={filter}
                control={<Checkbox />}
                label={filter}
                sx={{ color: "white" }}
              />
            ))}
          </Box>
        )} */}
      </Box>
    </Grid>
        <Grid item xs={9.5}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '7%' }}>
            <Typography variant="h6" color="white" style={{ fontSize: "27px", marginLeft: "1%"}}>All Reports</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <TextField
                variant="outlined"
                placeholder="Search"
                size="small"
                fullWidth
                sx={{
                  mb: 2,
                  width: '50%',
                  marginTop: '5%',
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'gray',
                    },
                    '& input': {
                      color: 'white',
                    },
                  },
                }}
                value={searchTerm1}
                onChange={handleSearchChange1}
              />
              <Typography variant="h6" color="white" style={{ fontSize: "15px", marginLeft: "1%", width: '60%', lineHeight: '1', cursor: 'pointer' }}>
                Advanced Analytics for BBS CRM
                <span style={{ fontSize: "16px", marginLeft: "1%", color: "grey" }}>
                  <br /> powered by BBS Analytics
                </span>
              </Typography>
              <IconButton onClick={handleMenuOpen}>
                <MoreVertIcon style={{ color: 'white' }} />
              </IconButton>
              <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                <MenuItem onClick={handleMenuClose}>Help</MenuItem>
              </Menu>
            </Box>
          </Box>
          <Paper elevation={3} style={{ padding: '20px', height: '75vh', marginTop: '5px', color: 'black' }}>
            <Typography variant="h6" sx={{ mb: 2 }} style={{ color: 'white' }}>Total Records: {filteredAccounts.length}</Typography>
            <DataGrid
              columns={columns}
              rows={filteredAccounts}
              pageSize={10}
              rowsPerPageOptions={[10]}
              checkboxSelection
              disableSelectionOnClick
            />
            <Dialog open={openDialog} onClose={handleCloseDialog}>
              <DialogTitle>Confirm Delete</DialogTitle>
              <DialogContent>Are you sure you want to delete this lead?</DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                  No
                </Button>
                <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AllReport;
