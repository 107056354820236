


// import React, { useState, useEffect } from "react";
// import { useParams } from "react-router-dom";
// import { Helmet } from "react-helmet";
// import axios from "../../../utils/axios";


// const LeadsTimeLine = () => {
//   const { lead } = useParams();
//   console.log(lead)
//   const [leadData, setLeadData] = useState(null);

//   useEffect(() => {
//     const fetchCompanyData = async () => {
//       try {
//         const response = await axios.get(`/getUpdatedLeadById/${lead}`);
//         const data = response.data;
//         console.log("Lead Data:", data);
//         setLeadData(data);
//       } catch (error) {
//         console.error("Error fetching company data:", error);
//       }
//     };

//     fetchCompanyData();
//   }, [lead]);

//   return (
//     <>
//       <Helmet>
//         <title>ContactTab | User Timeline</title>
//       </Helmet>

//       <div>
//         {leadData ? (
//           <div>
//             <h2>User Timeline</h2>
//             <ul>
//               {Object.entries(leadData).map(([fieldName, value]) => (
//                 <li key={fieldName}>
//                   {typeof value === 'object' && value !== null ? (
//                     <>
//                       {/* {fieldName}: */}
//                       <ul>
//                         <li>
//                         {fieldName}  was updated from {value.oldValue !== undefined ? value.oldValue : 'blank'} to {value.newValue !== undefined ? value.newValue : 'blank'}
//                         </li>
//                       </ul>
//                     </>
//                   ) : (
//                     `${fieldName} was updated from blank value to : ${value}`

                    
//                   )}
//                 </li>
//               ))}
//             </ul>
//           </div>
//         ) : (
//           <div>Loading...</div>
//         )}
//       </div>
//     </>
//   );
// };

// export default LeadsTimeLine;





import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "../../../utils/axios";
import { Box, Typography, Paper, List, ListItem, ListItemText, CircularProgress } from '@mui/material';

const LeadsTimeLine = () => {
  const { lead } = useParams();
  const [leadData, setLeadData] = useState(null);

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const response = await axios.get(`/getUpdatedLeadById/${lead}`);
        const data = response.data;
        console.log("Lead Data:", data);
        setLeadData(data);
      } catch (error) {
        console.error("Error fetching company data:", error);
      }
    };

    fetchCompanyData();
  }, [lead]);

  return (
    <>
      <Helmet>
        <title>ContactTab | User Timeline</title>
      </Helmet>

      <Box sx={{ padding: 2 }}>
        {leadData ? (
          <Paper elevation={3} sx={{ padding: 3 }}>
            <Typography variant="h4" gutterBottom>User Timeline</Typography>
            <List>
              {Object.entries(leadData).map(([fieldName, value]) => (
                <ListItem key={fieldName}>
                  <ListItemText
                    primary={
                      typeof value === 'object' && value !== null 
                        ? `${fieldName} was updated from ${value.oldValue !== undefined ? value.oldValue : 'blank'} to ${value.newValue !== undefined ? value.newValue : 'blank'}`
                        : `${fieldName} was updated from blank value to: ${value}`
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
            <CircularProgress />
          </Box>
        )}
      </Box>
    </>
  );
};

export default LeadsTimeLine;



